<template>
  <div>
    <!--<v-btn> Generate Invoice</v-btn> -->
    <v-btn
      class="messagebtn"
      v-if="countInvoice != 0"
      @click.stop="dialog = true"
      title="Generate Invoice"
      style="background: orange !important; padding: 21px"
      flat
      target="_blank"
      color="white darken-1"
    >
      Generate Invoice ({{ countInvoice }})
    </v-btn>
    <v-btn
      class="messagebtn"
      @click.stop="dialog = true"
      title="Generate Invoice"
      style="background: orange !important; padding: 21px"
      flat
      target="_blank"
      :disabled="!assignmentIds.length"
      v-else
      prepend-icon
      color="white darken-1"
    >
      Generate Invoice
    </v-btn>

    <v-dialog v-model="dialog" persistent max-width="50%">
      <v-form ref="form">
        <v-card class="comments-view">
          <v-toolbar>
            <v-toolbar-title>Select Invoice Type</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="close()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="loading"
          ></v-progress-linear>
          <v-spacer class="invoice-pop">
            <v-card-text class="nospace">
              <v-layout>
                <v-flex>
                  <v-radio-group
                    class="typebox"
                    v-model="selected"
                    style="text-align: center !important"
                    mandatory
                  >
                    <v-radio
                      label="Single Invoice: Single Assignment Basis"
                      class="boxborder"
                      color="red"
                      value="INVOICE_PER_ASSIGNMENT"
                    ></v-radio>
                    <v-radio
                      label="Single Invoice: Multiple Assignment details"
                      value="SINGLE_INVOICE_FOR_ALL_ASSIGNMENTS"
                      class="boxborder"
                      color="red"
                      :disabled="assignmentIds.length == 1"
                    ></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-layout>

              <v-layout style="text-align: center">
                <v-flex pt-1>
                  <v-btn
                    class="messagebtn"
                    title="Generate Invoice"
                    style="background: orange !important; padding: 21px"
                    flat
                    target="_blank"
                    :disabled="!selected.length"
                    @click="fetchBank()"
                    prepend-icon
                    color="white darken-1"
                  >
                    Generate Invoice</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-spacer>
        </v-card>
        <v-dialog
          v-model="invoiceDialog"
          persistent
          :max-width="options.width"
          :style="{ zIndex: options.zIndex }"
          @keydown.esc="cancel"
        >
          <v-card>
            <v-flex style="text-align: end">
              <v-btn icon>
                <v-icon
                  small
                  @click.prevent="invoiceDialog = false"
                  :disabled="loading"
                  >close</v-icon
                >
              </v-btn>
            </v-flex>
            <v-spacer class="spacerclass">
              <v-card-text style="padding-top: 0px !important">
                <v-layout>
                  <v-flex pr-2>
                    <v-icon large color="orange">info</v-icon></v-flex
                  >
                  <v-flex
                    >The invoice generation process is started. We'll notify you
                    once it is completed.</v-flex
                  >
                </v-layout>
              </v-card-text>
            </v-spacer>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="bankDialog"
          persistent
          :max-width="700"
          @keydown.esc="cancel"
        >
          <v-card>
            <v-toolbar
              dark
              style="background-color: #fee9d0; color: black"
              flat
            >
              <v-toolbar-title>Select Bank </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon>
                <v-icon
                  class="heading grey--text text--darken-4"
                  @click.prevent="closeBankInfo()"
                  :disabled="loading"
                  >close</v-icon
                >
              </v-btn>
            </v-toolbar>
            <v-spacer class="spacerclass">
              <v-card-text>
                <div class="fonthead">
                  Are you sure want to generate invoice number?
                </div>
                <v-layout class="pt-2">
                  <v-flex xs6 md6>
                    <v-autocomplete
                      placeholder=" Please Choose Bank"
                      :items="bankInfo"
                      class="pt-0 currencyTitle"
                      v-model="bank"
                      item-text="text"
                      item-value="value"
                      @change="setCustomerValues($event)"
                    />
                  </v-flex>
                  <v-flex xs6 md5 v-if="bank.length">
                    <div class="sub-heading muted">
                      Account Number:
                      <span style="color: black" class="heading">{{
                        accountNo
                      }}</span>
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout class="pb-3" v-if="bank.length">
                  <v-flex xs6 md6>
                    <div class="sub-heading muted">
                      IBAN NO:
                      <span style="color: black" class="heading">{{
                        ibanNo || N.A
                      }}</span>
                    </div>
                  </v-flex>
                  <v-flex xs6 md6>
                    <div class="sub-heading muted">
                      Branch Code :
                      <span style="color: black" class="heading">{{
                        branchCode || N.A
                      }}</span>
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout class="pb-3" v-if="bank.length">
                  <v-flex xs6 md6>
                    <div class="sub-heading muted">
                      Swift Code :
                      <span style="color: black" class="heading">{{
                        swiftCode || N.A
                      }}</span>
                    </div>
                  </v-flex>
                  <v-flex xs6 md6>
                    <div class="sub-heading muted">
                      Beneficiary Name :
                      <span style="color: black" class="heading">{{
                        beneficiaryName || N.A
                      }}</span>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card-text>

              <v-card-actions class="pt-2 pb-2" style="justify-content: right">
                <v-btn
                  medium
                  style="text-transform: none !important"
                  color="red white--text "
                  darken-1
                  :disabled="loading"
                  text
                  @click="closeBankInfo()"
                  >No</v-btn
                >

                <v-btn
                  medium
                  style="text-transform: none !important"
                  color="green white--text "
                  v-permissions="'generate-purchase-invoice'"
                  darken-1
                  @click="generateMultipleInvoice()"
                  :disabled="loading"
                  text
                  >Yes</v-btn
                >
              </v-card-actions>
            </v-spacer>
          </v-card>
        </v-dialog>
      </v-form>
    </v-dialog>
    <v-snackbar
      :timeout="4000"
      bottom
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
  </div>
</template>

<script>
import { StorageKeys } from "../../../constants/constants";
import { EventBus } from "../../../event-bus.js";
export default {
  data() {
    return {
      error: "",
      dialog: false,
      invoicingCity: "",
      invoicingCountry: "",
      bankInfo: [],
      bankName: [],
      bank: "",
      beneficiaryName: "",
      branchCode: "",
      ibanNo: "",
      swiftCode: "",
      accountNo: "",
      bankId: "",
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      invoiceDialog: false,

      bankDialog: false,
      loading: false,

      selected: [],
      single: "",
    };
  },
  props: {
    assignmentIds: Array,
    countInvoice: Number,
    selectedCustomerForInvoice: Object,
  },
  methods: {
    close() {
      this.selected = [];
      this.loading = false;
      this.dialog = false;
    },

    setCustomerValues(val) {
      var data = this.bankInfo.filter((v) => {
        if (v.value === val) return v;
      });

      (this.beneficiaryName = data[0].beneficiaryName),
        (this.branchCode = data[0].branchCode),
        (this.accountNo = data[0].accountNo),
        (this.ibanNo = data[0].ibanNo),
        (this.bankId = data[0].bankId),
        (this.swiftCode = data[0].swiftCode);
      // this.beneficiaryName = data[0].beneficiaryName;
    },
    fetchBank() {
      this.bankDialog = true;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        invoicingCity: {
          code: this.selectedCustomerForInvoice.invoicingCity,
        },
        invoicingCountry: {
          code: this.selectedCustomerForInvoice.invoicingCountry,
        },
      };
      let { apiUrl } = this.constants;
      this.axios.post(`${apiUrl}/accounts/getBankDetails`, body, config).then(
        (response) => {
          const { data = null } = response.data;
          data.forEach((element) => {
            this.bankInfo.push({
              text: element.bankName,
              value: element._id,
              beneficiaryName: element.beneficiaryName,
              branchCode: element.branchCode,
              accountNo: element.accountNo,
              ibanNo: element.ibanNo,
              bankId: element._id,
              swiftCode: element.swiftCode,
            });
          });
        },
        (error) => {
          this.error = "Something went wrong. Please try again later!";
          this.loading = false;
        }
      );
    },

    generateInvoice() {
      if (!this.selectedCustomerForInvoice["invoiceDay"]) {
        this.$emit("customerInvoiceFailed", {
          ...this.selectedCustomerForInvoice,
        });
        return false;
      }
      this.dialog = true;
    },
    chooseBank() {
      this.bankDialog = true;
    },
    closeBankInfo() {
      this.bankDialog = false;
      this.bankInfo = [];
      this.bank = "";
      (this.beneficiaryName = ""),
        (this.branchCode = ""),
        (this.accountNo = ""),
        (this.ibanNo = ""),
        (this.bankId = ""),
        (this.swiftCode = "");
    },
    generateMultipleInvoice() {
      if (!this.selected.length) {
        this.loading = false;
        this.error = "Please select invoicing type !";
        return;
      }
      if (!this.bank) {
        this.loading = false;
        this.error = "Please choose bank before generating the invoice";
        return;
      }
      this.loading = true;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let body = {
        assignmentIds: this.$props.assignmentIds,
        type: this.selected.toString(),
        bankId: this.bankId,
      };
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.axios
        .post(
          `${this.constants.apiUrl}/dmsAdmin/generate-assignment-invoice`,
          body,
          config
        )
        .then(
          (response) => {
            const { status, data } = response.data;
            this.close();
            this.invoiceDialog = true;
            this.loading = false;
            localStorage.removeItem("customerFilter");
            this.$emit("reset-list");
          },
          (error) => {
            this.loading = false;
            this.error = error.response.data.message;
            localStorage.removeItem("customerFilter");
          }
        );
    },
  },
};
</script>
<style lang="scss">
.typebox {
  display: block !important;
  .boxborder {
    padding-bottom: 11px;
    margin-right: 11px;
    border-bottom: 1px solid lightgray;
  }
  .v-input--selection-controls__input {
    position: absolute !important;
    right: 0 !important;
  }
}
</style>

<style scoped>
.invoice-pop {
  padding: 20px !important;
}
.fonthead {
  font-size: 20px;
}
</style>
