<template>
  <v-card class="nospace">
    <loading :active.sync="loading" />
    <v-card-text class="nospace">
      <v-data-table
        id="dataTable"
        hide-actions
        item-key="jobId"
        :headers="headers"
        single-expand
        show-expand
        :items="items"
        :disable-initial-sort="true"
        :pagination.sync="pagination"
      >
        <template slot="items" slot-scope="props">
          <tr
            style="cursor: pointer"
            @click="
              props.expanded = !props.expanded;
              getAssignmentList(props.item.jobId);
            "
          >
            <td>
              <v-layout style="justify-content: center">
                <span v-if="!customerSearchData" style="padding-top: 6px">
                  {{ props.item.jobId }}</span
                >
                <v-flex @click="stop($event)">
                  <v-checkbox
                    v-if="customerSearchData"
                    class="align-center justify-center"
                    v-model="props.item.selected"
                    :disabled="
                      !props.item.voucherIdRealBooks ||
                      props.item.jobStatus == 5 ||
                      props.item.isSaleOrderBackgroundProcess ||
                      props.item.isSaleInvoviceBackgroundProcess ||
                      props.item.isSaleOrderInQueue ||
                      props.item.isAssignmentCustomerReceiptInitiated
                    "
                    :label="props.item.jobId.toString()"
                    @change="
                      checkSelected(
                        props.item.selected,
                        props.item.jobId,
                        $event
                      )
                    "
                    primary
                    hide-details
                  >
                  </v-checkbox>
                </v-flex>
                <activityLogs
                  class="pd_5"
                  :type="'jobs'"
                  :permissions="'activity-logs-for-accounts'"
                  :_id="props.item._id"
                  :id="props.item.jobId.toString()"
                />
              </v-layout>
            </td>
            <td>
              <img
                v-if="props.item.workingCountry == 'PAK'"
                src="../../assets/pakflag.png"
                height="20px"
              /><img
                v-if="props.item.workingCountry == 'KSA'"
                src="../../assets/ksaflag.png"
                height="20px"
              /><img
                v-if="props.item.workingCountry == 'UAE'"
                src="../../assets/Uaeflag.png"
                height="20px"
              /><img
                v-if="props.item.workingCountry == 'UAE LA'"
                src="../../assets/Uaeflag.png"
                height="20px"
                style="border-radius: 50%"
              />
              <img
                v-if="props.item.workingCountry == 'OMAN'"
                src="../../assets/omanflag.png"
                height="28px"
              />
              <br />{{ props.item.workingCountry || "-" }}
            </td>
            <td>{{ props.item.companyName || props.item.customer }}</td>
            <!-- <td>{{ getTime3(props.item.pickupDate) }}</td> -->
            <td>
              <v-layout class="justfy_c">
                <span style="padding-top: 12px">
                  {{
                    props.item.jobPrice
                      ? Math.round(props.item.jobPrice * 100) / 100
                      : "N.A"
                  }}
                  {{
                    Math.round(props.item.jobPrice * 100) / 100
                      ? props.item.customerCurrency
                      : ""
                  }}</span
                >
                <div v-if="!props.item.isJobActivatedByScript">
                  <CustomerPrice
                    v-permissions="'edit-customer-price-for-accounts'"
                    :priceData="props.item"
                    @account-list-refresh="
                      refresh();
                      getAssignmentList(props.item.jobId);
                    "
                  />
                </div>
              </v-layout>
            </td>
            <td>{{ jobsStatus(props.item.jobStatus) }}</td>
            <td>
              <v-layout class="justfy_c">
                <span style="padding-top: 12px">
                  {{ props.item.chargesType }}</span
                >
                <div v-if="!props.item.isJobActivatedByScript">
                  <PriceType
                    v-permissions="'job-price-type-for-accounts'"
                    :jobId="props.item.jobId"
                    :chargesType="props.item.chargesType"
                    @account-list-refresh="
                      refresh();
                      getAssignmentList(props.item.jobId);
                    "
                  />
                </div>
              </v-layout>
            </td>
            <td>
              <v-layout class="justfy_c">
                <div style="display: flex; align-items: center">
                  <v-flex>
                    <div style="padding-top: 3px">
                      Misc:&nbsp;
                      {{
                        props.item.miscellaneousCharge
                          ? props.item.miscellaneousCharge
                          : "0"
                      }}
                      {{
                        props.item.miscellaneousCharge
                          ? props.item.customerCurrency
                          : ""
                      }}
                    </div>
                    <div style="padding-top: 3px">
                      Driver:&nbsp;
                      {{
                        props.item.additionalCharges
                          ? props.item.additionalCharges
                          : "0"
                      }}
                      {{
                        props.item.additionalCharges
                          ? props.item.customerCurrency
                          : ""
                      }}
                    </div>

                    <div style="padding-top: 3px">
                      Customer:&nbsp;
                      {{ props.item.costMargin ? props.item.costMargin : "0" }}
                      {{
                        props.item.costMargin ? props.item.customerCurrency : ""
                      }}
                    </div>
                  </v-flex>

                  <div v-if="!props.item.isJobActivatedByScript">
                    <additionalChargesPopup
                      v-permissions="'additional-charge-for-accounts'"
                      @account-list-refresh="
                        refresh();
                        getAssignmentList(props.item.jobId);
                      "
                      :_id="props.item._id"
                      :id="props.item.jobId"
                      :jobCurrency="props.item.customerCurrency"
                      :jobSalesInvoice="props.item.isSalesInvoiceGenerated"
                    />
                  </div>
                </div>
              </v-layout>
            </td>
            <td>
              {{ props.item.driverRate || "N.A" }}
              {{ props.item.driverRate ? props.item.customerCurrency : "" }}
            </td>
            <td>
              <button
                v-permissions="'generate-downaload-so-si-for-accounts'"
                class="account-disabled-ass-btn"
                flat
                prepend-icon
                :disabled="props.expanded"
                color="white darken-1"
              >
                View
              </button>
            </td>
            <td>
              <v-flex
                v-if="
                  props.item.workingCountry == 'PAK' ||
                  props.item.workingCountry == 'OMAN'
                "
                >-</v-flex
              >
              <v-flex
                v-else-if="
                  (!props.item.workOrderCompleted &&
                    props.item.workingCountry != 'PAK') ||
                  (!props.item.workOrderCompleted &&
                    props.item.workingCountry != 'OMAN')
                "
              >
                Work Order not generated
                <v-flex
                  v-if="
                    props.item.workingCountry != 'PAK' ||
                    props.item.workingCountry != 'OMAN'
                  "
                >
                  <v-flex
                    v-if="
                      !props.item.userData.invoicingCity &&
                      props.item.userData.invoicingCountry
                    "
                  >
                    Please update customer invoicing details.
                  </v-flex>
                  <v-flex
                    v-else-if="
                      !props.item.userData.invoicingCity &&
                      !props.item.userData.invoicingCountry &&
                      !props.item.invoicingCompany &&
                      !props.item.invoicingCity
                    "
                  >
                    Please update customer invoicing details{{
                      props.item.invoicingCompany
                    }}{{ props.item.invoicingCity
                    }}{{ props.item.userData.invoicingCity
                    }}{{ props.item.userData.invoicingCountry }}
                  </v-flex>
                  <v-flex
                    v-else-if="
                      props.item.userData.companyVatNumber &&
                      props.item.userData.address &&
                      props.item.workOrderCompleted == false
                    "
                  >
                    <button
                      v-if="
                        (props.item.userData &&
                          props.item.userData.invoicingCity &&
                          props.item.userData.invoicingCountry) ||
                        (props.item.invoicingCompany &&
                          props.item.invoicingCity &&
                          !props.item.isWorkOrderInQueue)
                      "
                      class="track-ass-btn"
                      flat
                      prepend-icon
                      color="white darken-1"
                      @click="retry(props.item.jobId)"
                    >
                      Retry
                    </button>
                    <div v-if="props.item.isWorkOrderInQueue">
                      Background process is ongoing
                    </div>
                  </v-flex>
                  <v-flex
                    v-else-if="
                      props.item.userData &&
                      props.item.userData.companyVatNumber &&
                      props.item.userData.address
                    "
                  >
                    <button
                      class="track-ass-btn"
                      v-if="!props.item.isWorkOrderInQueue"
                      flat
                      prepend-icon
                      color="white darken-1"
                      @click="retry(props.item.jobId)"
                    >
                      Create
                    </button>
                    <div v-if="props.item.isWorkOrderInQueue">
                      Background process is ongoing
                    </div>
                  </v-flex>
                  <v-flex v-else>
                    Please Update Customer Billing address and Vat number*
                  </v-flex>
                </v-flex>

                <!-- Work order is not generated no -->
              </v-flex>
              <v-flex
                v-else-if="
                  props.item.workingCountry != 'PAK' ||
                  props.item.workingCountry != 'OMAN'
                "
              >
                <div v-if="props.item.isSaleOrderInQueue">
                  Background process is ongoing
                </div>
                <div v-else-if="!props.item.workOrderCompleted">
                  Work Order not generated
                  <v-flex
                    v-if="
                      props.item.workingCountry != 'PAK' ||
                      props.item.workingCountry != 'OMAN'
                    "
                  >
                    <v-flex
                      v-if="
                        !props.item.userData.invoicingCity &&
                        props.item.userData.invoicingCountry
                      "
                    >
                      Please update customer invoicing details.
                    </v-flex>
                    <v-flex
                      v-else-if="
                        !props.item.userData.invoicingCity &&
                        !props.item.userData.invoicingCountry &&
                        !props.item.invoicingCompany &&
                        !props.item.invoicingCity
                      "
                    >
                      Please update customer invoicing details.
                    </v-flex>
                    <v-flex
                      v-else-if="
                        props.item.userData.companyVatNumber &&
                        props.item.userData.address &&
                        props.item.workOrderCompleted == false
                      "
                    >
                      <button
                        v-if="
                          (props.item.userData &&
                            props.item.userData.invoicingCity &&
                            props.item.userData.invoicingCountry) ||
                          (props.item.invoicingCompany &&
                            props.item.invoicingCity &&
                            !props.item.isWorkOrderInQueue)
                        "
                        class="track-ass-btn"
                        flat
                        prepend-icon
                        color="white darken-1"
                        @click="retry(props.item.jobId)"
                      >
                        Retry
                      </button>
                      <div v-if="props.item.isWorkOrderInQueue">
                        Background process is ongoing
                      </div>
                    </v-flex>
                    <v-flex
                      v-else-if="
                        props.item.userData &&
                        props.item.userData.companyVatNumber &&
                        props.item.userData.address
                      "
                    >
                      <button
                        class="track-ass-btn"
                        v-if="!props.item.isWorkOrderInQueue"
                        flat
                        prepend-icon
                        color="white darken-1"
                        @click="retry(props.item.jobId)"
                      >
                        Create
                      </button>
                      <div v-if="props.item.isWorkOrderInQueue">
                        Background process is ongoing
                      </div>
                    </v-flex>
                    <v-flex v-else>
                      Please Update Customer Billing address and Vat number*
                    </v-flex>
                  </v-flex>

                  <!-- Work order is not generated no -->
                </div>
                <div v-else-if="props.item.jobStatus == -1">
                  Job is cancelled
                </div>
                <!-- <div v-else-if="detail.jobStatus == 4">Please assign driver</div> -->
                <div
                  v-else-if="
                    props.item.isSalesOrderIntiated &&
                    props.item.voucherIdRealBooks
                  "
                >
                  {{ props.item.voucherIdRealBooks }}
                </div>
                <div
                  v-else-if="
                    props.item.isSalesOrderIntiated == false &&
                    props.item.workOrderCompleted == true &&
                    props.item.errorWhileRealbooks == true
                  "
                >
                  <span v-if="props.item.isSaleOrderInQueue">
                    Background process are ongoing
                  </span>
                  <span
                    v-if="
                      !props.item.isSaleOrderInQueue &&
                      props.item.isSaleOrderBackgroundProcess &&
                      props.item.errorWhileRealbooks
                    "
                    >Data inappropriate, kindly update data</span
                  >
                  <button
                    class="track-ass-btn"
                    v-permissions="'generate-downaload-so-si-for-accounts'"
                    flat
                    v-if="
                      !props.item.isSaleOrderInQueue &&
                      !props.item.isSaleOrderBackgroundProcess &&
                      props.item.errorWhileRealbooks
                    "
                    prepend-icon
                    color="white darken-1"
                    @click="retrySO(props.item.jobId)"
                    :_id="props.item._id"
                  >
                    Retry
                  </button>
                </div>
                <div
                  v-else-if="
                    props.item.isReadyToGenerateSalesInvoice &&
                    props.item.isSalesOrderIntiated == false &&
                    !props.item.voucherIdRealBooks &&
                    props.item.workOrderCompleted == true
                  "
                >
                  <span v-if="props.item.isSaleOrderInQueue">
                    Background process is ongoing
                  </span>
                  <button
                    v-if="!props.item.isSaleOrderInQueue"
                    v-permissions="'generate-downaload-so-si-for-accounts'"
                    class="account-ass-btn"
                    flat
                    prepend-icon
                    color="white darken-1"
                    :disabled="loading"
                    @click="retrySO(props.item.jobId)"
                    :_id="props.item._id"
                  >
                    <v-icon color="orange" small>settings</v-icon> SO
                  </button>
                </div>
              </v-flex>
              <v-flex v-else>
                <!-- <span v-if="props.item.jobStatus != 5">-</span> -->
                <v-tooltip
                  v-if="
                    props.item.workingCountry != 'PAK' ||
                    props.item.workingCountry != 'OMAN'
                  "
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="grey"
                >
                  <template slot="activator">
                    <button
                      v-permissions="'generate-downaload-so-si-for-accounts'"
                      class="account-disabled-ass-btn"
                      flat
                      prepend-icon
                      color="white darken-1"
                      disabled
                    >
                      <v-icon style="color: lightgray !important" small
                        >settings</v-icon
                      >
                      SO
                    </button></template
                  >
                  <span class="tooltip"
                    >Complete shipment to create sales order.</span
                  >
                </v-tooltip>
              </v-flex>
            </td>
            <td v-if="props.item.invoiceNumber == 'NA'">N.A.</td>
            <td v-else>
              <v-flex
                v-if="
                  props.item.workingCountry == 'PAK' ||
                  props.item.workingCountry == 'OMAN'
                "
                >-</v-flex
              >
              <v-flex
                v-else-if="
                  (!props.item.workOrderCompleted &&
                    props.item.workingCountry != 'PAK') ||
                  (!props.item.workOrderCompleted &&
                    props.item.workingCountry != 'OMAN')
                "
              >
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="grey"
                >
                  <template slot="activator">
                    <button
                      v-permissions="'generate-downaload-so-si-for-accounts'"
                      class="account-disabled-ass-btn"
                      flat
                      prepend-icon
                      color="white darken-1"
                      disabled
                    >
                      <v-icon style="color: lightgray !important" small
                        >settings</v-icon
                      >
                      SI
                    </button></template
                  >
                  <span class="tooltip"
                    >Generate SO to generate sales invoice.</span
                  >
                </v-tooltip>
              </v-flex>
              <v-flex
                v-else-if="
                  !props.item.isSalesInvoiceGenerated &&
                  !props.item.invoiceNumber &&
                  !props.item.isReadyToGenerateSalesInvoice &&
                  !props.item.invoicePdfUrl &&
                  props.item.VendorWorkingCountry != 'PAK' &&
                  props.item.VendorWorkingCountry != 'OMAN'
                "
              >
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="grey"
                >
                  <template slot="activator">
                    <button
                      v-permissions="'generate-downaload-so-si-for-accounts'"
                      class="account-disabled-ass-btn"
                      flat
                      prepend-icon
                      color="white darken-1"
                      disabled
                    >
                      <v-icon style="color: lightgray !important" small
                        >settings</v-icon
                      >
                      SI
                    </button></template
                  >
                  <span v-if="!props.item.voucherIdRealBooks" class="tooltip"
                    >Generate SO to generate sales invoice.</span
                  >

                  <span v-if="props.item.voucherIdRealBooks" class="tooltip"
                    >Complete shipment to generate sales invoice.</span
                  >
                </v-tooltip>
              </v-flex>

              <v-flex
                v-if="
                  props.item.isReadyToGenerateSalesInvoice &&
                  !props.item.isSalesInvoiceGenerated &&
                  props.item.workOrderCompleted &&
                  props.item.workingCountry != 'PAK' &&
                  !props.item.isSaleOrderInQueue &&
                  props.item.isAssignmentCustomerReceiptInitiated &&
                  props.item.workingCountry != 'OMAN'
                "
              >
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="grey"
                >
                  <template slot="activator">
                    <button
                      v-permissions="'generate-downaload-so-si-for-accounts'"
                      class="account-disabled-ass-btn"
                      flat
                      prepend-icon
                      color="white darken-1"
                      disabled
                    >
                      <v-icon style="color: lightgray !important" small
                        >settings</v-icon
                      >
                      SI
                    </button></template
                  >
                  <span class="tooltip">Customer Receipt Initiated</span>
                </v-tooltip>
              </v-flex>

              <v-flex
                v-if="
                  props.item.isReadyToGenerateSalesInvoice &&
                  !props.item.isSalesInvoiceGenerated &&
                  props.item.workOrderCompleted &&
                  props.item.workingCountry != 'PAK' &&
                  props.item.workingCountry != 'OMAN' &&
                  !props.item.isSaleOrderInQueue &&
                  !props.item.isAssignmentCustomerReceiptInitiated
                "
              >
                <!-- <div class="subheading muted">Generate Sales Invoice</div>
              <div class="heading"> -->
                <v-tooltip
                  top
                  class="milestone"
                  content-class="tooltip-op"
                  color="grey"
                >
                  <template slot="activator">
                    <button
                      v-permissions="'generate-downaload-so-si-for-accounts'"
                      @click="open(props.item.userData, props.item.jobId)"
                      type="button"
                      v-if="!props.item.isSaleInvoviceBackgroundProcess"
                      class="account-ass-btn"
                      :disabled="loading"
                      color="orange"
                      outline
                    >
                      <v-icon color="orange" small>settings</v-icon>SI
                    </button>
                  </template>
                  <span v-if="props.item.assignementSalesInvoiceGenerated"
                    >Assignment level sales invoice has already been generated
                    for this.</span
                  >

                  <span v-if="!props.item.assignementSalesInvoiceGenerated"
                    >Sale Invoice Generated Button</span
                  >
                </v-tooltip>

                <div
                  v-if="props.item.isSaleInvoviceBackgroundProcess"
                  style="word-break: break-word"
                >
                  Background process is ongoing.
                </div>
                <!-- </div> -->
              </v-flex>

              <div v-else>
                <v-flex
                  v-if="
                    props.item.invoiceNumber &&
                    props.item.isSalesInvoiceGenerated &&
                    props.item.workOrderCompleted &&
                    props.item.workingCountry != 'PAK'
                  "
                >
                  {{ props.item.invoiceNumber }}
                </v-flex>
                <v-flex
                  v-if="
                    props.item.invoicePdfUrl &&
                    props.item.isSalesInvoiceGenerated &&
                    props.item.workOrderCompleted &&
                    props.item.workingCountry != 'PAK'
                  "
                >
                  <v-layout style="text-align: center">
                    <v-flex style="text-align: end">
                      <button
                        class="download-ass-btn"
                        v-permissions="'generate-downaload-so-si-for-accounts'"
                        type="button"
                        color="orange"
                        title="Download Invoice"
                        :disabled="loading"
                        outline
                        @click="
                          checkViewInvoice(
                            props.item.invoicePdfUrl,
                            props.item.isDownloadInvocie,
                            props.item.isSaleInvoviceBackgroundProcess,
                            props.item.invoiceNumber,
                            $event
                          )
                        "
                      >
                        <v-icon color="white" small>download</v-icon>
                      </button>
                    </v-flex>
                    <v-flex style="text-align: start; padding-left: 3px">
                      <SendSI
                        :jobId="props.item.jobId"
                        :email="props.item.userData.email"
                        :invoiceUrl="props.item.invoicePdfUrl"
                        :workingCountry="props.item.workingCountry"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </div>
            </td>
          </tr>
        </template>
        <template slot="expand" slot-scope="props">
          <v-stepper v-model="e1">
            <v-stepper-header class="SubTypeTab">
              <template v-for="n in steps" class="bg-clr-tab">
                <v-stepper-step
                  :complete="e1 == n"
                  :key="`${n}-step`"
                  :step="n"
                  :editable="true"
                  color="orange darken-1"
                  complete-icon
                  class="bg-clr-tab"
                >
                  <span
                    >{{
                      assignments.length ? getJobType(jobTypeArray[n - 1]) : ""
                    }}
                    {{ n }}
                  </span>
                </v-stepper-step>
              </template>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content
                v-for="n in steps"
                :key="`${n}-content`"
                :step="n"
              >
                <TripInformation
                  :assignments="assignments[n - 1]"
                  :tabOrder="n"
                  :jobType="jobTypeArray[n - 1]"
                  :isAssignCustomerRecipt="isAssignCustomerRecipt[n - 1]"
                  :totalAdditionalAmountTab="totalAdditionalAmountTab[n - 1]"
                  :jobId="props.item.jobId"
                  :jobGeography="props.item.jobGeoType"
                  :isCustomerReceiptInitiate="
                    props.item.isCustomerReceiptInitiate
                  "
                  :additionalCharges="props.item.additionalCharges"
                  :isAssignmentCustomerReceiptInitiated="
                    props.item.isAssignmentCustomerReceiptInitiated
                  "
                  :isJobActivatedByScript="props.item.isJobActivatedByScript"
                  @account-list-refresh="
                    getAssignmentList(props.item.jobId);
                    refresh();
                  "
                />
                <AssignmentList
                  :assignmentList="assignments[n - 1]"
                  :jobId="props.item._id"
                  :jobData="props.item"
                  :tabOrder="n"
                  @account-list-refresh="
                    getAssignmentList(props.item.jobId);
                    refresh();
                  "
                  @account-assign-list-refresh="
                    getAssignmentList(props.item.jobId)
                  "
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </template>
      </v-data-table>
      <pagination
        :totalPages="totalPages"
        :pages="this.pages"
        @pageNo="page"
        :url="`/dmsAdmin/accountSection`"
        :callback="callback"
        :componentKey="componentKey"
        :action="`accounts`"
        :payload="payload"
        :type="1"
        v-on:pagination-load="checkLoading"
      />
    </v-card-text>
    <v-dialog
      v-model="dialog1"
      persistent
      :max-width="700"
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar dark style="background-color: #fee9d0; color: black" flat>
          <v-toolbar-title>Generate Invoice</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon>
            <v-icon
              class="heading grey--text text--darken-4"
              @click.prevent="closeBankInfo()"
              :disabled="loading"
              >close</v-icon
            >
          </v-btn>
        </v-toolbar>
        <v-spacer class="spacerclass">
          <v-card-text>
            <div
              v-if="!this.userDetail.invoiceDay"
              style="padding-left: 15px; text-align: left !important"
              class="subheading muted"
            >
              Please enter credit limit duration
            </div>
            <v-layout v-if="!this.userDetail.invoiceDay">
              <v-flex xs9 pt-3 pl-3>
                <div class="heading">
                  <v-text-field
                    placeholder="Credit Duration"
                    v-model="invoiceDay"
                    :value="invoiceDay"
                    suffix="days"
                    type="number"
                    single-line
                    class="pt-0 currencyTitle"
                  ></v-text-field>
                </div>
              </v-flex>
              <v-flex pt-3 pl-4>
                <button
                  class="track-ass-btn"
                  style="float: left"
                  type="button"
                  @click="addCreditDuration()"
                  title="view"
                >
                  Save
                </button>
              </v-flex>
            </v-layout>
            <div v-if="this.userDetail.invoiceDay">
              <div class="fonthead">
                Are you sure want to generate invoice number?
              </div>
              <v-layout class="pt-2">
                <v-flex xs6 md6 pr-3>
                  <v-autocomplete
                    placeholder=" Please Choose Bank"
                    :items="bankInfo"
                    class="pt-0 currencyTitle"
                    v-model="bank"
                    item-text="text"
                    item-value="value"
                    @change="setCustomerValues($event)"
                  />
                </v-flex>
                <v-flex xs6 md6 v-if="bank.length">
                  <div class="sub-heading muted">
                    Account Number:
                    <span style="color: black" class="heading">{{
                      accountNo
                    }}</span>
                  </div>
                </v-flex>
              </v-layout>

              <v-layout class="pb-3" v-if="bank.length">
                <v-flex xs6 md6 pr-3>
                  <div class="sub-heading muted">
                    IBAN NO:
                    <span
                      style="color: black"
                      class="heading word___Break__all"
                      >{{ ibanNo }}</span
                    >
                  </div>
                </v-flex>
                <v-flex xs6 md6>
                  <div class="sub-heading muted">
                    Branch Code :
                    <span style="color: black" class="heading">{{
                      branchCode
                    }}</span>
                  </div>
                </v-flex>
              </v-layout>
              <v-layout class="pb-3" v-if="bank.length">
                <v-flex xs6 md6 pr-3>
                  <div class="sub-heading muted">
                    Swift Code :
                    <span style="color: black" class="heading">{{
                      swiftCode
                    }}</span>
                  </div>
                </v-flex>
                <v-flex xs6 md6>
                  <div class="sub-heading muted">
                    Beneficiary Name :
                    <span style="color: black" class="heading">{{
                      beneficiaryName
                    }}</span>
                  </div>
                </v-flex>
              </v-layout>
            </div>
          </v-card-text>

          <v-card-actions
            class="pt-2 pb-2"
            style="justify-content: right"
            v-if="this.userDetail.invoiceDay"
          >
            <v-btn
              medium
              style="text-transform: none !important"
              color="red white--text "
              darken-1
              :disabled="loading"
              text
              @click="closeBankInfo()"
              >No</v-btn
            >
            <v-btn
              medium
              style="text-transform: none !important"
              color="green white--text "
              darken-1
              @click="generateInvoiceNumber()"
              :disabled="loading"
              text
              >Yes</v-btn
            >
          </v-card-actions>
        </v-spacer>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmationDialog" max-width="23%" persistent>
      <v-card>
        <v-spacer class="spacerclass">
          <v-card-text style="padding-top: 0px !important">
            <v-layout class="pt-4" style="text-align: center">
              <v-flex pr-2 pb-3>
                <v-icon x-large color="orange">info</v-icon></v-flex
              >
            </v-layout>
            <v-layout>
              <v-flex pb-3 style="text-align: center"> there </v-flex>
            </v-layout>
            <v-layout>
              <v-flex style="text-align: center">
                <v-btn
                  color="white darken-1"
                  style="
                    background: orange !important;
                    min-width: 66px !important;
                  "
                  flat
                  @click.prevent="confirm()"
                  >OK</v-btn
                ></v-flex
              >
            </v-layout>
          </v-card-text>
        </v-spacer>
      </v-card>
    </v-dialog>

    <success-dialog
      content="Successful"
      :show="x.registerSuccess"
      :onclose="closeRegistration"
    />
    <v-snackbar
      :timeout="3000"
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
  </v-card>
</template>
<script>
import moment from "moment";
import { EventBus } from "../../event-bus.js";
import { accountList } from "@/constants/datatable-headers.js";
import downloadMixin from "@/mixins/downloadMixin";
import SuccessDialog from "@/components/Common/SuccessDialog";
import activityLogs from "../Common/activityLogs";
import { StorageKeys } from "../../constants/constants";
import Swal from "sweetalert2";
import PriceType from "./Popups/PriceType";
import SendSI from "./Popups/SendSI";
import CustomerPrice from "./Popups/CustomerPrice";
import pagination from "@/components/Pagination/pagination";
import additionalChargesPopup from "../JobDocuments/additionalChargesPopup";
import TripInformation from "./TripInformation";
import AssignmentList from "./AssignmentList";
import Loading from "vue-loading-overlay";
export default {
  mixins: [downloadMixin],
  components: {
    pagination,
    TripInformation,
    additionalChargesPopup,
    PriceType,
    SendSI,
    AssignmentList,
    activityLogs,
    Loading,
    CustomerPrice,
    SuccessDialog,
  },
  created() {
    this.$eventBus.$on("accounts-purchase-invoice", (data) => {
      this.refresh();
    });
    this.$eventBus.$on("accounts-purchase-invoice2", (data) => {
      this.refresh();
    });
    this.$eventBus.$on("accounts-purchase-order", (data) => {
      this.refresh();
    });
    this.$eventBus.$on("accounts-sales-order", (data) => {
      this.refresh();
    });
    this.$eventBus.$on("accounts-sales-invoice", (data) => {
      this.refresh();
    });

    //this.checkPermission("accounts");
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;
      this.payload = {
        workingCountry: this.workingCountry,
      };
    }

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = val;
      this.loading = true;
      if (this.$route.name === "Accounts") {
        this.payload.workingCountry = this.workingCountry;
      }
    });
  },

  props: {
    searchText: String,
    dateRangenew: Array,
    dashboardData: Function,
    searchCustomer: String,
    resetCustomer: Boolean,
    loader: Boolean,
    TypeFilter: String,
    Type: String,
    count1: String,
    selectedCustInvoiceFailed: Object,
  },
  watch: {
    count1(val) {
      this.generateInvoiceCount = val;
    },
    dateRangenew: function (n, o) {
      if (!n) {
        this.$eventBus.$emit("on-load");
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
      if (n) {
        this.$eventBus.$emit("on-load");
        this.loading = false;
        if (n.length && n[0] && n[1]) {
          this.startDate = moment(n[0]).format("YYYY-MM-DD");
          this.endDate = moment(n[1]).format("YYYY-MM-DD");
          this.getDashboardData(
            this.searchCustomer,
            this.startDate,
            this.endDate
          );
        }
        this.$set(this.payload, "startDate", this.startDate);

        this.payload.isSearch = "true";
        this.payload.endDate = this.endDate;
      } else {
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
    },
    pagination(val) {
      if (val["sortBy"] === "jobPrice") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "jobPrice";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "jobPrice";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        }
      } else if (val["sortBy"] === "additionalCharges") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "additionalCharges";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "additionalCharges";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        }
      }

      if (!val["sortBy"]) {
        delete this.payload["sortType"];
        delete this.payload["sortBy"];
        if (val["descending"] == null) {
          this.componentKey = this.componentKey + 1;
        }
      }
      deep: true;
    },
    dateRange: function (n, o) {
      if (n != "") {
        this.$eventBus.$emit("on-load");
        this.loading = false;
        if (n.startDate && n.endDate) {
          this.startDate = moment(this.dateRange.startDate).format(
            "YYYY-MM-DD"
          );
          this.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD");
        }
        this.$set(this.payload, "startDate", this.startDate);

        this.payload.isSearch = "true";
        this.payload.endDate = this.endDate;
      } else {
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
    },
    searchText: function (n, o) {
      if (n.trim() != "") {
        this.$eventBus.$emit("on-load");
        this.loading = false;

        this.payload.isSearch = "true";

        this.payload.searchType = this.Type;
        this.$set(this.payload, "searchText", n);
      }
    },
    searchCustomer: function (n, o) {
      this.generateInvoiceCount = 0;
      this.$emit("count", this.generateInvoiceCount);
      this.$emit("jobList-Id", []);
      this.customerSearchData = n;
      this.selectedids = [];
      this.selectednames = [];
      if (n) {
        this.$eventBus.$emit("on-load");
        if (
          this.$props.dateRangenew &&
          this.$props.dateRangenew[0] &&
          this.$props.dateRangenew[1]
        ) {
          this.startDate = moment(n[0]).format("YYYY-MM-DD");
          this.endDate = moment(n[1]).format("YYYY-MM-DD");
          this.getDashboardData(n, this.startDate, this.endDate);
        } else {
          this.getDashboardData(n);
        }
        this.loading = false;

        this.payload.isSearch = "true";
        this.$set(this.payload, "customerId", n);
      }
    },
    selectedCustInvoiceFailed: function (n, o) {
      this.openOnInvoiceFail(n);
    },

    resetCustomer: function (n, o) {
      if (n) {
        this.selectedids = [];
        this.selectednames = [];
        this.customerSearchData = null;
        this.generateInvoiceCount = 0;
        this.$eventBus.$emit("on-load");
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
    },
  },

  data() {
    return {
      e1: 1,
      steps: 1,
      customerSearchData: null,
      confirmationDialog: false,
      confirmation: "",
      singleExpand: true,
      invoicingCity: "",
      invoicingCountry: "",
      bankInfo: [],
      bankName: [],
      bank: "",
      beneficiaryName: "",
      branchCode: "",
      ibanNo: "",
      swiftCode: "",
      accountNo: "",
      bankId: "",
      generateInvoiceCount: 0,
      error: null,
      subJobArray: [],
      assignments: [],
      isAssignCustomerRecipt: [],
      totalAdditionalAmountTab: [],
      jobTypeArray: [],
      isAssignDriver: [],
      invoiceDay: null,
      valid: true,
      x: {
        registerSuccess: false,
      },
      options: {
        color: "#ffc04c",
        width: 450,
        zIndex: 200,
      },
      pagination: {},
      dialog1: false,
      workingCountry: null,
      userDetail: {},
      invoiceDay: null,
      operationName: "",
      id: null,
      selected: [],
      selectedids: [],
      selectednames: [],
      loading: false,
      componentKey: 0,
      showScreen: true,
      totalPages: 0,
      loading: false,
      pages: 1,
      payload: {
        workingCountry: this.workingCountry,
      },

      headers: accountList,
      items: [],
      isMultipleInvoiceFailed: false,
    };
  },
  methods: {
    stop(e) {
      e.stopPropagation();
    },
    toDataURL(url) {
      return fetch(url)
        .then((response) => {
          return response.blob();
        })
        .then((blob) => {
          return URL.createObjectURL(blob);
        });
    },

    confirm() {
      this.confirmationDialog = false;
    },
    page(event) {
      this.pages = event;
    },
    setCustomerValues(val) {
      var data = this.bankInfo.filter((v) => {
        if (v.value === val) return v;
      });
      (this.beneficiaryName = data[0].beneficiaryName),
        (this.branchCode = data[0].branchCode),
        (this.accountNo = data[0].accountNo),
        (this.ibanNo = data[0].ibanNo),
        (this.bankId = data[0].bankId),
        (this.swiftCode = data[0].swiftCode);
      // this.beneficiaryName = data[0].beneficiaryName;
    },
    checkLoading(event) {
      if (event) {
        this.loading = true;
      } else {
        // this.$emit("job-load", false);
        this.loading = false;
      }
    },
    addCreditDuration() {
      if (!this.invoiceDay) {
        this.loading = false;
        this.error = "Please enter the credit duration";
        return;
      }

      if (!this.invoiceDay.match(/^([1-9][0-9]{0,3}|10000)$/)) {
        this.loading = false;
        this.error =
          "Please enter valid duration greater than 0 less than 10000";
        return;
      }
      this.loading = true;
      let url = "/dmsAdmin/editInvoiceDay";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        userId: this.userDetail._id,
        invoiceDay: parseInt(this.invoiceDay),
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.userDetail.invoiceDay = this.invoiceDay;
          //this.items = response.data.data;
          if (this.isMultipleInvoiceFailed) {
            this.selectedids = [];
            this.selectednames = [];
            this.generateInvoiceCount = 0;
            this.$emit("count", this.generateInvoiceCount);
            this.$emit("jobList-Id", []);
            this.isMultipleInvoiceFailed = false;
            this.dialog1 = false;
          }
          this.loading = false;
          this.refresh();
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    fetchBank() {
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        invoicingCity: {
          code: this.userDetail.invoicingCity,
        },
        invoicingCountry: {
          code: this.userDetail.invoicingCountry,
        },
      };
      let { apiUrl } = this.constants;
      this.axios.post(`${apiUrl}/accounts/getBankDetails`, body, config).then(
        (response) => {
          const { data = null } = response.data;
          data.forEach((element) => {
            this.bankInfo.push({
              text: element.bankName,
              value: element._id,
              beneficiaryName: element.beneficiaryName,
              branchCode: element.branchCode,
              accountNo: element.accountNo,
              ibanNo: element.ibanNo,
              bankId: element._id,
              swiftCode: element.swiftCode,
            });
          });
        },
        (error) => {
          this.error = "Something went wrong. Please try again later!";
          this.loading = false;
        }
      );
    },

    getDashboardData(customerId, startDate, endDate) {
      this.loading = true;
      let url = "/dmsAdmin/jobCountForAccounts";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        workingCountry: this.workingCountry,
      };
      if (customerId) {
        body.customerId = customerId;
      }
      if (startDate && this.endDate) {
        body.startDate = startDate;
        body.endDate = endDate;
      }
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          const data = response.data.data;
          this.dashboardData(data);

          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Fetch Data";
          this.loading = false;
        }
      );
    },
    openOnInvoiceFail(userData) {
      this.isMultipleInvoiceFailed = true;
      this.dialog1 = true;
      this.userDetail = userData;
    },
    open(userData, id) {
      this.dialog1 = true;
      this.userDetail = userData;
      this.fetchBank();
      this.id = id;
    },
    closeRegistration() {
      this.x.registerSuccess = false;
      // this.componentKey=this.componentKey+1
    },
    refresh() {
      this.componentKey = this.componentKey + 1;
    },
    getJobType(type) {
      switch (type) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    getAssignmentList(jobId) {
      this.loading = true;
      let url = "/dmsAdmin/jobDetailsForAccount";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        jobId: jobId.toString(),
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          const data = response.data.data;
          this.invoicingCity = response.data.data.jobInfo.invoicingCity;
          this.invoicingCountry = response.data.data.jobInfo.invoicingCompany;
          const status = response.status;
          const { jobInfo = null, assignmentList = [] } = data;
          var size = assignmentList.length;
          this.steps = size;
          this.assignments = [];
          this.jobTypeArray = [];
          this.subJobArray = [];
          for (var i = 0; i < size; i++) {
            this.assignments[i] = assignmentList[i].assignmentListing;
            this.isAssignDriver[i] = assignmentList[i].isAssignDriver;
            this.jobTypeArray[i] = assignmentList[i].jobType || "1";
            this.subJobArray[i] = assignmentList[i];
            this.isAssignCustomerRecipt[i] =
              assignmentList[i].isCustomerReceiptInitiated;
            this.totalAdditionalAmountTab[i] =
              assignmentList[i].totalAdditionalAmount;

            var prices = {
              driverCost: null,
              customerCost: null,
              trukkinCost: null,
              AdditionalCost: null,
            };
          }
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to Fetch Data";
          this.loading = false;
        }
      );
    },
    getStatus(status) {
      switch (status) {
        case 1:
          return "Active";
        case 2:
          return "Complete";
        case 3:
          return "Customer Rate Accepted";
        case 4:
          return "Finding Drivers";
        case 5:
          return "Driver Assigned";
        case 6:
          return "In-Transit";
        case 7:
          return "Shipment Offloaded";
        case 8:
          return "Payment Pending";
        case 9:
          return "Payment Done";
        case 10:
          return "Low Credit Limit";

        case -1:
          return "Job Cancelled";
        default:
          return "Unknown";
      }
    },
    retry(jobId) {
      this.loading = true;
      let url = "/dmsAdmin/generateWorkOrder";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = { jobId: jobId.toString() };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.x.registerSuccess = true;
          this.x.message = response.data.data.message;
          this.loading = false;
          this.refresh();
          this.getAssignmentList(jobId);
          setTimeout(() => (this.dialog = false), 100);
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    async retrySO(jobId) {
      this.operationName = "generate-downaload-so-si-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        let url = "/dmsAdmin/generateSaleOrder";
        this.loading = true;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = { jobId: jobId.toString() };

        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.x.registerSuccess = true;
            this.x.message = response.data.data.message;
            this.loading = false;
            this.refresh();
            this.getAssignmentList(jobId);
            setTimeout(() => (this.dialog = false), 100);
          },
          (error) => {
            this.loading = false;
            this.error = error.response.data.message;
          }
        );
      } else {
        this.loading = false;
        return;
      }
    },
    async checkViewInvoice(
      url,
      isDownloadInvocie,
      isSaleInvoviceBackgroundProcess,
      invoiceNumber,
      e
    ) {
      this.loading = true;
      e.stopPropagation();
      this.operationName = "generate-downaload-so-si-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.loading = false;
        if (!isDownloadInvocie || isSaleInvoviceBackgroundProcess) {
          this.loading = false;
          this.error = "Background process is ongoing ";
          return;
        }
        /* Mixin function*/
        this.loading = false;
        this.forceDownload(url, "Sales Invoice" + " " + invoiceNumber);
      } else {
        this.loading = false;
        return;
      }
    },
    async generateInvoiceNumber() {
      this.loading = true;
      this.operationName = "generate-downaload-so-si-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (!this.bank) {
        this.loading = false;
        this.error = "Please choose bank before generating the invoice";
        return;
      }
      if (y) {
        this.loading = true;
        if (!this.userDetail.invoiceDay) {
          this.loading = false;
          this.error = "Please enter the credit limit duration";
          return;
        }
        let url = "/dmsAdmin/generateSalesInvoice";
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = { jobId: this.id.toString(), bankId: this.bankId };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            //this.items = response.data.data;
            this.loading = false;
            this.x.registerSuccess = true;
            this.dialog1 = false;
            this.getAssignmentList(this.id);
            this.componentKey = this.componentKey + 1;
            // this.x.message = "Drivers notified";
          },
          (error) => {
            this.loading = false;
            this.error = error.response.data.message;
          }
        );
      } else {
        this.loading = false;
        return;
      }
    },
    async check(id) {
      this.loading = true;
      this.operationName = "view-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.loading = false;
        this.goToProfile(id);
      } else {
        this.loading = false;
        return;
      }
    },
    closeBankInfo() {
      this.dialog1 = false;
      this.invoiceDay = null;
      this.isMultipleInvoiceFailed = false;
      this.bankInfo = [];
      this.bank = "";
      (this.beneficiaryName = ""),
        (this.branchCode = ""),
        (this.accountNo = ""),
        (this.ibanNo = ""),
        (this.bankId = ""),
        (this.swiftCode = "");
    },
    checkPermission(operation) {
      const { serverUrl, rbacUrl, apiUrl } = this.constants;
      this.$http
        .post(`${rbacUrl}/checkUserPermission`, {
          userId: localStorage.getItem("userId"),
        })
        .then((res) => {
          if (!res.data.data) {
            this.loading = false;
          }
          let old = res.data.data.roles;
          old = old.map((x) => x.role.roleName.toLowerCase());
          let roles = JSON.parse(localStorage.getItem("permissions")).roles;
          roles = roles.map((x) => x.role.roleName.toLowerCase());
          old.sort();
          roles.sort();
          if (old.toString() != roles.toString()) {
            this.showScreen = false;
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
            Swal.fire({
              title: "Oops!",
              text: "Your permissions have changed .",
              type: "error",
              allowOutsideClick: false,
              confirmButtonColor: "orange",
              confirmButtonText: "Okay",
            }).then((result) => {
              localStorage.setItem("first-load", true);
              this.$router.replace("/");
              location.reload();
            });
          }
          if (old.toString() == roles.toString()) {
            localStorage.setItem("permissions", JSON.stringify(res.data.data));
          }
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    getTime3(date) {
      return moment.utc(date).format("DD/MM/YYYY");
    },
    callback(res) {
      this.$eventBus.$emit("off-load");
      this.loading = false;
      this.items = res.jobInfo;
      this.items.forEach((e) => {
        e.selected = false;
        if (this.selectedids.includes(e.jobId)) {
          e.selected = true;
        }
      });

      this.totalPages = Math.ceil(res.totalData / 15);
      this.pagination.rowsPerPage = 15;
    },
    paginationPayload(event) {
      this.payload = event;
    },

    getTime(time) {
      return moment.unix(time).format("DD/MM/YYYY");
    },

    checkSelected(select, id, e) {
      if (select && !this.selectedids.includes(id)) {
        this.generateInvoiceCount = this.generateInvoiceCount + 1;
        this.$emit("count", this.generateInvoiceCount);
        this.selectedids.push(id);
        this.selectednames.push(id);
      }
      if (!select && this.selectedids.includes(id)) {
        this.generateInvoiceCount = this.generateInvoiceCount - 1;
        this.$emit("count", this.generateInvoiceCount);
        this.selectedids.splice(this.selectedids.indexOf(id), 1);
        this.selectednames.splice(this.selectednames.indexOf(id), 1);
      }
      this.$emit("jobList-Id", this.selectednames);
      this.$emit("selectedCustomerData", this.items[0]["userData"]);
    },
  },
};
</script>
<style scoped>
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.fonthead {
  font-size: 20px;
}
.download-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: blue;
  border-radius: 5px;
  border: 2px solid blue;
  padding: 3px 5px;
  margin-right: 2px;
}
/* .track-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 2px 5px;
} */

.account-ass-btn {
  text-decoration: none;
  color: orange;
  border: 2px solid darkorange;
  padding: 2px 8px;
  border-radius: 5px;
  margin-top: 6px;
}
.account-disabled-ass-btn {
  text-decoration: none;
  color: lightgray;
  border: 2px solid lightgray;
  padding: 2px 8px;
  border-radius: 5px;
}
.v-stepper__header {
  justify-content: left !important;
}
</style>
