<template>
  <v-container fluid id="filter_wrap">
    <v-layout justify-end pt-0 class="mr-8">
      <v-card-actions class="justify-center">
        <v-btn
          v-if="showButtonCustomer"
          class="openMarked-btn-color"
          style="padding: 18px !important"
          flat
          prepend-icon
          color="white"
          @click.native="markedOff()"
        >
          Marked off
        </v-btn>
      </v-card-actions>

      <v-card-actions class="justify-center">
        <v-btn
          v-if="showButtonCustomer"
          class="Loss-btn-color"
          style="padding: 18px !important"
          flat
          prepend-icon
          color="white "
          @click.native="LossToTrukkin()"
        >
          Loss To Trukkin
        </v-btn>
      </v-card-actions>

      <v-card-actions class="justify-center">
        <customerReceiveList
          v-if="showButtonCustomer"
          :customerId="selectedCustomer"
          :selectedCustomerWorkingCoun="this.$props.selectedCustomerWorkingCoun"
        />
      </v-card-actions>
    </v-layout>
    <v-layout row pt-0>
      <v-flex xs3 pt-3 pr-3>
        <v-autocomplete
          label="Select Customer"
          :items="customers"
          class="pt-0 currencyTitle"
          v-model="selectedCustomer"
          @change="searchByCustomer()"
          item-text="text"
          item-value="value"
          single-line
        />
      </v-flex>
      <v-flex xs2 pt-3 pr-3>
        <v-autocomplete
          class="pt-0 currencyTitle"
          :items="jobStatus"
          v-model="invoiceSelect"
          label="Invoice Type"
          single-line
          autocomplete="offfadsf"
          @change="searchData()"
        />
      </v-flex>
      <v-flex xs2 pt-3 pr-3>
        <v-text-field
          class="pt-0 currencyTitle"
          v-model="invoiceNumber"
          label="Search Invoice Number"
          single-line
          autocomplete="offfadsf"
          @keyup.enter="searchText"
          @click:append="searchText"
          append-icon="search"
        >
        </v-text-field>
      </v-flex>
      <v-flex xs2 pt-3 pr-3>
        <v-autocomplete
          class="pt-0 currencyTitle"
          :items="SalesInvoiceType"
          v-model="invoiceSelectSales"
          label="Sales Invoice Type"
          single-line
          autocomplete="offfadsf"
          @change="searchDataSales()"
        />
      </v-flex>
      <v-flex xs3 pt-3 pr-3 id="accountDate" class="date_9">
        <ejs-daterangepicker
          :placeholder="waterMark"
          v-model="dateRange2"
        ></ejs-daterangepicker>
      </v-flex>

      <v-flex xs1 class="reset-btn" mt-2 pl-4>
        <v-card-actions class="justify-center pt-1">
          <v-btn
            class="reset-btn-color"
            style="padding: 12px !important"
            flat
            prepend-icon
            color="orange darken-1"
            @click.native="reset()"
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { EventBus } from "../../../../event-bus.js";

import removeLocalstorageMixin from "@/mixins/removeLocalstorageMixin";
import moment from "moment";
import customerReceiveList from "@/components/Accounts/CustomerPayment/CustomerPopups/customerReceiveList";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { StorageKeys, JobListTypes } from "../../../../constants/constants";
import { salesSectionApiUrl } from "../../../../constants/api-urls";

export default {
  mixins: [removeLocalstorageMixin],
  name: "customFilterCustomer",
  components: {
    customerReceiveList,
  },
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;

      this.payload = {
        workingCountry: this.workingCountry,
      };
      this.fetchShippers(this.workingCountry);
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.path == "/accounts/customerPayment") {
        this.workingCountry = JSON.stringify(val);
        this.reset();
        this.fetchShippers(this.workingCountry);
      }
    });
  },
  props: {
    selectedCustomerWorkingCoun: String,
  },
  data() {
    return {
      customers: [],
      jobStatus: [
        { text: "Pending Invoice", value: "pending" },
        { text: "Closed Invoice", value: "closed" },
      ],
      SalesInvoiceType: [
        { text: "Job Level", value: "JOB" },
        { text: "Assignment Level", value: "ASSIGNMENT" },
      ],
      invoiceType: [
        {
          status: "Pending",
          abbr: "pending",
        },
        { status: "Active", abbr: "jobId" },
      ],
      waterMark: "Select a Range (Invoice Raised Date)",
      selectedCustomer: null,
      dateRange2: null,
      showButtonCustomer: false,
      adjustButton: false,
      lossButton: false,
      markedButton: false,
      startDate: "",
      endDate: "",
      invoiceSelect: "",
      invoiceSelectSales: "",
      invoiceNumber: "",
    };
  },
  watch: {
    dateRange2(val) {
      if (val) {
        if (val[0] && val[1]) {
          this.startDate = moment(val[0]).format("YYYY-MM-DD");
          this.endDate = moment(val[1]).format("YYYY-MM-DD");
          this.$emit("daterangenew-cust-pay", this.dateRange2);
        }
      } else {
        this.startDate = "";
        this.endDate = "";
      }
    },
  },
  methods: {
    fetchShippers(val) {
      this.customers = [];
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.t = config.headers.authorization;
      let body = { workingCountry: val, isVerified: true };
      this.axios
        .post(`${this.constants.apiUrl}/dmsAdmin/shippers`, body, config)
        .then((response) => {
          const { data = null } = response.data;
          for (var i = 0; i < data.length; i++) {
            data[i].fullName = data[i].name + "(" + data[i].companyName + ")";
          }

          data.forEach((element) => {
            if (element.companyName) {
              this.customers.push({
                text: element.fullName,
                value: element._id,
                userId: element.userId,
                companyName: element.companyName,
                companyVatNumber: element.companyVatNumber,
                address: element.address
                  ? element.address.replaceAll("\n", ", ")
                  : "",
                invoicingCity: element.invoicingCity,
                invoicingCountry: element.invoicingCountry,
                selectedIndustry: element.selectedIndustry,
                isVerifiedByAccounts: element.isVerifiedByAccounts,
              });
            } else {
              this.customers.push({
                text: element.name,
                value: element._id,
                userId: element.userId,
                companyName: element.companyName,
                companyVatNumber: element.companyVatNumber,
                address: element.address
                  ? element.address.replaceAll("\n", ", ")
                  : "",
                invoicingCity: element.invoicingCity,
                invoicingCountry: element.invoicingCountry,
                selectedIndustry: element.selectedIndustry,
                isVerifiedByAccounts: element.isVerifiedByAccounts,
              });
            }
          });
        });
    },

    searchText() {
      this.$emit("customer-search-pay", this.invoiceNumber.trim());
    },

    searchByCustomer() {
      if (this.selectedCustomer) {
        this.$emit("request-customerFilter", this.selectedCustomer);
        this.showButtonCustomer = true;
      }
    },
    searchData() {
      this.$emit("invoice-search-pay", this.invoiceSelect);
    },
    searchDataSales() {
      this.$emit("invoice-search-pay-type", this.invoiceSelectSales);
    },
    reset() {
      this.$emit("customer-reset-receipt");
      this.selectedCustomer = null;
      this.showButtonCustomer = false;
      this.invoiceNumber = "";
      this.invoiceSelect = "";
      this.invoiceSelectSales = "";
      this.dateRange2 = null;
    },
    LossToTrukkin() {
      this.lossButton = true;
      this.$emit("loss-button", this.lossButton);
    },
    adjust() {
      this.adjustButton = true;
      this.$emit("adjust-button", this.adjustButton);
    },
    markedOff() {
      this.markedButton = true;
      this.showButtonCustomer = false;
      this.$emit("marked-button", this.markedButton);
    },
  },
};
</script>
<style scoped>
.openMarked-btn-color {
  background-color: #042f71 !important;
  text-transform: capitalize;
  width: 100%;
}
.adjust-btn-color {
  background-color: #4089fa !important;
  text-transform: capitalize;
  width: 100%;
}
div#ej2-datetimepicker_0_popup {
  left: 827.2px !important;
}
#accountDate .e-clear-icon {
  display: none !important;
  opacity: 0;
}
#accountDate ::placeholder {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: 500;
}
.Loss-btn-color {
  background-color: #e3471b !important;
  text-transform: capitalize;
  width: 100%;
}
.Recieve-btn-color {
  background-color: #539b56 !important;
  text-transform: capitalize;
  width: 110px;
}
.mr-8 {
  margin-right: -6px;
}
</style>
