<template>
  <div class="log">
    <v-btn
      class="Recieve-btn-color"
      style="padding: 18px !important"
      flat
      prepend-icon
      color="white "
      @click.stop="dialog = false"
      @click="getList()"
    >
      Receive/Adjust
    </v-btn>

    <v-dialog v-model="dialog" max-width="98%" persistent>
      <v-form ref="form" onsubmit="return false;" class="layout__list">
        <v-card style="padding: 0px !important">
          <v-toolbar
            class="pd__left__10"
            dark
            style="background-color: #dcdcdc; color: black"
            flat
          >
            <v-toolbar-title
              >Adjust & Receive Amount From Customer</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn class="mx-2" fab small @click="dialog = false">
              <v-icon dark> close </v-icon>
            </v-btn>
          </v-toolbar>

          <v-spacer>
            <v-card-text class="nospace">
              <v-card-text class="nospace">
                <loading :active.sync="loading" />

                <v-layout
                  style="
                    border-top: 1px solid #efefef;
                    justify-content: end;
                    white-space: nowrap;
                    padding-left: 7px;
                  "
                >
                  <v-flex xs7 pt-3 pl-1
                    ><span class="dashboard-style">Total Received Amt. : </span>
                    {{ totalReceivedAmount.toFixed(2) }} {{ currency }}</v-flex
                  >
                  <v-flex xs6 pt-3 pl-2
                    ><span class="dashboard-style">Total Adjusted Amt. : </span
                    >{{ totalAdjustedAmount.toFixed(2) }} {{ currency }}</v-flex
                  >

                  <v-flex xs6 pt-3 pl-2
                    ><span class="dashboard-style">Current Balance : </span>
                    {{ totalBalanceAmount.toFixed(2) }} {{ currency }}</v-flex
                  >

                  <v-flex xs2>
                    <v-card-actions class="justify-center" mt-1>
                      <v-btn
                        v-if="items.length"
                        flat
                        outline
                        class="reset-btn"
                        color="indigo"
                        @click.native="download()"
                      >
                        <v-icon color="indigo">download</v-icon>
                        download
                      </v-btn>
                    </v-card-actions>
                  </v-flex>

                  <v-flex xs2 class="button__align2" style="margin-top: 2px">
                    <receivePayment
                      :customerId="this.$props.customerId"
                      :selectedCustomerWorkingCoun="
                        this.$props.selectedCustomerWorkingCoun
                      "
                    />
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-data-table
                id="TransporterListTable"
                hide-actions
                :headers="headers"
                :items="items"
                :pagination.sync="pagination"
                style="word-break: break-word"
              >
                <template slot="items" slot-scope="props">
                  <td>
                    <v-layout row>
                      <span>
                        <activityLogs
                          :type="'1'"
                          :_id="props.item._id"
                          :id="props.item.receiptNumber"
                          :subType="15"
                        />
                      </span>

                      &nbsp;<span class="pt-1"
                        >{{ props.item.amount.toFixed(2) }}
                        {{ props.item.customerCurrency }}</span
                      >
                    </v-layout>
                  </td>
                  <td>
                    {{ props.item.balanceAmount.toFixed(2) }}
                    {{ props.item.customerCurrency }}
                  </td>
                  <td>
                    {{ props.item.adjustedAmount.toFixed(2) }}
                    {{ props.item.customerCurrency }}
                  </td>
                  <td v-if="props.item.adjustedAmount > 0">
                    {{
                      props.item.isInvoiceLevelAssignment
                        ? "Assignment Level"
                        : "Job Level"
                    }}
                  </td>
                  <td v-else>N.A</td>
                  <td>
                    {{ getTime4(props.item.actualReceivedDate) }}<br />{{
                      getTime5(props.item.actualReceivedTime)
                    }}
                  </td>

                  <td>
                    {{ props.item.createdBy["name"][0] }}<br />
                    ({{ props.item.receiptNumber }})
                  </td>
                  <td>
                    <span
                      v-if="
                        props.item.paymentMode == 'Cash' ||
                        props.item.paymentMode == 'Cheque'
                      "
                    >
                      {{ props.item.paymentMode }}
                    </span>
                    <br />
                    <span v-if="props.item.paymentMode == 'Cheque'">
                      {{ props.item.chequeNumber }}
                    </span>
                    <span v-if="props.item.paymentMode == 'Bank Account'">
                      Bank Name -
                      {{ props.item.bankName }}<br />
                      Remitter Bank Name -
                      {{ props.item.remitterBankName || "N.A" }}<br />
                    </span>
                  </td>

                  <td v-if="props.item.adjusts.length" style="max-width: 250px">
                    <v-flex v-if="props.item.adjusts.length <= 2">
                      {{
                        props.item.adjusts
                          ? props.item.adjusts
                              .map((e) => e.invoiceNumber)
                              .join(", ")
                          : ""
                      }}
                    </v-flex>

                    <v-flex v-else>
                      <v-tooltip
                        top
                        class="milestone"
                        content-class="tooltip-op-comment"
                        color="orange"
                      >
                        <template slot="activator">
                          {{
                            props.item.adjusts
                              .map((e) => e.invoiceNumber)
                              .slice(0, 2)
                              .join(", ")
                          }}
                          <span>...</span></template
                        >

                        <span class="tooltip">
                          {{
                            props.item.adjusts.map((e) => e.invoiceNumber)
                              ? props.item.adjusts
                                  .map((e) => e.invoiceNumber)
                                  .join(", ")
                              : ""
                          }}
                        </span>
                      </v-tooltip>
                    </v-flex>
                  </td>
                  <td v-else>N.A</td>

                  <td style="width: 20%">
                    <v-flex xs12>
                      <ul
                        style="
                          position: relative;
                          list-style-type: none;
                          display: inline-block;
                          width: 200px;
                          padding-left: 0px !important;
                        "
                      >
                        <li
                          :key="i"
                          style="display: inline-block"
                          v-for="(doc, i) in props.item.paymentProof"
                          class="list"
                          ref="documentList"
                        >
                          <a
                            :href="doc"
                            class="document-link"
                            style="text-decoration: none; outline: none"
                            v-if="
                              doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'
                            "
                            target="_blank"
                          >
                            <img
                              src="../../../../assets/pfd.png"
                              height="40"
                              width="32"
                            />
                          </a>
                          <a
                            :href="doc"
                            style="text-decoration: none; outline: none"
                            class="document-link"
                            target="_blank"
                            v-else
                          >
                            <img
                              src="../../../../assets/docimg.webp"
                              height="40"
                              width="40"
                            />
                          </a>
                        </li>
                      </ul>
                    </v-flex>
                  </td>

                  <td
                    v-if="props.item.depositComments.length"
                    style="max-width: 250px"
                  >
                    <v-flex v-if="props.item.depositComments.length <= 80">
                      {{ props.item.depositComments }}
                    </v-flex>

                    <v-flex v-else>
                      <v-tooltip
                        top
                        class="milestone"
                        content-class="tooltip-op-comment"
                        color="orange"
                      >
                        <template slot="activator">
                          {{ props.item.depositComments.slice(0, 81) }}
                          <span>...</span></template
                        >

                        <span class="tooltip">
                          {{ props.item.depositComments }}
                        </span>
                      </v-tooltip>
                    </v-flex>
                  </td>
                  <td v-else>N.A</td>

                  <td>
                    <EditReceivePayment
                      :receiveCustDetails="props.item"
                      :selectedCustomerWorkingCoun="selectedCustomerWorkingCoun"
                    />
                  </td>
                </template>
              </v-data-table>

              <pagination
                :totalPages="totalPages"
                :pages="this.pages"
                @pageNo="page"
                :url="listApiUrl"
                :callback="callback"
                :componentKey="componentKey"
                :action="`customerList`"
                :payload="payload"
                :type="1"
                v-on:pagination-load="checkLoading"
              />
              <success-dialog
                :content="x.message"
                :show="x.success"
                :onclose="closeMessage"
              />

              <v-snackbar
                :timeout="3000"
                color="red darken-2"
                v-model="error"
                class="white--text"
                v-if="error"
                >{{ error }}</v-snackbar
              >
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import { EventBus } from "../../../../event-bus.js";
import Loading from "vue-loading-overlay";
import { customerRecieveList } from "@/constants/datatable-headers.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import receivePayment from "./receivePayment.vue";
import EditReceivePayment from "./EditReceivePayment.vue";
import activityLogs from "@/components/Common/activityLogs";
import moment from "moment";
import pagination from "@/components/Pagination/pagination";
import { receieveCustomerPayment } from "@/constants/api-urls.js";
import downloadMixin from "@/mixins/downloadMixin";

import {
  StorageKeys,
  JobTypes,
  JobListTypes,
  CancellationType,
} from "../../../../constants/constants";
import { async } from "q";
import PaginationButtons from "@/components/Pagination/Pagination-New";
export default {
  mixins: [downloadMixin],
  created() {
    this.$eventBus.$on("customerRecipt", (value) => {
      setTimeout(() => {
        this.refresh();
      }, 2000);
    });

    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;

      this.payload = {
        customer_id: this.$props.customerId,
      };
    }
  },
  components: {
    PaginationButtons,
    SuccessDialog,
    pagination,
    activityLogs,
    EditReceivePayment,
    Loading,
    receivePayment,
  },
  props: {
    loadingstop: Function,
    loader: Boolean,
    assignId: String,
    advancePaid: Number,
    viewOnly: Boolean,
    section: String,
    customerId: String,
    isVisible: Number,
    selectedCustomerWorkingCoun: String,
  },

  data() {
    return {
      opeartionName: "",
      workingCountry: null,
      listApiUrl: receieveCustomerPayment,
      componentKey: 0,
      count: 0,
      rejectDialog: false,
      rejectReason: null,
      assignDetails: {},
      walletInfo: {},
      customerStatus: "",
      bankName: "",
      ownerType: "",
      advanceId: null,
      JobTypes: [],
      pages: 1,
      invoiceCompany: "",
      invoiceCity: "",
      totaldata: "",
      advanceAmount: null,
      totaladvancePaid: null,
      advanceStatus: "",
      currency: "",
      cancellationType: [],
      cancellationReason: "",
      cancelType: "",
      status: null,
      jobType: "",
      jobId: "",
      assignID: null,
      isRejectTrue: false,
      paymentAmount: null,
      assignmentDetails: {},
      pagination: {},
      isMarkedAllRead: false,
      error: "",
      valid: true,
      lazy: false,
      cancelDialog: false,
      isCancelAll: null,
      cancelreason: "",
      loading: false,
      dialog: false,
      dialog2: false,
      processing: false,
      totalReceivedAmount: 0,
      totalAdjustedAmount: 0,
      totalBalanceAmount: 0,
      x: {
        message: "The advance has been successfully cancelled",
        loading: true,
        success: false,
        menu: false,

        error: null,
        missingField: null,
      },
      search: "",
      totalPages: 0,
      payload: {},
      headers: customerRecieveList,
      items: [],
    };
  },

  methods: {
    page(event) {
      this.pages = event;
    },

    getTime4(date) {
      return moment(date).format("ll  h:mm a");
    },

    getList() {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        let workingCountry = [];
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            workingCountry.push(key.value);
          }
        });
        this.workingCountry = workingCountry;

        this.payload = {
          customer_id: this.$props.customerId,
        };
        this.dialog = true;
      }
    },

    checkLoading(event) {
      if (event) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },

    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
    },
    getTime3(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    getTime4(date) {
      return moment(date).format("DD/MM/YYYY ");
    },
    getTime5(time) {
      return moment(time, "HH:mm").format("hh:mm A");
    },

    // Download Recieve Amount Listing

    download() {
      let url = [];

      url.push(`customer_id=${this.$props.customerId}`);

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let finalUrl = "/customerPayment/payment/downloadPassbook?";
      if (url.length > 0) {
        let params = url.join("&");
        finalUrl = finalUrl + params;
      } else return "";
      let fileName = `receive_report`;
      this.DownloadExcel(this.constants.apiUrl + finalUrl, fileName);
    },

    //callback function for  pagination and fetch table data

    callback(res) {
      if (res.walletInfo) {
        (this.walletInfo = res.walletInfo),
          (this.totalReceivedAmount = this.walletInfo.totalReceivedAmount),
          (this.totalAdjustedAmount = this.walletInfo.totalAdjustedAmount),
          (this.totalBalanceAmount = this.walletInfo.totalBalanceAmount);
        this.currency = this.walletInfo.currency;
      }

      this.items = [...res.list];

      this.totalPages = Math.ceil(res.totalData / 5);
      this.pagination.rowsPerPage = 5;
      window.scrollTo(0, 0);
    },

    // refresh all listing of table
    refresh() {
      this.componentKey = this.componentKey + 1;
    },

    paginationPayload(event) {
      this.payload = event;
      this.loading = true;
    },
  },
};
</script>
<style lang="scss">
.pd__left__4 .v-toolbar__content {
  padding-left: 10px !important;
}
.card--raised {
  border: 1px solid orange !important;
}
.download__btn {
  position: relative;
  margin-right: 127px;
  right: 0;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
.card--raised {
  border: 1px solid orange !important;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.dashboard-style {
  color: grey;
  font-size: 16px;
  font-weight: 700;
}
.p-16 {
  padding: 8px !important;
}
.track-ass-btn {
  text-decoration: none;
  margin: 7px !important;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 6px 15px;
}
.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.tooltip-op-comment {
  max-width: 40%;
  opacity: 1 !important;
}
.editCustomer {
  margin: auto 0;
}
.flex.button__align2 {
  display: flex;

  padding-left: 0 !important;
}
.tooltip {
  font-size: 13px;
  font-weight: 500;
}
.tooltip-op {
  opacity: 1 !important;
}

.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.color-black {
  color: #000000 !important;
}
.reset-btn {
  text-transform: capitalize;
  width: 100%;
}

.cancel-ass-btn {
  text-decoration: none;
  color: #ffffff;

  border-radius: 5px;
  border: 2px solid red;
  padding: 2px 0px;
}
.reject-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: red;
  border-radius: 5px;
  border: 2px solid red;
  padding: 2px 0px;
}

.green-button {
  border-radius: 5px;
  background-color: #63ae63 !important;
  color: #ffffff !important;
  border: 2px solid green !important;
}
.red-button {
  border-radius: 5px;
  background-color: #ff7b7b !important;
  color: #ffffff !important;
  border: 2px solid red !important;
}
.white-button {
  border-radius: 5px;
  background-color: white !important;
  color: grey !important;
  border: 2px solid grey !important;
}
.blue-button {
  border-radius: 5px;
  background-color: blue !important;
  color: #ffffff !important;
  border: 2px solid blue !important;
}
.yellow-button {
  border-radius: 5px;
  background-color: #ffd65c !important;
  color: #ffffff !important;
  border: 2px solid #ffc107 !important;
}

.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.p-b-0 {
  padding-bottom: 0px !important ;
}
.p-t-0 {
  padding-top: 0px !important ;
}
.text-stylee {
  font-size: 20px;
}
.Recieve-btn-color {
  background-color: #539b56 !important;
  text-transform: capitalize;
  width: 110px;
}
</style>
