<template>
  <v-tabs
    fixed-tabs
    class="m-b-20"
    background-color="darkorange"
    dark
    v-model="changeTab"
  >
    <v-tab :href="`#paymentDetail`" @click="filterByTab('paymentDetail')"
      >Jobs Payment Detail
    </v-tab>
    <v-tab :href="`#advanceDetail`" @click="filterByTab('advanceDetail')"
      >Assignment Payment Details
    </v-tab>
    <!-- <v-tab
      v-if="workingCountry.includes('UAE') || workingCountry.includes('KSA')"
      :href="`#customerDetail`"
      @click="filterByTab('customerDetail')"
      >Customer Receipt
    </v-tab> -->
  </v-tabs>
</template>

<script>
import Loading from "vue-loading-overlay";
import { EventBus } from "../../event-bus.js";
export default {
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.path == "/accounts") {
        this.workingCountry = JSON.stringify(val);
        if (
          this.workingCountry.includes("UAE") ||
          this.workingCountry.includes("KSA")
        ) {
        } else {
          this.$router.push(`/accounts`);
        }

        this.loading = true;
      }
    });
  },
  data: () => ({
    active: "0",
    loading: true,
    workingCountry: null,
    changeTab: "paymentDetail",
  }),
  methods: {
    filterByTab(value) {
      if (value == "advanceDetail") {
        this.$router.push(`/accounts/advance`).catch(() => {});
      } else if (value == "customerDetail") {
        this.$router.push(`/accounts/customerPayment`);
      } else {
        this.$router.push(`/accounts`);
      }

      this.changeTab = value;
    },
  },
};
</script>

<style></style>
