<template>
  <v-card class>
    <v-card-title class="bg-clr p-t-0">
      <v-layout justify-space-around wrap>
        <v-avatar tile color="#ffa500" class="total avatar">
          <span class="white--text breakword">
            Total Jobs
            <span class="count">{{ dashboard.totalData }}</span></span
          >
        </v-avatar>

        <v-avatar tile color="#ffa500" class="total avatar">
          <span class="white--text breakword">
            Ongoing Jobs
            <span class="count">{{ dashboard.onGoingData }}</span></span
          >
        </v-avatar>
      </v-layout>
    </v-card-title>
    <SelectCountry
      :open="selectCountryDialog"
      @closeSelectCountry="selectCountryDialog = false"
      :type="'/customer/new'"
    />
  </v-card>
</template>
<script>
import SelectCountry from "../Common/SelectCountry";
export default {
  components: {
    SelectCountry,
  },
  props: ["dashboard"],
  data() {
    return {
      selectCountryDialog: false,
      operationName: "",
    };
  },
  methods: {},
};
</script>
<style scoped>
.dashboard {
  padding: 20px;
  margin: 0 auto;
}
.breakword {
  padding: 10px;
}
.p-b-0 {
  padding-bottom: 0 !important;
}

.f-16 {
  font-size: 16px !important;
}
</style>
