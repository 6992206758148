var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-radio-group',{staticClass:"mt--10",model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}},[_c('v-card-text',{staticClass:"pt-0 nospace"},[_c('v-data-table',{staticClass:"pt-0 nospace",attrs:{"id":"jobsTable","hide-actions":"","headers":_vm.headers,"disable-initial-sort":true,"pagination":_vm.pagination,"items":_vm.items},on:{"update:pagination":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_c('v-layout',{attrs:{"row":""}},[_c('span',{staticStyle:{"width":"22px !important"}},[(
                  _vm.radioButtonCheck(
                    props.item.outStandingAmount,
                    props.item.lossTReceiptNumber,
                    props.item.receivedAmount,
                    props.item.lossToTAmount
                  )
                )?_c('v-flex',[_c('v-tooltip',{staticClass:"milestone pt-1",attrs:{"top":"","content-class":"tooltip-op","color":"orange"}},[_vm._v("  "),_c('template',{slot:"activator"},[(_vm.lossRadio)?_c('v-radio',{attrs:{"disabled":_vm.radioButtonCheck(
                          props.item.outStandingAmount,
                          props.item.lossTReceiptNumber,
                          props.item.receivedAmount,
                          props.item.lossToTAmount
                        ),"value":props.item.invoiceNumber},on:{"change":function($event){return _vm.radioSelected(
                          props.item.customerId,
                          props.item.invoiceNumber,
                          props.item.outStandingAmount,
                          props.item.customerCurrency,
                          props.item.isInvoiceLevelAssignment
                        )}}}):_vm._e()],1),(props.item.receivedAmount == 0)?_c('span',{staticClass:"tooltip"},[_vm._v(" There is no amount adjusted against this invoice yet. Please adjust some amount. ")]):(props.item.outStandingAmount == 0)?_c('span',{staticClass:"tooltip"},[_vm._v(" There is no outstanding amount against this invoice yet. ")]):_c('span',{staticClass:"tooltip"},[_vm._v(" You can only edit the loss to Trukkin amount now. ")])],2)],1):_c('v-flex',[(_vm.lossRadio)?_c('v-radio',{staticClass:"iconred",attrs:{"disabled":_vm.radioButtonCheck(
                      props.item.outStandingAmount,
                      props.item.lossTReceiptNumber,
                      props.item.receivedAmount,
                      props.item.lossToTAmount
                    ),"value":props.item.invoiceNumber},on:{"change":function($event){return _vm.radioSelected(
                      props.item.customerId,
                      props.item.invoiceNumber,
                      props.item.outStandingAmount,
                      props.item.customerCurrency,
                      props.item.isInvoiceLevelAssignment
                    )}}}):_vm._e()],1)],1),_c('span',{staticStyle:{"margin-top":"1px"}},[_c('activityLogs',{attrs:{"type":'2',"_id":props.item.invoiceNumber,"id":props.item.invoiceNumber,"subType":9}})],1),_vm._v("     "),_c('v-tooltip',{staticClass:"milestone pt-1",attrs:{"top":"","content-class":"tooltip-op","color":"orange"}},[_vm._v("  "),_c('template',{slot:"activator"},[_vm._v(_vm._s(props.item.invoiceNumber))]),_c('span',{staticClass:"tooltip"},[_vm._v("Job Id "+_vm._s(props.item.jobIds ? props.item.jobIds.join(",") : ""))])],2)],1)],1),_c('td',[_vm._v(" "+_vm._s(props.item.companyName)+" ")]),_c('td',[_vm._v(" "+_vm._s(props.item.isInvoiceLevelAssignment ? "Assignment Level" : "Job Level")+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.getTime4(props.item.salesInvoiceGeneratedAt))+" ")]),(props.item.totalSalesAmount)?_c('td',[_vm._v(" "+_vm._s(props.item.totalSalesAmount.toFixed(2) || "0")+" "+_vm._s(props.item.customerCurrency || "")+" ")]):_c('td',[_vm._v("0.00")]),_c('td',[_vm._v(" "+_vm._s(props.item.receivedAmount.toFixed(2) || "0")+" "+_vm._s(props.item.customerCurrency || "")+" ")]),_c('td',[(!_vm.adjust && props.item.selected)?_c('span',[_vm._v(_vm._s(props.item.outStandingAmount || "0")+" "+_vm._s(props.item.customerCurrency))]):_vm._e(),(!props.item.selected)?_c('span',[_vm._v(_vm._s(props.item.outStandingAmount.toFixed(2) || "0")+" "+_vm._s(props.item.customerCurrency))]):_vm._e(),(props.item.selected && _vm.adjust)?_c('v-text-field',{attrs:{"label":"Enter Adjust Amount","type":"number","min":"0","rules":[_vm.rules.required]},on:{"input":function($event){_vm.amountFill(
                props.item.invoiceNumber,
                Number(props.item.amountEnter)
              )}},model:{value:(props.item.amountEnter),callback:function ($$v) {_vm.$set(props.item, "amountEnter", $$v)},expression:"props.item.amountEnter"}}):_vm._e()],1),_c('td',{staticStyle:{"max-width":"200px !important"}},[_c('v-layout',{staticStyle:{"grid-gap":"8px","height":"20px"}},[_c('span',[_c('activityLogs',{attrs:{"type":'4',"_id":props.item.lossTid,"id":props.item.invoiceNumber,"subType":10}})],1),(props.item.lossTstatus == 'Approved')?_c('span',{staticStyle:{"margin-top":"3px"}},[_vm._v(" "+_vm._s(( props.item.lossToTAmount + props.item.automaticAmount ).toFixed(2))+" "+_vm._s(props.item.customerCurrency || "")+" ")]):(props.item.lossTstatus == 'Pending')?_c('span',{staticStyle:{"margin-top":"3px"}},[(
                  props.item.automaticAmount > 0 &&
                  props.item.lossToTAmount == 0
                )?_c('span',[_vm._v(" "+_vm._s(props.item.automaticAmount.toFixed(2))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.item.lossToTAmount.toFixed(2))+" ")]),_vm._v(" "+_vm._s(props.item.customerCurrency || "")+" ")]):(props.item.lossTstatus == 'Rejected')?_c('span',{staticStyle:{"margin-top":"3px"}},[(props.item.automaticAmount > 0)?_c('span',[_vm._v(" "+_vm._s(props.item.automaticAmount.toFixed(2))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.item.lossToTAmount.toFixed(2))+" ")]),_vm._v(" "+_vm._s(props.item.customerCurrency || "")+" ")]):_c('span',{staticStyle:{"margin-top":"3px"}},[_vm._v(" "+_vm._s(( props.item.lossToTAmount + props.item.automaticAmount ).toFixed(2))+" "+_vm._s(props.item.customerCurrency || "")+" ")]),_vm._v("   "),_c('v-flex',{attrs:{"pt-2":""}},[(
                  _vm.editPopupLtt(
                    _vm.lossRadio,
                    props.item.lossTReceiptNumber,
                    props.item.lossToTAmount,
                    props.item.lossTstatus,
                    props.item.automaticAmount
                  )
                )?_c('LossToTrukkinPopup',{attrs:{"type":2,"editData":props.item,"custid":_vm.payload.customerId,"invoiceNumber":props.item.invoiceNumber,"lossAmount":props.item}}):_vm._e()],1),_c('v-flex',{attrs:{"pt-2":""}},[(
                  _vm.lossRadio &&
                  props.item.lossTstatus == 'Pending' &&
                  props.item.lossToTAmount
                )?_c('AcceptAmount',{attrs:{"id":props.item.lossTid,"invoiceLevel":props.item.isInvoiceLevelAssignment,"amount":props.item.lossToTAmount.toFixed(2),"currency":props.item.customerCurrency}}):_vm._e()],1),_c('v-flex',{attrs:{"pt-2":""}},[(
                  _vm.lossRadio &&
                  props.item.lossTstatus == 'Pending' &&
                  props.item.lossToTAmount
                )?_c('RejectAmountRecieved',{attrs:{"id":props.item.lossTid,"amount":props.item.lossToTAmount.toFixed(2),"invoiceLevel":props.item.isInvoiceLevelAssignment,"currency":props.item.customerCurrency}}):_vm._e()],1)],1),(props.item.lossTstatus)?_c('v-layout',{staticClass:"font_Bold",staticStyle:{"padding-left":"35px"}},[(props.item.lossTstatus == 'Approved')?_c('span',{staticClass:"green-text"},[_vm._v(" "+_vm._s(props.item.lossTstatus)+" ")]):_vm._e(),(props.item.lossTstatus == 'Pending')?_c('span',[(
                  props.item.automaticAmount > 0 &&
                  props.item.lossToTAmount == 0
                )?_c('span',{staticClass:"green-text"},[_vm._v(" Approved ")]):(props.item.lossToTAmount)?_c('span',{staticClass:"orange-text"},[_vm._v(" "+_vm._s(props.item.lossTstatus)+" ")]):_vm._e()]):_vm._e(),(props.item.lossTstatus == 'Rejected')?_c('span',{staticClass:"red-text"},[(props.item.automaticAmount > 0)?_c('span',{staticClass:"green-text"},[_vm._v(" Approved ")]):_c('span',{staticClass:"red-text"},[_vm._v(" "+_vm._s(props.item.lossTstatus)+" ")])]):_vm._e()]):_vm._e(),(props.item.lossTstatus == 'Approved')?_c('v-layout',{staticClass:"font_Bold",staticStyle:{"padding-left":"35px"}},[(props.item.lossTTEntryProof.length > 0)?_c('ProofOfDelivery',{attrs:{"deliveryProof":props.item.lossTTEntryProof}}):_vm._e()],1):_vm._e()],1)]}}])}),_c('pagination',{attrs:{"totalPages":_vm.totalPages,"pages":this.pages,"url":_vm.listApiUrl,"callback":_vm.callback,"componentKey":_vm.componentKey,"action":"customerList","payload":_vm.payload,"type":1},on:{"pageNo":_vm.page,"pagination-load":_vm.checkLoading}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }