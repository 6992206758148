<template>
  <div>
    <v-btn
      style="width: 30px; height: 30px"
      outline
      small
      fab
      @click="check($event)"
      :disabled="processing"
      v-if="!isCustomerReceiptInitiate"
      color="orange"
    >
      <v-icon>edit</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="35%" persistent>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
            style="
              background-color: #f5f5f5;
              padding-bottom: 5px;
              padding-top: 5px;
            "
          >
            <span class="headline">Price Type</span>
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="close">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-card-title>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>

          <v-layout
            row
            align-center
            style="padding: 20px 5px 0px 5px !important"
          >
            <v-flex xs7 class="title-layout-size">
              <v-radio-group
                class="mt-0 pt-0"
                v-model="priceType"
                row
                :mandatory="true"
                :rules="[rules.required]"
              >
                <div>
                  <div class>
                    <v-layout>
                      <v-radio
                        label="Inclusive"
                        value="Inclusive"
                        color="black"
                        style="margin-right: 100px !important"
                        class="col-md-6"
                      ></v-radio>
                      <v-radio
                        label="Exclusive"
                        value="Exclusive"
                        color="black"
                        class="col-md-6"
                      ></v-radio>
                    </v-layout>
                  </div>
                </div>
              </v-radio-group>
            </v-flex>
          </v-layout>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              :disabled="processing"
              flat
              @click="close()"
              >CLOSE</v-btn
            >
            <v-btn
              color="orange darken-1"
              :disabled="processing"
              flat
              @click="editPriceType()"
              >SUBMIT</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
      <v-snackbar
        :timeout="3000"
        bottom
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { StorageKeys } from "../../../constants/constants";
import ErrorBox from "@/components/Common/ErrorBox";
export default {
  components: {
    ErrorBox,
  },

  data() {
    return {
      priceType: "",
      customerStatus: "",
      valid: true,
      lazy: false,
      reason: "",
      processing: false,
      operationName: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
        amount: (v) =>
          /^\d*(\.\d+)?$/.test(v) || "Enter a valid amount. E.g 10 or 10.20",
        number: (v) => /^[0-9]*$/.test(v) || "Please enter valid amount",
      },

      error: null,
    };
  },
  props: {
    jobId: Number,
    chargesType: String,
    isCustomerReceiptInitiate: Boolean,
  },
  created() {},
  methods: {
    open() {
      this.dialog = true;
      this.priceType = this.$props.chargesType;
    },
    async check(e) {
      e.stopPropagation();
      this.processing = true;
      this.operationName = "job-price-type-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.processing = false;
        this.open();
      } else {
        return;
      }
    },
    close() {
      this.dialog = false;
      this.priceType = "";
      this.$refs.form.resetValidation();
    },
    getTime(date) {
      return moment(date).format("YYYY-MM-DD hh:mm A");
    },
    async editPriceType() {
      this.operationName = "job-price-type-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.processing = true;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let body = {
          jobId: this.$props.jobId.toString(),
          chargesType: this.priceType,
        };
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        this.axios
          .post(
            `${this.constants.apiUrl}/dmsAdmin/changeChargesType`,
            body,
            config
          )
          .then(
            (response) => {
              const { status, data } = response.data;
              this.processing = false;
              this.dialog = false;
              this.$emit("account-list-refresh");
            },
            (error) => {
              this.processing = false;
              this.error = error.response.data.message;
            }
          );
      } else {
        this.processing = false;
        return;
      }
    },
  },
};
</script>
<style scoped>
.messagebtn {
  margin: 0 auto;
}
.title-layout-size {
  padding: 0px 16px !important;
}
.title {
  font-size: 18px;
  font-weight: 500;
}
</style>
