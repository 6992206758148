<template>
  <v-card class="pt-0">
    <loading :active.sync="loading" />
    <v-tabs
      fixed-tabs
      v-if="!markTable"
      class="m-b-20 pt-0"
      background-color="darkorange"
      dark
      v-model="changeTab"
    >
      <v-tab :href="`#paymentDetail`" @click="filterByTab('paymentDetail')"
        >Job Payment Details
      </v-tab>
      <v-tab :href="`#advanceDetail`" @click="filterByTab('advanceDetail')"
        >Assignment Payment Details
      </v-tab>
      <v-tab
        v-if="workingCountry.includes('UAE') || workingCountry.includes('KSA')"
        :href="`#customerDetail`"
        @click="filterByTab('customerDetail')"
        >Customer Receipt
      </v-tab>
    </v-tabs>

    <v-card-title class="bg-clr p-t-0 mt-1">
      <v-layout align-center v-if="selectedCustomerId && !markTable">
        <v-layout justify-space-around wrap>
          <v-avatar tile color="#ffa500" class="total avatar" size="200">
            <span class="white--text status-count breakword">
              Total received amt.
              <span class="count"
                >{{ totalReceivedAmount.toFixed(2) }} {{ currency }}</span
              >
            </span>
          </v-avatar>

          <v-avatar tile color="#ffa500" class="total avatar" size="200">
            <span class="white--text status-count breakword">
              Total adjusted amt.
              <span class="count"
                >{{ totalAdjustedAmount.toFixed(2) }} {{ currency }}</span
              >
            </span>
          </v-avatar>
          <v-avatar tile color="#ffa500" class="total avatar" size="200">
            <span class="white--text status-count breakword">
              Current balance
              <span class="count"
                >{{ totalBalanceAmount.toFixed(2) }} {{ currency }}</span
              >
            </span>
          </v-avatar>

          <v-avatar tile color="#ffa500" class="total avatar" size="200">
            <span class="white--text status-count breakword">
              Pending SI balance
              <span class="count"
                >{{ totalInvoicePendingAmount.toFixed(2) }} {{ currency }}</span
              >
            </span>
          </v-avatar>
        </v-layout>
      </v-layout>
    </v-card-title>

    <v-card-text class="pt-0 nospace">
      <customerListingFilter
        v-if="!adjustTable && !lossTrukkin && !markTable"
        v-on:request-customerFilter="selectedCustomer"
        v-on:customer-reset-receipt="resetCustomer"
        v-on:customer-search-pay="invoiceNumber"
        v-on:invoice-search-pay="invoiceFilter"
        v-on:invoice-search-pay-type="invoiceSalesType"
        v-on:daterangenew-cust-pay="dateRangenew"
        v-on:adjust-button="adjust"
        v-on:loss-button="lossTrukkinMoney"
        v-on:marked-button="marked"
        :selectedCustomerWorkingCoun="selectCustomerUser"
      />
      <v-layout justify-end pt-2>
        <v-flex xs1>
          <v-btn
            color="orange"
            class="margin-l8"
            outline
            v-if="adjustTable || lossTrukkin"
            style="padding: 20px !important; margin-right: 10px !important"
            @click.native="cancelAdjust()"
          >
            cancel
          </v-btn>
        </v-flex>
        <LossToTrukkinPopup
          v-if="lossTrukkin"
          :custid="selectedCustomerId"
          :type="1"
          :invoiceNumber="lossInvoiceId"
          :invoiceLevel="assignmentLevel"
          :customerCurrency="lossCustomerCurrency"
          :lossAmount="lossoutAmount"
          v-on:upLossButton="lossOutstandingAmount"
          @loss-submit="refreshListLoss"
        />
      </v-layout>
      <AdjustListing
        v-if="adjustTable"
        :customerId="selectedCustomerId"
        v-on:entry-id="entryId"
        v-on:cust-id="adjustCustomerId"
        v-on:recieve-amount="recieveAmount"
      />
      <div style="width: 100%; display: inline-block">
        <span
          class="font-bold"
          v-if="adjustTable"
          style="text-align: right; float: right"
        >
          Total credit Selected : {{ recieveAmountAdjust || "0" }}</span
        >
      </div>

      <OpenMarkedListing
        v-if="markTable"
        v-on:back-button="cancelAdjust()"
        :customerId="selectedCustomerId"
      />

      <CustomerList
        v-if="!markTable"
        :customerFilter="selectedCustomerId"
        :reset="reset"
        :dateRangenew="date2"
        :adjust="adjustTable"
        :invoiceNumber="invoiceNumberSearch"
        :invoiceFilter="invoiceSearch"
        :invoiceSalesType="invoicesalesLevel"
        :lossTrukkin="lossTrukkin"
        :refresh="refreshList"
        v-on:adjust-amount="adjustAmount"
        v-on:wallet-amount="walletAmount"
        v-on:selectCustomerWorkingCountry="selectCustomerWorkingCountry"
        v-on:on-loading="startLoad"
        v-on:off-loading="stopLoad"
        v-on:Loss-id="lossId"
        v-on:invoice-no="invoiceId"
        v-on:si-level="siLevel"
        v-on:loss-currency="lossCurrency"
        v-on:outstanding-amount="lossOutstandingAmount"
      />
      <!-- <CustomerList v-if="adjustTable" :customerId="selectedCustomerId" /> -->
      <div style="width: 100%">
        <span
          class="font-bold"
          v-if="adjustTable"
          style="text-align: right; float: right"
        >
          Total Debit Selected : {{ sumAdjust || "0" }}</span
        >
      </div>

      <v-snackbar
        :timeout="3000"
        bottom
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import { accountCustomerPayment } from "@/constants/datatable-headers.js";
import moment from "moment";

import PaymentDone from "../Popups/PaymentDone.vue";

import ProofOfDelivery from "@/components/Pop-ups/ProofOfDelivery";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";

import Loading from "vue-loading-overlay";

import activityLogs from "../../Common/activityLogs.vue";
import AdjustListing from "@/components/Accounts/CustomerPayment/Listing/AdjustListing.vue";
import CustomerList from "@/components/Accounts/CustomerPayment/Listing/CustomerList.vue";
import OpenMarkedListing from "@/components/Accounts/CustomerPayment/Listing/OpenMarkedListing.vue";
import MarkedOffRefrenceList from "@/components/Accounts/CustomerPayment/Listing/MarkedOffRefrenceList.vue";

import RejectAmountRecieved from "@/components/Accounts/CustomerPayment/CustomerPopups/RejectAmountRecieved.vue";
import AcceptAmount from "@/components/Accounts/CustomerPayment/CustomerPopups/AcceptAmount.vue";

import LossToTrukkinPopup from "@/components/Accounts/CustomerPayment/CustomerPopups/LossToTrukkinPopup.vue";

import customerListingFilter from "@/components/Accounts/CustomerPayment/Filter/customerListingFilter.vue";

import ConfirmationDialog from "../../Common/ConfirmationDialog.vue";
import { adjustCustomerAmount } from "@/constants/api-urls.js";
import { StorageKeys, Banks } from "@/constants/constants";
import { EventBus } from "../../../event-bus.js";
import pagination from "@/components/Pagination/pagination";
let momentTz = require("moment-timezone");
export default {
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.path == "/accounts/customerPayment") {
        this.workingCountry = JSON.stringify(val);
        if (
          this.workingCountry.includes("UAE") ||
          this.workingCountry.includes("KSA")
        ) {
        } else {
          this.$router.push(`/accounts`);
        }

        this.loading = true;
      }
    });
    this.loading = true;
  },

  components: {
    pagination,
    activityLogs,
    RejectAmountRecieved,
    ConfirmationDialog,
    AdjustListing,
    CustomerList,
    LossToTrukkinPopup,
    OpenMarkedListing,
    MarkedOffRefrenceList,

    customerListingFilter,

    AcceptAmount,
    Loading,
  },

  data() {
    return {
      active: "0",
      lossTrukkin: false,
      sumAdjust: 0,
      openMarkedOff: false,
      refreshList: false,
      lossCustomerId: "",
      LossPopup: false,
      checkBoxInput: false,

      componentKey: 0,
      selectedCustomerId: "",
      entryIdAdjust: "",
      totalInvoiceAmount: 0,
      totalAdjustedAmount: 0,
      totalInvoicePendingAmount: 0,
      totalInvoiceReceivedAmount: 0,
      totalBalanceAmount: 0,
      totalReceivedAmount: 0,
      customerIdAdjust: "",
      recieveAmountAdjust: "",
      date2: null,
      adjustAmountData: [],
      walletInformation: {},

      id: null,

      pagination: {},
      TruckTypesList: [],
      loading: true,
      paginationURL: ``,
      waterMark: "Select a Range",
      dateRange2: null,
      adjustTable: false,
      markTable: false,

      dateRange: { startDate: "", endDate: "" },
      startDate: "",
      endDate: "",

      workingCountry: null,
      assignmentData: {},

      selectCountryDialog: false,

      error: null,
      JobTypes: [],

      totalPages: 0,
      items: [],
      changeTab: "customerDetail",
      headers: accountCustomerPayment,

      pages: 1,
      rules: {
        required: (value) => !!value || "This field is required.",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        phoneNumber: (v) =>
          /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
          "Please Enter Valid Mobile No.",
        number: (v) => /^[0-9]*$/.test(v) || "Please Enter Valid Price",
      },
      statusList: [],
      statusListInProgress: [],
      reset: false,

      invoiceNumberSearch: "",
      invoiceSearch: "",
      invoicesalesLevel: "",
      selectedCustomerId: "",
      lossInvoiceId: "",
      assignmentLevel: Boolean,
      lossCustomerCurrency: "",
      selectCustomerUser: "",
      dateRangeSearch: "",
      currency: "",
      statusListCompleted: [],
      lossoutAmount: "",
      subTruck: [],
      totalPages: 0,
      totalJob: 0,
    };
  },
  watch: {
    dateRange2(val) {
      if (val) {
        if (val[0] && val[1]) {
          this.startDate = moment(val[0]).format("YYYY-MM-DD");
          this.endDate = moment(val[1]).format("YYYY-MM-DD");
        }
        this.searchData(null, val);
      } else {
        this.startDate = "";
        this.endDate = "";

        this.searchData(null, val);
      }
    },
  },
  methods: {
    // Customer selection filter event

    startLoad() {
      this.loading = true;
    },
    stopLoad() {
      this.loading = false;
    },
    selectedCustomer(event) {
      this.selectedCustomerId = event;
      this.reset = false;
    },
    refreshListLoss(event) {
      this.refreshList = event;
    },
    invoiceId(event) {
      this.lossInvoiceId = event;
    },
    siLevel(event) {
      this.assignmentLevel = event;
    },
    lossCurrency(event) {
      this.lossCustomerCurrency = event;
    },
    lossId(event) {
      this.lossCustomerId = event;
    },
    lossOutstandingAmount(event) {
      console.log(event, "--losstooutstanding");
      this.lossoutAmount = event;
    },
    // Invoice Number serach  filter event

    invoiceNumber(event) {
      this.invoiceNumberSearch = event;
      this.reset = false;
    },
    lossTrukkinMoney(event) {
      this.lossTrukkin = event;

      this.reset = false;
    },
    invoiceFilter(event) {
      this.invoiceSearch = event;
      this.reset = false;
    },
    invoiceSalesType(event) {
      this.invoicesalesLevel = event;
      this.reset = false;
    },
    marked(event) {
      this.markTable = event;
      this.reset = false;
    },
    adjustAmount(event) {
      this.adjustAmountData = event;
      this.sumAdjust = this.adjustAmountData.reduce(function (acc, obj) {
        return acc + obj.adjustedAmount;
      }, 0);
    },
    selectCustomerWorkingCountry(event) {
      this.selectCustomerUser = event;
      console.log(event);
    },

    walletAmount(event) {
      this.walletInformation = event;
      this.currency = this.walletInformation.currency;

      this.totalInvoiceAmount = this.walletInformation.totalInvoiceAmount;
      this.totalBalanceAmount = this.walletInformation.totalBalanceAmount;
      this.totalInvoicePendingAmount =
        this.walletInformation.totalInvoicePendingAmount;
      this.totalInvoiceReceivedAmount =
        this.walletInformation.totalInvoiceReceivedAmount;
      this.totalReceivedAmount = this.walletInformation.totalReceivedAmount;
      this.totalAdjustedAmount = this.walletInformation.totalAdjustedAmount;
    },
    entryId(event) {
      this.entryIdAdjust = event;
    },
    adjustCustomerId(event) {
      this.customerIdAdjust = event;

      this.reset = false;
    },
    recieveAmount(event) {
      this.recieveAmountAdjust = event;
      this.reset = false;
    },
    refresh(event) {},

    adjust(event) {
      this.adjustTable = event;

      this.reset = false;
    },

    // date range filter select event
    dateRangenew(event) {
      this.date2 = event;
      this.reset = false;
    },

    resetCustomer() {
      this.reset = true;
      this.search = "";
      this.jobType = "";
      this.startDate = "";
      this.status = null;
      this.endDate = "";
      this.filter = "";
      this.invoiceNumberSearch = "";
      this.invoiceSearch = "";
      this.selectCustomerUser = "";
      this.invoicesalesLevel = "";

      this.date2 = null;
      this.selectedCustomerId = "";
      this.lossCustomerId = "";
      this.lossInvoiceId = "";
      this.assignmentLevel = "";
    },

    // async check(jobId) {
    //   this.operationName = "view-details-jobs";
    //   let y = await this.checkOpertaionPermission(this.operationName);
    //   if (y) {
    //     this.$router.push(`/jobs/${jobId}`);
    //   } else {
    //     return;
    //   }
    // },
    getText(arr = [], val) {
      let finalEl = null;
      finalEl = arr.find((el) => el.value == val);
      return finalEl ? finalEl.text : "NA";
    },

    adjust() {
      this.adjustTable = true;
      this.lossTrukkin = false;
    },
    refresh() {
      this.componentKey = this.componentKey + 1;
    },

    LossToTrukkin() {
      this.lossTrukkin = true;
    },

    page(event) {
      this.pages = event;
    },

    getTime4(date) {
      return moment(date).format("ll HH:mm");
    },

    filterByTab(value) {
      if (value == "paymentDetail") {
        this.$router.push(`/accounts`);
      } else if (value == "customerDetail") {
        this.$router.push(`/accounts/customerPayment`);
      } else {
        this.$router.push(`/accounts/advance`).catch(() => {});
      }
    },
    // submitAdjust() {
    //   if (!this.recieveAmountAdjust) {
    //     this.error = "Please select adjust amount from adjust Table";
    //     return;
    //   }
    //   if (!this.adjustAmountData.length && !this.adjustAmountData) {
    //     this.error =
    //       "Please Enter adjust amount coresponding sales receipnt job";
    //     return;
    //   }
    //   this.adjustAmountData,
    //     (this.sumAdjust = this.adjustAmountData.reduce(function (acc, obj) {
    //       return acc + obj.adjustedAmount;
    //     }, 0));
    //   if (this.sumAdjust > this.recieveAmountAdjust) {
    //     this.error = "Please fill less adjust amount  ";
    //     return;
    //   }

    //   let url = adjustCustomerAmount;
    //   delete this.axios.defaults.headers.common["token"];
    //   let token = localStorage.getItem(StorageKeys.SessionKey);
    //   let config = {
    //     headers: {
    //       authorization: `bearer ${token}`,
    //     },
    //   };

    //   let body = {
    //     customer_id: this.customerIdAdjust,
    //     entry_id: this.entryIdAdjust,
    //     adjustData: this.adjustAmountData,
    //   };
    //   this.axios.put(this.constants.apiUrl + url, body, config).then(
    //     (response) => {
    //       this.customerIdAdjust = [];
    //       this.adjustTable = false;
    //       this.selectedCustomerId = "";
    //       this.sumAdjust = 0;
    //     },
    //     (error) => {
    //       this.error = error.response.data.message;
    //     }
    //   );
    // },

    cancelAdjust() {
      this.adjustTable = false;
      this.reset = true;
      this.lossoutAmount = "";
      this.lossTrukkin = false;
      this.selectedCustomerId = "";
      this.lossCustomerId = "";
      this.search = "";
      this.markTable = false;
      this.$emit("customer-reset-receipt");
    },

    checkLoading(event) {
      if (event) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
  },
};
</script>

<style scss>
.pd-t-l {
  padding-left: 5px;
  padding-top: 7px;
}
div#ej2-datetimepicker_0_popup {
  left: 905.2px !important;
}
#accountDate .e-input-group-icon.e-range-icon.e-icons::after {
  content: none !important;
}

#accountDate span.e-input-group-icon.e-range-icon.e-icons {
  position: absolute;
  left: 0;
  right: 100px !important;
  width: 100%;
  text-align: right;
}
#accountDate .e-input-group-icon.e-range-icon::before,
*.e-control-wrapper .e-input-group-icon.e-range-icon::before {
  content: "\e245";
  font-family: "e-icons";
  float: right;
  position: absolute;
  right: 0;
}
#accountDate .e-daterangepicker {
  left: inherit !important;
  right: 120px;
}
.font_Bold {
  font-size: 13px;
  font-weight: 600;
  margin-top: 2px;
  font-family: "Open Sans bold", sans-serif;
}
.reset__btn {
  position: absolute;
  right: 0;
  top: -4px !important;
}
.reset2__btn {
  position: absolute;
  right: 5px;
  margin-right: 100px;
  top: -4px !important;
}
.total.avatar[data-v-6c1d3ab2] {
  min-width: 253px !important;
}
.e-daterangepicker.e-popup .e-calendar,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar {
  background-color: #fff;
  height: 100%;
}
#accountDate .e-clear-icon {
  display: none !important;
  opacity: 0;
}
.searchAccounts .v-label.v-label--is-disabled.theme--light {
  left: 10px !important;
}

.searchAccounts .theme--light.v-label {
  left: 10px !important;
}
.searchAccounts input {
  padding-left: 10px !important;
}
div#ej2-datetimepicker_0_popup {
  left: 827.2px !important;
}
/* .date_9 input#ej2-datetimepicker_0 {
  font-size: 16px;
} */
</style>

<style scoped>
.openMarked-btn-color {
  background-color: #042f71 !important;
}
.adjust-btn-color {
  background-color: #4089fa !important;
}

.Loss-btn-color {
  background-color: #e3471b !important;
}
.Recieve-btn-color {
  background-color: #539b56 !important;
}

.track-ass-btn {
  text-decoration: none;
  margin: 7px !important;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 6px 15px;
}

.track-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 2px 5px;
}
.total.avatar .count {
  background-color: darkorange;
  color: white !important;
  padding: 0 3px;
  font-size: 11px !important;
  border-radius: 5px;
  margin-left: 5px;
}
.margin-l8 {
  margin-left: 22px;
}
.total.avatar {
  min-width: 280px !important;
  height: 26px !important;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-radius: 0;
}
.total.avatar span {
  color: black !important;
}

.divider-style {
  margin-bottom: 24px;
  margin-top: 15px;
  background: lightgrey;
}
.gap {
  position: relative;
  right: 2px;

  z-index: auto;
}
.scrollbar {
  width: 5px;
}
.tooltip-op-comment {
  max-width: 30%;
  opacity: 1 !important;
}

.div ::-webkit-scrollbar {
  width: 1 px;
}
.muted {
  font-size: 13px;
}
.layout {
  overflow: auto;
}
.tra-ass-btn {
  text-decoration: none;
  margin: 7px !important;
  color: white;
  background-color: orange;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 6px 6px;
}
.orange-text {
  color: orange;
}
.status-count {
  font-size: 14px;

  font-weight: 800 !important;
}
.avatar {
  border-radius: 10px;
}
.total.avatar span {
  color: darkorange !important;
}
.total.avatar .count {
  background-color: darkorange;
  color: white !important;
  padding: 0 5px;
  font-size: 15px !important;
  border-radius: 5px;
  margin-left: 4px;
}
.total.avatar {
  min-width: 290px !important;
  height: 50px !important;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-radius: 0;
}
.red-text {
  color: red;
  animation: animate 1.5s linear infinite;
}
@keyframes animate {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 0;
  }
}
</style>
