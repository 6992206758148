<template>
  <div>
    <button outline class="tra-ass-btn" type="button" @click.stop="check()">
      <v-icon color="grey darken-1" flat prepend-icon class="customer-actions"
        >create
      </v-icon>
    </button>

    <v-dialog
      v-model="dialog"
      max-width="44%"
      persistent
      class="layout__spacing2"
    >
      <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
        height="100%"
        onSubmit="return false;"
        class="layout__spacing2"
      >
        <v-card>
          <v-card-title
            style="
              background-color: #f5f5f5;
              padding-bottom: 5px;
              padding-top: 5px;
            "
          >
            <span class="headline pl-2">Edit Amount From Customer</span>
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="close">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-card-title>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer class="spacerclassAssign">
            <v-layout class="span__padding" pl-3>
              <v-flex xs8 pl-2>
                <span>How did you receive the amount from the customer?</span>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs12 pl-4>
                <v-radio-group
                  class="mt-0 pt-0"
                  v-model="modeOfPayment"
                  row
                  :mandatory="true"
                  :rules="[rules.required]"
                >
                  <div>
                    <div class>
                      <v-layout>
                        <v-radio
                          label="Cash"
                          value="Cash"
                          color="black"
                          class="col-md-6"
                        ></v-radio>
                        <v-radio
                          label="Bank Account"
                          value="Bank Account"
                          color="black"
                          class="col-md-6"
                        ></v-radio>
                        <v-radio
                          label="Cheque"
                          value="Cheque"
                          color="black"
                          class="col-md-6"
                        ></v-radio>
                      </v-layout>
                    </div>
                  </div>
                </v-radio-group>
              </v-flex>
            </v-layout>

            <v-layout pl-3 style="">
              <v-flex md12 pl-2 class="">
                <v-layout>
                  <v-flex md4 class="custom-label-color">
                    <v-layout>
                      <v-flex md10 class="custom-label-color" pt-20 xs6>
                        <v-text-field
                          v-model="amount"
                          :value="amount"
                          label="Actual Amount Received"
                          placeholder="Enter  amount"
                          class="pt-0 currencyTitle custom-label-color"
                          maxlength="11"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex md8 pr-2>
                    <v-layout>
                      <v-flex xs6 pt-20 pr-1>
                        <v-menu
                          ref="FilterDateBool"
                          lazy
                          v-model="FilterDateBool"
                          class="date__icon"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          full-width
                          :nudge-right="40"
                          :return-value.sync="startDate"
                        >
                          <v-text-field
                            slot="activator"
                            :label="'Select Actual Date '"
                            placeholder="Select Actual Date"
                            v-model="startDate"
                            prepend-icon="event"
                            readonly
                            class="pt-0 currencyTitle custom-label-color"
                            :rules="[rules.noWhiteSpace, rules.required]"
                          ></v-text-field>
                          <v-date-picker
                            v-model="startDate"
                            :max="dateToday"
                            class="minHeight"
                            @change="$refs.FilterDateBool.save(startDate)"
                            no-title
                            scrollable
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs7 md-8 pl-5 pt-1 style="position: relative">
                        <label
                          :class="
                            actualModeError
                              ? 'light_red label__position muted caption'
                              : 'label__position  caption'
                          "
                          >Select Actual Time</label
                        >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          :class="
                            actualModeError
                              ? 'light_red_fill clock_account_pay'
                              : 'clock_account_pay'
                          "
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path
                            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                          />
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
                        </svg>
                        <vue-timepicker
                          class="fonttime4 custom-label-color"
                          :minute-interval="30"
                          v-model="pickupTime"
                          input-width="15em"
                          :label="'Select Actual Time'"
                          placeholder="Select Actual Time"
                          close-on-complete
                          :rules="[rules.noWhiteSpace, rules.required]"
                        ></vue-timepicker>
                        <span
                          v-if="actualModeError"
                          style="
                            color: #ff5252 !important;
                            width: 100%;
                            border: 0px;
                            margin: auto;
                            text-align: left;
                            font-size: 12px;
                            padding-top: 5px;
                            display: block;
                          "
                          >{{ errorMessage }}</span
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout pl-4>
              <v-layout
                v-if="
                  modeOfPayment === 'Bank Account' || modeOfPayment === 'Cheque'
                "
              >
                <v-flex md4 class="">
                  <v-layout>
                    <v-flex md10 class="" pt-20>
                      <v-text-field
                        v-model="remitterBankInstrumentNo"
                        label="Instrument Number"
                        :value="remitterBankInstrumentNo"
                        placeholder="Instrument Number"
                        class="pt-0 currencyTitle custom-label-color"
                        :rules="[rules.noWhiteSpace, rules.required]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex>
                  <v-layout>
                    <v-flex xs11 pt-20 pr-1>
                      <v-menu
                        ref="FilterDateBool2"
                        lazy
                        v-model="FilterDateBool2"
                        class="date__icon"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        full-width
                        :nudge-right="40"
                        :return-value.sync="InstrumentDate"
                      >
                        <v-text-field
                          slot="activator"
                          :label="'Select Insturment Date'"
                          placeholder="Select Insturment Date"
                          v-model="InstrumentDate"
                          prepend-icon="event"
                          readonly
                          class="pt-0 currencyTitle custom-label-color"
                          :rules="[rules.noWhiteSpace, rules.required]"
                        ></v-text-field>
                        <v-date-picker
                          v-model="InstrumentDate"
                          :max="dateToday"
                          class="minHeight"
                          @change="$refs.FilterDateBool2.save(InstrumentDate)"
                          no-title
                          scrollable
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                    <!-- <v-text-field
                        v-model="ibanNumber"
                        label="IBAN Number "
                        placeholder="Enter IBAN Number "
                        class="pt-0 currencyTitle custom-label-color"
                        :rules="[rules.noWhiteSpace, rules.required]"
                      ></v-text-field> -->
                  </v-layout></v-flex
                >
                <v-flex>
                  <v-layout>
                    <v-flex md10 class="" pt-20 pl-2>
                      <v-autocomplete
                        label="Select Instrument Type"
                        placeholder="Select Instrument Type"
                        :items="instrumentTypes"
                        class="pt-0 currencyTitle custom-label-color"
                        v-model="remitterBankInstrumentType"
                        item-text="text"
                        item-value="value"
                        :rules="[rules.noWhiteSpace, rules.required]"
                      />
                      <!-- <v-text-field
                      v-model="accountNumber"
                      label="Account Number"
                      placeholder="Enter Account Number"
                      class="pt-0 currencyTitle custom-label-color"
                      :rules="[rules.noWhiteSpace, rules.required]"
                    ></v-text-field> -->
                    </v-flex>
                  </v-layout></v-flex
                >
              </v-layout>
            </v-layout>
            <v-layout pl-4>
              <v-layout
                v-if="
                  modeOfPayment === 'Bank Account' || modeOfPayment === 'Cheque'
                "
              >
                <v-flex md4 class="">
                  <v-layout>
                    <v-flex md10 class="" pt-20>
                      <v-autocomplete
                        label="Select Bank Name"
                        placeholder="Select Bank Name"
                        :items="bankLegerCollection"
                        class="pt-0 currencyTitle custom-label-color"
                        v-model="bankName"
                        item-text="text"
                        item-value="value"
                        :rules="[rules.noWhiteSpace, rules.required]"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex md4 pr-2 v-if="modeOfPayment === 'Cheque'">
                  <v-layout>
                    <v-flex class="maxw214" pt-20>
                      <v-text-field
                        label="Cheque No."
                        v-model="checkNumber"
                        :value="checkNumber"
                        class="pt-0 currencyTitle custom-label-color"
                        placeholder="Enter Cheque No."
                        :rules="[rules.noWhiteSpace, rules.required]"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex md4 pr-2 v-if="modeOfPayment === 'Bank Account'">
                  <v-layout>
                    <v-flex class="maxw214" pt-20>
                      <v-text-field
                        v-model="remitterBankName"
                        label="Remitter Bank Name "
                        placeholder="Enter Remitter Bank Name "
                        class="pt-0 currencyTitle custom-label-color"
                        :rules="[rules.noWhiteSpace, rules.required]"
                        maxlength="80"
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-layout>
                  <!-- <v-flex md11 class="" pt-20>
                    <v-text-field
                      v-model="accountNumber"
                      label="Account Number"
                      :value="accountNumber"
                      placeholder="Enter Account Number"
                      class="pt-0 currencyTitle custom-label-color"
                      :rules="[rules.noWhiteSpace, rules.required]"
                    ></v-text-field>
                  </v-flex> -->
                </v-layout>
              </v-layout>
            </v-layout>

            <v-layout>
              <v-flex xs12>
                <v-layout> </v-layout>
                <v-layout pt-20 pl-2>
                  <v-flex md12>
                    <v-card-title class="title-layout-size"> </v-card-title>
                    <v-card-text class="title-layout-size">
                      <v-text-field
                        v-model="comment"
                        label="Comment"
                        class="pt-0 currencyTitle custom-label-color"
                        placeholder="Enter comment"
                        :value="comment"
                        :rules="[rules.noWhiteSpace, rules.required]"
                        maxlength="150"
                      ></v-text-field>
                    </v-card-text>
                  </v-flex>
                </v-layout>

                <v-layout>
                  <v-flex md12 class="payadvance" pt-2>
                    <v-flex
                      xs7
                      class="pt-3 pt-100 pl-4"
                      v-if="document.length < 5"
                    >
                      <div class="upload-btn-wrapper pl-1">
                        <button v-if="document.length < 5" class="btn">
                          <v-icon small color="orange">upload</v-icon> Upload
                          Proofs
                        </button>
                        <input
                          ref="fileUpload"
                          id="fileUpload"
                          type="file"
                          multiple
                          accept=".pdf, .jpeg, .jpg, .png"
                          @change="uploadDocument"
                        />
                      </div> </v-flex
                  ></v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs6>
                <v-layout v-if="modeOfPayment === 'Cash'">
                  <v-flex md11 pt-20 pl-2 class="payadvance">
                    <v-text-field
                      label="Collected By "
                      v-model="cashHandover"
                      :value="cashHandover"
                      class="pt-0 currencyTitle custom-label-color"
                      placeholder="Enter Collected by"
                      :rules="[rules.noWhiteSpace, rules.required]"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <!-- <v-layout v-if="modeOfPayment === 'Cheque'">
                  <v-flex md11 pt-20 pl-2 class="payadvance">
                    <v-text-field
                      label="Cheque No."
                      v-model="checkNumber"
                      :value="checkNumber"
                      class="pt-0 currencyTitle custom-label-color"
                      placeholder="Enter Cheque No."
                      :rules="[rules.noWhiteSpace, rules.required]"
                    ></v-text-field>
                  </v-flex>
                </v-layout> -->
                <v-layout>
                  <v-flex md12 class="payadvance">
                    <ul style="padding-top: 10px !important">
                      <li
                        style="position: relative"
                        :key="i"
                        v-for="(doc, i) in document"
                        class="list"
                        ref="documentList"
                      >
                        <button
                          type="button"
                          v-show="document"
                          @click="removeImage(i)"
                        >
                          <span class="close imageClose">&times;</span>
                        </button>
                        <a
                          :href="doc"
                          class="document-link"
                          v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                          target="_blank"
                        >
                          <img src="../../../../assets/pfd.png" />
                        </a>
                        <a
                          :href="doc"
                          class="document-link"
                          target="_blank"
                          v-else
                        >
                          <img
                            src="../../../../assets/docimg.webp"
                            height="72"
                            width="75"
                          />
                        </a>
                      </li>
                    </ul>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-layout pt-2>
              <span class="headline pl-4 mt-4"
                >Adjust Amount Against Sales Invoices</span
              >
            </v-layout>

            <v-layout pt-1>
              <span class="pl-4 mt-1">Sales Invoice Types</span>
            </v-layout>

            <v-layout>
              <v-flex xs12 pl-4>
                <v-radio-group
                  class="mt-0 pt-0"
                  v-model="modeOfAdjust"
                  row
                  :mandatory="true"
                  :disabled="$props.receiveCustDetails.adjusts.length"
                  @change="fetchInvoice2(workingCountry)"
                  :rules="[rules.required]"
                >
                  <div>
                    <div class>
                      <v-layout>
                        <v-radio
                          label="Job Level"
                          value="JOB"
                          color="black"
                          class="col-md-6"
                        ></v-radio>
                        <v-radio
                          label="Assignment Level"
                          value="ASSIGNMENT"
                          color="black"
                          class="col-md-6"
                        ></v-radio>
                      </v-layout>
                    </div>
                  </div>
                </v-radio-group>
              </v-flex>
            </v-layout>

            <v-layout :key="i" v-for="(checklist, i) in shipmentDocument">
              <v-flex xs4 class="pt-2" pl-4 style="word-break: break-all">
                <v-autocomplete
                  label="Choose Invoice Number"
                  placeholder="Choose Invoice Number"
                  :items="customers"
                  item-text="text"
                  item-value="text"
                  class="custom-label-color"
                  :value="checklist.invoiceNumber"
                  v-model="checklist.invoiceNumber"
                  :search-input.sync="checklist.search"
                  :disabled="checklist._id"
                  @change="setCustomerValues($event, i)"
                  @keyup.enter="searchAnything($event, i, checklist.search)"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs4 class="pt-2" pl-4 style="word-break: break-all">
                <v-text-field
                  v-model="checklist.adjustedAmount"
                  :value="checklist.adjustedAmount"
                  label="Enter Amount To Be Adjusted"
                  type="number"
                  class="custom-label-color"
                >
                </v-text-field>
              </v-flex>

              <v-flex v-if="shipmentDocument.length != 1" xs2 class="pt-3 pl-4">
                <div class="upload-btn-wrapper">
                  <button
                    style="border: 2px solid red; color: red"
                    @click="
                      removeRow(
                        i,
                        shipmentDocument[i]._id,
                        shipmentDocument[i].invoiceNumber
                      )
                    "
                    type="button"
                    class="btn"
                  >
                    Remove
                  </button>
                </div>
              </v-flex>
              <v-flex v-if="shipmentDocument.length == 1" xs2 class="pt-3 pl-4">
                <div class="upload-btn-wrapper">
                  <button
                    style="border: 2px solid red !important; color: red"
                    @click="
                      clearData(
                        i,
                        shipmentDocument[i]._id,
                        shipmentDocument[i].invoiceNumber
                      )
                    "
                    type="button"
                    class="btn"
                  >
                    Remove
                  </button>
                </div>
              </v-flex>
            </v-layout>

            <v-layout pl-4>
              <div
                v-if="this.shipmentDocument.length <= 10"
                class="upload-btn-wrapper"
              >
                <button
                  style="border: 2px solid green !important; color: green"
                  @click="addMore"
                  class="btn"
                  type="button"
                >
                  + Add More
                </button>
              </div>
            </v-layout>

            <v-spacer></v-spacer>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                :disabled="processing"
                flat
                @click="close()"
                >Cancel</v-btn
              >
              <v-btn
                color="white darken-1"
                style="background: orange !important"
                :disabled="processing"
                flat
                @click="receieveAmount()"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-spacer>
        </v-card>
      </v-form>
      <v-dialog v-model="confirmationDialog" max-width="23%" persistent>
        <v-card>
          <v-spacer class="spacerclass">
            <v-card-text style="padding-top: 0px !important">
              <v-layout class="pt-4" style="text-align: center">
                <v-flex pr-2 pb-3>
                  <v-icon x-large color="orange">info</v-icon></v-flex
                >
              </v-layout>
              <v-layout>
                <v-flex pb-3 style="text-align: center">
                  {{ confirmationData }}
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex style="text-align: center">
                  <v-btn
                    color="white darken-1"
                    style="
                      background: orange !important;
                      min-width: 66px !important;
                    "
                    flat
                    @click.prevent="confirm()"
                    >OK</v-btn
                  ></v-flex
                >
              </v-layout>
            </v-card-text>
          </v-spacer>
        </v-card>

        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="x.error"
          class="white--text"
          v-if="error"
          >{{ error }}</v-snackbar
        >
      </v-dialog>
      <v-snackbar
        :timeout="3000"
        bottom
        color="red darken-2"
        v-model="x.error"
        class="white--text"
        v-if="x.error"
        >{{ x.error }}</v-snackbar
      >
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { StorageKeys, Banks } from "../../../../constants/constants";
import { uploadImage } from "../../../../constants/api-urls";
import { recieveAdjust, recieveBankLedger } from "@/constants/api-urls.js";

import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
export default {
  components: {
    VueTimepicker,
  },

  data() {
    return {
      dateToday: moment(new Date()).format("YYYY-MM-DD"),
      operationName: "",
      customers: [],
      remitterBankInstrumentType: "",
      remitterBankInstrumentNo: "",
      banks: [],
      confirmationDialog: false,
      options: {
        color: "#ffc04c",
        width: 500,
        zIndex: 200,
      },
      bankName: "",

      activity: [],
      pickupTime: {
        HH: "",
        mm: "",
      },
      deleteData: [],
      searchcustomers: [],
      checkNumber: "",
      confirmationData: "",
      startDate: "",
      InstrumentDate: "",
      search: null,
      cashHandover: "",
      ibanNumber: "",
      FilterDateBool: false,
      FilterDateBool2: false,

      modeOfPayment: "Cash",
      modeOfAdjust: "",
      customerStatus: "",
      accountHolderName: "",
      comment: "",
      accountNumber: "",
      remitterBankName: "",
      swiftNumber: "",
      ifscCode: "",
      amount: "",

      valid: true,
      lazy: false,
      document: [],
      errorMessage: "This field is required.",
      reason: "",
      actualModeError: false,
      processing: false,
      dialog: false,
      disStatus: false,
      loading: false,
      advancePayment: null,
      shipmentDocument: [
        {
          i: 0,
          invoiceNumber: null,
          adjustedAmount: 0,
          search: null,
        },
      ],
      receiveData: [
        {
          i: 0,
          invoiceNumber: null,
          adjustedAmount: 0,
        },
      ],

      document: [],
      bankLegerCollection: [],
      instrumentTypes: [],
      sumAdjust: 0,
      documentType: [],
      tempDeleteData: Object,
      error: null,
      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "Enter valid value.",
        amount: (v) =>
          /^\d*(\.\d+)?$/.test(v) || "Enter a valid amount. E.g 10 or 10.20",
        number: (v) => /^[0-9]*$/.test(v) || "Please enter valid amount",
      },
      x: {
        error: null,
      },
    };
  },
  watch: {
    pickupTime(val) {
      //  this.actualModeError = false;

      if (val.split(":")[0] || val.split(":")[1]) {
        this.actualModeError = false;
      } else {
        this.actualModeError = true;
      }
    },
  },
  props: {
    receiveCustDetails: Object,
    assId: Number,
    totalAmount: Number,
    totalAdvanceAmount: Number,
    driverCreditDuration: Number,
    selectedCustomerWorkingCoun: String,
    advance: Number,
    currency: String,
    invoiceCity: String,
    invoiceCompany: String,
    isCancel: Boolean,
    viewOnly: Boolean,
    customerId: String,
    status: Number,
    advancedialog: Boolean,
    type: String,
  },
  created() {
    this.customerStatus = localStorage.getItem("cust-status");
    this.totalPayment = this.$props.totalAmount;

    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;
    }

    // this.getActivityLog();
  },
  methods: {
    //remove Image via close icon
    removeImage(key) {
      this.document.splice(key, 1);
    },

    searchAnything(val, index, s) {
      this.searchcustomers = [];
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.t = config.headers.authorization;
      let body = {
        workingCountry: this.workingCountry,
        invoiceNumber: s,
        invoiceLevel: this.modeOfAdjust,
        customerId: this.$props.customerId,
      };
      this.axios
        .post(
          `${this.constants.apiUrl}/customerPayment/listInvoice`,
          body,
          config
        )
        .then((response) => {
          const { data = null } = response.data;

          data.forEach((element) => {
            this.searchcustomers.push({
              text: element.invoiceNumber,
              value: element.outStandingAmount,
            });
          });
        });
    },

    addMore() {
      let el = this.shipmentDocument.length;

      let checklist = {
        i: el - 1 + 1,
        adjustedAmount: 0,
        invoiceNumber: null,
        search: null,
      };
      this.shipmentDocument.push(checklist);
    },

    removeRow(i, _id, invoiceNumber) {
      if (_id) {
        this.tempDeleteData[invoiceNumber] = _id;
        // this.deleteData.push(_id);
      }

      if (this.shipmentDocument.length == 1) {
        return;
      } else {
        let el = this.shipmentDocument;
        el.splice(i, 1);
        this.shipmentDocument = el;
        this.shipmentDocument.map((e, index) => (e.i = index));
      }
    },

    uploadDocument(e) {
      this.x.error = null;
      var files = e.target.files || e.dataTransfer.files;
      let x = [...files];
      setTimeout(() => {
        this.$refs.fileUpload.value = "";
      }, 6000);
      if (!files.length) return;

      Array.from(x).forEach((y, i) => {
        this.createImage(files[i]);
      });
    },
    //createImage file check size of image/Document
    createImage(file) {
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.x.error = "Please upload proof with size less than 5MB!";

        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        this.x.error =
          "Please upload proof of file type png , jpg ,jpeg or pdf!";
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.upload(file);
      }
    },

    //upload function for upload document
    upload(file) {
      this.processing = true;
      const formData = new FormData();
      formData.append("image", file);
      this.axios({
        url: `${this.constants.apiUrl}${uploadImage}`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          this.processing = false;
          const { statusCode } = response.data;
          if (statusCode === 200) {
            if (this.document.length < 5) {
              this.document.push(response.data.data.original);
            } else {
              this.x.error = "Maximum 5 proofs can be uploaded!";
              return false;
            }
          } else {
            this.x.error = "Failed to upload image";
          }
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to upload image";
          this.processing = false;
        }
      );
    },
    getBankNdInstrument() {
      this.loading = true;
      let url = recieveBankLedger;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let country = this.$props.selectedCustomerWorkingCoun;
      this.axios
        .get(this.constants.apiUrl + url + "?country=" + country, config)
        .then(
          (res) => {
            this.bankLegerCollection = res.data.data.bankLegerCollection;
            this.instrumentTypes = res.data.data.instrumentTypes;
            console.log(res.data.data.bankLegerCollection);
          },
          (error) => {
            this.x.error = "Failed to Fetch Admin";
            this.loading = false;
          }
        );
    },

    //  fill Data  In Popup
    check() {
      this.getBankNdInstrument();
      this.dialog = true;
      console.log(this.$props.receiveCustDetails);
      //   this.amount = this.$props.receiveCustDetails.amount;
      if (this.$props.receiveCustDetails["paymentMode"] == "Cash") {
        this.amount = this.$props.receiveCustDetails.amount;
        this.startDate = moment(
          this.$props.receiveCustDetails.actualReceivedDate
        ).format("YYYY-MM-DD");
        this.pickupTime = this.$props.receiveCustDetails.actualReceivedTime;
        this.comment = this.$props.receiveCustDetails.depositComments;
        this.modeOfPayment = this.$props.receiveCustDetails.paymentMode;

        // this.document.push(this.$props.receiveCustDetails.paymentProof);
        if (this.$props.receiveCustDetails.cashReceivedBy) {
          this.cashHandover = this.$props.receiveCustDetails.cashReceivedBy;
        }
      } else if (
        this.$props.receiveCustDetails["paymentMode"] == "Bank Account"
      ) {
        this.remitterBankInstrumentNo =
          this.$props.receiveCustDetails.remitterBankInstrumentNo;
        this.remitterBankInstrumentType =
          this.$props.receiveCustDetails.remitterBankInstrumentType;
        this.InstrumentDate = moment(
          this.$props.receiveCustDetails.remitterBankInstrumentDate
        ).format("YYYY-MM-DD");
        this.remitterBankName = this.$props.receiveCustDetails.remitterBankName;
        this.amount = this.$props.receiveCustDetails.amount;
        this.startDate = moment(
          this.$props.receiveCustDetails.actualReceivedDate
        ).format("YYYY-MM-DD");
        this.pickupTime = this.$props.receiveCustDetails.actualReceivedTime;
        this.modeOfPayment = this.$props.receiveCustDetails.paymentMode;
        this.bankName = this.$props.receiveCustDetails.bankName;
        this.comment = this.$props.receiveCustDetails.depositComments;
        // this.accountHolderName =
        //   this.$props.receiveCustDetails.accountHolderName;
        // this.accountNumber = this.$props.receiveCustDetails.accountNumber;
        // this.swiftNumber = this.$props.receiveCustDetails.swiftNumber;
        // this.ibanNumber = this.$props.receiveCustDetails.ibanNumber;
      } else {
        this.bankName = this.$props.receiveCustDetails.bankName;
        this.remitterBankInstrumentNo =
          this.$props.receiveCustDetails.remitterBankInstrumentNo;
        this.remitterBankInstrumentType =
          this.$props.receiveCustDetails.remitterBankInstrumentType;
        this.InstrumentDate = moment(
          this.$props.receiveCustDetails.remitterBankInstrumentDate
        ).format("YYYY-MM-DD");
        this.amount = this.$props.receiveCustDetails.amount;
        this.startDate = moment(
          this.$props.receiveCustDetails.actualReceivedDate
        ).format("YYYY-MM-DD");
        this.pickupTime = this.$props.receiveCustDetails.actualReceivedTime;
        this.comment = this.$props.receiveCustDetails.depositComments;
        this.modeOfPayment = this.$props.receiveCustDetails.paymentMode;

        if (this.$props.receiveCustDetails.chequeNumber) {
          this.checkNumber = this.$props.receiveCustDetails.chequeNumber;
        }
      }
      this.document = this.$props.receiveCustDetails.paymentProof;
      this.modeOfAdjust = this.$props.receiveCustDetails
        .isInvoiceLevelAssignment
        ? "ASSIGNMENT"
        : "JOB";

      if (this.$props.receiveCustDetails.adjusts.length) {
        let invoiceList = [];

        invoiceList = this.$props.receiveCustDetails.adjusts.map(
          (it, index) => {
            return {
              i: index,
              invoiceNumber: it.invoiceNumber,
              adjustedAmount: Number(it.adjustedAmount),
              _id: it._id,
            };
          }
        );

        this.shipmentDocument = [];
        this.shipmentDocument.push(...invoiceList);
      }

      this.fetchInvoice(this.workingCountry);
    },

    clearData(i, _id, invoiceNumber) {
      if (_id) {
        this.tempDeleteData[invoiceNumber] = _id;
        // this.deleteData.push(_id);
      }
      this.shipmentDocument = [
        {
          i: 0,
          invoiceNumber: null,
          adjustedAmount: 0,
          search: null,
        },
      ];
    },

    //close popup
    close() {
      this.actualModeError = false;
      this.dialog = false;
      this.advancePayment = null;
      this.modeOfPayment = "Cash";
      this.modeOfAdjust = "";
      this.accountHolderName = "";
      this.accountNumber = "";
      this.remitterBankName = "";
      this.swiftNumber = "";
      this.ibanNumber = "";
      this.comment = "";
      this.ifscCode = "";
      this.bankName = "";
      this.remitterBankInstrumentType = "";
      this.remitterBankInstrumentNo = "";
      this.deleteData = [];
      this.tempDeleteData = {};
      this.shipmentDocument = [
        {
          i: 0,
          invoiceNumber: null,
          adjustedAmount: 0,
          search: null,
        },
      ];
      // this.$refs.form.resetValidation();
    },

    //TIME CONVERTER
    getTime(date) {
      return moment(date).format("YYYY-MM-DD hh:mm A");
    },

    fetchInvoice(val) {
      // this.$props.receiveCustDetails.isInvoiceLevelAssignment =
      //   this.modeOfAdjust;
      this.customers = [];
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.t = config.headers.authorization;
      let body = {
        workingCountry: val,
        edit: true,
        invoiceLevel: this.$props.receiveCustDetails.isInvoiceLevelAssignment
          ? "ASSIGNMENT"
          : "JOB",
        customerId: this.$props.receiveCustDetails.customer_id,
      };
      this.axios
        .post(
          `${this.constants.apiUrl}/customerPayment/listInvoice`,
          body,
          config
        )
        .then((response) => {
          const { data = null } = response.data;

          // for (var i = 0; i < data.length; i++) {
          //   data[i].fullName = data[i].name + "(" + data[i].companyName + ")";
          // }

          data.forEach((element) => {
            this.customers.push({
              text: element.invoiceNumber,
              value: element.outStandingAmount,
              losttAmount: element.lossToTrukkin,
            });
          });
          this.shipmentDocument.forEach((e) => {
            this.customers.filter((p) => {
              if (e.invoiceNumber === p.text) {
                p.value = p.value + e.adjustedAmount;
                return;
              }
            });
          });
        });
    },
    fetchInvoice2(val) {
      this.customers = [];
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.t = config.headers.authorization;
      let body = {
        workingCountry: val,
        edit: true,
        invoiceLevel: this.modeOfAdjust,
        customerId: this.$props.receiveCustDetails.customer_id,
      };
      this.axios
        .post(
          `${this.constants.apiUrl}/customerPayment/listInvoice`,
          body,
          config
        )
        .then((response) => {
          const { data = null } = response.data;

          data.forEach((element) => {
            this.customers.push({
              text: element.invoiceNumber,
              value: element.outStandingAmount,
              losttAmount: element.lossToTrukkin,
            });
          });

          this.shipmentDocument.forEach((e) => {
            this.customers.filter((p) => {
              if (e.invoiceNumber === p.text) {
                p.value = (p.value + e.adjustedAmount).toFixed(2);
                return;
              }
            });
          });
        });
    },

    confirm() {
      this.confirmationDialog = false;
    },

    setCustomerValues(val, index) {
      console.log(
        this.shipmentDocument.filter((a) => {
          if (a.invoiceNumber == val) {
            if (a.i !== index) {
              this.x.error = `Please remove  duplicate invoice Number ${a.invoiceNumber}`;
              return;
            }
          }
        })
      );
      this.shipmentDocument.filter((a) => {
        if (a.invoiceNumber == val) {
          if (a.i !== index) {
            this.x.error = `Please remove  duplicate invoice Number ${a.invoiceNumber}`;
            return;
          }
        }
      });

      var data = this.customers.filter((v) => {
        if (v.text === val) return v;
      });

      this.shipmentDocument.forEach((a) => {
        if (a.i == index) {
          a.adjustedAmount = data[0].value;
          if (this.tempDeleteData[a.invoiceNumber]) {
            a._id = this.tempDeleteData[a.invoiceNumber];
            delete this.tempDeleteData[a.invoiceNumber];
          }
        }
      });
    },

    //SUBMIT AFTER FILL ALL FORM

    async receieveAmount() {
      this.processing = true;

      this.sumAdjust = this.shipmentDocument.reduce(function (acc, obj) {
        return Number(acc) + Number(obj.adjustedAmount);
      }, 0);
      if (this.sumAdjust) {
        if (!this.shipmentDocument.every((e) => e.invoiceNumber)) {
          this.processing = false;
          this.x.error =
            "Please  fill field of adjusted outstanding amount otherwise remove from listing";
          return;
        }
      }

      // this.checkTime();

      if (this.$refs.form.validate()) {
        if (this.sumAdjust > this.amount) {
          this.processing = false;
          this.x.error =
            "Adjusted amount is greater than the received amount for this entry ";
          return;
        }
        if (this.actualModeError == true) {
          this.processing = false;
          this.x.error = "Please fill  all required field";
          return;
        }

        if (this.pickupTime) {
          let currentDate = moment(new Date()).format("YYYY-MM-DD, HH:mm");
          let selectedDateTime =
            this.startDate +
            "," +
            parseInt(this.pickupTime.split(":")[0]) +
            ":" +
            parseInt(this.pickupTime.split(":")[1]);

          if (
            new Date(currentDate).getTime() <
            new Date(selectedDateTime).getTime()
          ) {
            this.loading = false;
            this.processing = false;
            this.x.error =
              "Please select the past time and date before moving ahead!";
            return false;
          }
        }
        if (!this.document.length) {
          this.x.error = "Please upload proof of physical document";
          this.processing = false;

          return false;
        }
        this.shipmentDocument.forEach((e) => {
          this.customers.filter((p) => {
            if (e.invoiceNumber === p.text) {
              console.log(p.value);
              console.log(e.adjustedAmount);
              console.log(p.losttAmount);
              console.log(p.value - e.adjustedAmount + p.losttAmount);
              console.log(p.value - e.adjustedAmount);

              if (
                (p.value - e.adjustedAmount + p.losttAmount).toFixed(2) < 1 &&
                (p.value - e.adjustedAmount + p.losttAmount).toFixed(2) > 0 &&
                (p.value - e.adjustedAmount).toFixed(2) > 0 &&
                (p.value - e.adjustedAmount).toFixed(2) < 1
              ) {
                this.loading = false;
                this.confirmationData = ` Remaining amount of ${(
                  p.value -
                  e.adjustedAmount +
                  p.losttAmount
                ).toFixed(2)} for  ${
                  e.invoiceNumber
                } will be marked automatically as Loss To Trukkin.`;
                this.confirmationDialog = true;
              }
            }
          });
        });

        this.processing = true;
        let url = recieveAdjust;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          amount: parseFloat(this.amount),
          actualReceivedDate: this.startDate,
          actualReceivedTime: this.pickupTime.toString(),
          paymentMode: this.modeOfPayment,
          invoiceLevel: this.modeOfAdjust,
          paymentProof: this.document,
          customer_id: this.$props.receiveCustDetails.customer_id,
          depositComments: this.comment,
          entry_id: this.$props.receiveCustDetails._id,
        };

        if (this.shipmentDocument[0].invoiceNumber) {
          let adjust = [];

          adjust = this.shipmentDocument.map(
            ({ invoiceNumber, adjustedAmount, _id }) => {
              return {
                invoiceNumber,
                adjustedAmount: Number(adjustedAmount),
                _id,
              };
            }
          );
          body.adjustData = adjust;
        }
        if (Object.keys(this.tempDeleteData).length) {
          for (let key in this.tempDeleteData) {
            this.deleteData.push(this.tempDeleteData[key]);
          }
          body.deleteData = this.deleteData;
        }
        if (this.cashHandover) {
          body.cashReceivedBy = this.cashHandover;
        }
        if (this.bankName) {
          body.bankName = this.bankName;
        }
        if (this.remitterBankInstrumentType) {
          body.remitterBankInstrumentType = this.remitterBankInstrumentType;
        }
        if (this.remitterBankInstrumentNo) {
          body.remitterBankInstrumentNo = this.remitterBankInstrumentNo;
        }
        if (this.remitterBankName) {
          body.remitterBankName = this.remitterBankName;
        }
        if (this.InstrumentDate) {
          body.remitterBankInstrumentDate = this.InstrumentDate;
        }
        if (this.checkNumber) {
          body.chequeNumber = this.checkNumber;
        }

        this.axios.put(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.x.success = true;
            this.dialog = false;
            this.processing = false;
            this.deleteData = [];
            this.tempDeleteData = {};
            this.$refs.form.resetValidation();
            (this.shipmentDocument = [
              {
                i: 0,
                invoiceNumber: null,
                adjustedAmount: 0,
                search: null,
              },
            ]),
              (this.cashHandover = "");
            this.startDate = null;
            this.InstrumentDate = null;
            this.amount = "";
            // this.pickupTime = "";
            this.modeOfPayment = "";
            this.modeOfAdjust = "";
            this.accountHolderName = "";
            this.accountNumber = "";
            this.swiftNumber = "";
            this.comment = "";
            this.ifscCode = "";
            this.ibanNumber = "";
            this.bankName = "";
            this.remitterBankInstrumentType = "";
            this.remitterBankInstrumentNo = "";
            this.document = [];

            this.advancePayment = null;

            this.$emit("generaterReceievePayEdit");
          },
          (error) => {
            this.processing = false;
            this.x.error = error.response.data.message;
          }
        );
      } else {
        this.processing = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.layout__spacing2 .v-input__slot {
  min-height: auto !important;
}
.layout__spacing2 .v-text-field__slot input {
  margin-top: 0px !important;
}
.custom-label-color label.v-label.v-label--active.theme--light {
  top: 8px !important;
  color: #000000;
}
</style>
<style scoped>
.spacerclassAssign {
  overflow-y: scroll !important;
  max-height: calc(104vh - 176px) !important;
}
.tra-ass-btn {
  text-decoration: none;
  margin: 7px !important;
  color: white;

  padding: 6px 6px;
}
.heading__span {
  font-size: 20px !important;
  line-height: 32px !important;
  font-weight: bold;
}
.span__padding {
  padding: 10px 10px 10px 16px;
}
.dashboard-style {
  color: grey;
  font-size: 16px;
  font-weight: 700;
}
.fonttime4 {
  font-size: 11px !important;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper a {
  margin-left: 10px;
}

.fonttime4 .dropdown.drop-down {
  top: -14%;
}
.upload-btn-wrapper .btn,
.upload-btn-wrapper .view-btn {
  border: 2px solid darkorange;
  color: darkorange;
  background-color: white;
  padding: 8px 8px;
  cursor: pointer !important;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
}
.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.vue__time-picker input.display-time {
  border-radius: 0px !important;
  border-bottom: 1px solid #d2d2d2 !important;
  border: none;
  padding: 0px !important;
}
::v-deep .fonttime4 input::placeholder {
  font-size: 12px !important;
  color: #b5b5b5;
  font-family: "Roboto", sans-serif;
  font-weight: 200;
}
.messagebtn {
  margin: 0 auto;
}
.label__position {
  position: relative;
  top: 3px;
}
.title-layout-size {
  padding: 0px 16px !important;
}

.list {
  display: inline-block;
  position: relative;
}
.maxw214 {
  max-width: 214px;
}
.currencyTitle {
  margin-top: -2px !important;
}
label.v-label.v-label--active.theme--light {
  top: 8px !important;
  color: #000000;
}
::v-deep .fonttime4 .controls .clear-btn {
  display: none !important;
  opacity: 0;
}
</style>
