<template>
  <v-radio-group v-model="selectedValue" class="mt--10">
    <v-card-text class="pt-0 nospace">
      <v-data-table
        id="jobsTable"
        class="pt-0 nospace"
        hide-actions
        :headers="headers"
        :disable-initial-sort="true"
        :pagination.sync="pagination"
        :items="items"
      >
        <template slot="items" slot-scope="props">
          <td>
            <v-layout row>
              <span style="width: 22px !important">
                <v-flex
                  v-if="
                    radioButtonCheck(
                      props.item.outStandingAmount,
                      props.item.lossTReceiptNumber,
                      props.item.receivedAmount,
                      props.item.lossToTAmount
                    )
                  "
                >
                  <v-tooltip
                    top
                    class="milestone pt-1"
                    content-class="tooltip-op"
                    color="orange"
                    >&nbsp;
                    <template slot="activator">
                      <v-radio
                        v-if="lossRadio"
                        :disabled="
                          radioButtonCheck(
                            props.item.outStandingAmount,
                            props.item.lossTReceiptNumber,
                            props.item.receivedAmount,
                            props.item.lossToTAmount
                          )
                        "
                        :value="props.item.invoiceNumber"
                        @change="
                          radioSelected(
                            props.item.customerId,
                            props.item.invoiceNumber,
                            props.item.outStandingAmount,
                            props.item.customerCurrency,
                            props.item.isInvoiceLevelAssignment
                          )
                        "
                      ></v-radio
                    ></template>
                    <span class="tooltip" v-if="props.item.receivedAmount == 0">
                      There is no amount adjusted against this invoice yet.
                      Please adjust some amount.
                    </span>

                    <span
                      class="tooltip"
                      v-else-if="props.item.outStandingAmount == 0"
                    >
                      There is no outstanding amount against this invoice yet.
                    </span>
                    <span class="tooltip" v-else>
                      You can only edit the loss to Trukkin amount now.
                    </span>
                  </v-tooltip>
                </v-flex>

                <v-flex v-else>
                  <v-radio
                    v-if="lossRadio"
                    class="iconred"
                    :disabled="
                      radioButtonCheck(
                        props.item.outStandingAmount,
                        props.item.lossTReceiptNumber,
                        props.item.receivedAmount,
                        props.item.lossToTAmount
                      )
                    "
                    :value="props.item.invoiceNumber"
                    @change="
                      radioSelected(
                        props.item.customerId,
                        props.item.invoiceNumber,
                        props.item.outStandingAmount,
                        props.item.customerCurrency,
                        props.item.isInvoiceLevelAssignment
                      )
                    "
                  ></v-radio>
                </v-flex>
              </span>

              <span style="margin-top: 1px">
                <activityLogs
                  :type="'2'"
                  :_id="props.item.invoiceNumber"
                  :id="props.item.invoiceNumber"
                  :subType="9"
                />
              </span>
              &nbsp; &nbsp;
              <v-tooltip
                top
                class="milestone pt-1"
                content-class="tooltip-op"
                color="orange"
                >&nbsp;
                <template slot="activator">{{
                  props.item.invoiceNumber
                }}</template>
                <span class="tooltip"
                  >Job Id
                  {{
                    props.item.jobIds ? props.item.jobIds.join(",") : ""
                  }}</span
                >
              </v-tooltip>
            </v-layout>
          </td>
          <td>
            {{ props.item.companyName }}
          </td>
          <td>
            {{
              props.item.isInvoiceLevelAssignment
                ? "Assignment Level"
                : "Job Level"
            }}
          </td>
          <td>
            {{ getTime4(props.item.salesInvoiceGeneratedAt) }}
          </td>

          <td v-if="props.item.totalSalesAmount">
            {{ props.item.totalSalesAmount.toFixed(2) || "0" }}
            {{ props.item.customerCurrency || "" }}
          </td>
          <td v-else>0.00</td>
          <td>
            {{ props.item.receivedAmount.toFixed(2) || "0" }}
            {{ props.item.customerCurrency || "" }}
          </td>
          <td>
            <span v-if="!adjust && props.item.selected"
              >{{ props.item.outStandingAmount || "0" }}
              {{ props.item.customerCurrency }}</span
            >

            <span v-if="!props.item.selected"
              >{{ props.item.outStandingAmount.toFixed(2) || "0" }}
              {{ props.item.customerCurrency }}</span
            >
            <v-text-field
              v-if="props.item.selected && adjust"
              label="Enter Adjust Amount"
              v-model="props.item.amountEnter"
              type="number"
              min="0"
              @input="
                amountFill(
                  props.item.invoiceNumber,
                  Number(props.item.amountEnter)
                )
              "
              :rules="[rules.required]"
            />
          </td>

          <td style="max-width: 200px !important">
            <v-layout style="grid-gap: 8px; height: 20px">
              <span>
                <activityLogs
                  :type="'4'"
                  :_id="props.item.lossTid"
                  :id="props.item.invoiceNumber"
                  :subType="10"
                />
              </span>

              <span
                style="margin-top: 3px"
                v-if="props.item.lossTstatus == 'Approved'"
              >
                {{
                  (
                    props.item.lossToTAmount + props.item.automaticAmount
                  ).toFixed(2)
                }}
                {{ props.item.customerCurrency || "" }}
              </span>
              <span
                style="margin-top: 3px"
                v-else-if="props.item.lossTstatus == 'Pending'"
              >
                <span
                  v-if="
                    props.item.automaticAmount > 0 &&
                    props.item.lossToTAmount == 0
                  "
                >
                  {{ props.item.automaticAmount.toFixed(2) }}
                </span>
                <span v-else>
                  {{ props.item.lossToTAmount.toFixed(2) }}
                </span>
                {{ props.item.customerCurrency || "" }}
              </span>
              <span
                style="margin-top: 3px"
                v-else-if="props.item.lossTstatus == 'Rejected'"
              >
                <span v-if="props.item.automaticAmount > 0">
                  {{ props.item.automaticAmount.toFixed(2) }}
                </span>
                <span v-else>
                  {{ props.item.lossToTAmount.toFixed(2) }}
                </span>

                {{ props.item.customerCurrency || "" }}
              </span>
              <span style="margin-top: 3px" v-else>
                {{
                  (
                    props.item.lossToTAmount + props.item.automaticAmount
                  ).toFixed(2)
                }}
                {{ props.item.customerCurrency || "" }}
              </span>

              &nbsp;

              <v-flex pt-2>
                <LossToTrukkinPopup
                  v-if="
                    editPopupLtt(
                      lossRadio,
                      props.item.lossTReceiptNumber,
                      props.item.lossToTAmount,
                      props.item.lossTstatus,
                      props.item.automaticAmount
                    )
                  "
                  :type="2"
                  :editData="props.item"
                  :custid="payload.customerId"
                  :invoiceNumber="props.item.invoiceNumber"
                  :lossAmount="props.item"
                />
              </v-flex>
              <v-flex pt-2>
                <AcceptAmount
                  v-if="
                    lossRadio &&
                    props.item.lossTstatus == 'Pending' &&
                    props.item.lossToTAmount
                  "
                  :id="props.item.lossTid"
                  :invoiceLevel="props.item.isInvoiceLevelAssignment"
                  :amount="props.item.lossToTAmount.toFixed(2)"
                  :currency="props.item.customerCurrency"
                />
              </v-flex>
              <v-flex pt-2>
                <RejectAmountRecieved
                  v-if="
                    lossRadio &&
                    props.item.lossTstatus == 'Pending' &&
                    props.item.lossToTAmount
                  "
                  :id="props.item.lossTid"
                  :amount="props.item.lossToTAmount.toFixed(2)"
                  :invoiceLevel="props.item.isInvoiceLevelAssignment"
                  :currency="props.item.customerCurrency"
                />
              </v-flex>
            </v-layout>

            <v-layout
              v-if="props.item.lossTstatus"
              style="padding-left: 35px"
              class="font_Bold"
            >
              <span
                v-if="props.item.lossTstatus == 'Approved'"
                class="green-text"
              >
                {{ props.item.lossTstatus }}
              </span>
              <span v-if="props.item.lossTstatus == 'Pending'">
                <span
                  v-if="
                    props.item.automaticAmount > 0 &&
                    props.item.lossToTAmount == 0
                  "
                  class="green-text"
                >
                  Approved
                </span>
                <span v-else-if="props.item.lossToTAmount" class="orange-text">
                  {{ props.item.lossTstatus }}
                </span>
              </span>
              <span
                v-if="props.item.lossTstatus == 'Rejected'"
                class="red-text"
              >
                <span v-if="props.item.automaticAmount > 0" class="green-text">
                  Approved
                </span>
                <span v-else class="red-text">
                  {{ props.item.lossTstatus }}
                </span>
              </span>
            </v-layout>
            <v-layout
              v-if="props.item.lossTstatus == 'Approved'"
              style="padding-left: 35px"
              class="font_Bold"
            >
              <ProofOfDelivery
                v-if="props.item.lossTTEntryProof.length > 0"
                :deliveryProof="props.item.lossTTEntryProof"
            /></v-layout>
          </td>
        </template>
      </v-data-table>
      <pagination
        :totalPages="totalPages"
        :pages="this.pages"
        @pageNo="page"
        :url="listApiUrl"
        :callback="callback"
        :componentKey="componentKey"
        :action="`customerList`"
        :payload="payload"
        :type="1"
        v-on:pagination-load="checkLoading"
      />
    </v-card-text>
  </v-radio-group>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import pagination from "@/components/Pagination/pagination";
import { accountCustomerPayment } from "@/constants/datatable-headers.js";
import { CustomerListApi } from "@/constants/api-urls.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import AcceptAmount from "@/components/Accounts/CustomerPayment/CustomerPopups/AcceptAmount.vue";
import RejectAmountRecieved from "@/components/Accounts/CustomerPayment/CustomerPopups/RejectAmountRecieved.vue";
import LossToTrukkinPopup from "@/components/Accounts/CustomerPayment/CustomerPopups/LossToTrukkinPopup.vue";
import ProofOfDelivery from "@/components/Pop-ups/ProofOfDelivery";
import activityLogs from "@/components/Common/activityLogs";
import { EventBus } from "../../../../event-bus.js";
export default {
  created() {
    this.$eventBus.$on("customerRecipt", (value) => {
      setTimeout(() => {
        this.refreshList();
      }, 1000);
    });

    this.selectedids = [];
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;

      this.payload = {
        workingCountry: this.workingCountry,
      };
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.path == "/accounts/customerPayment") {
        this.workingCountry = JSON.stringify(val);

        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
    });
  },
  components: {
    pagination,
    SuccessDialog,
    AcceptAmount,
    RejectAmountRecieved,
    ProofOfDelivery,
    activityLogs,
    LossToTrukkinPopup,
  },
  props: {
    customerFilter: String,
    reset: Boolean,
    invoiceNumber: String,
    dateRangenew: Array,
    invoiceFilter: String,
    invoiceSalesType: String,
    adjust: Boolean,
    checkBox: Boolean,
    lossTrukkin: Boolean,
    refresh: Boolean,
  },
  watch: {
    refresh(val) {
      this.payload = {
        workingCountry: this.workingCountry,

        customerId: this.payload.customerId,
      };
    },

    items(val) {},

    lossTrukkin(val) {
      this.lossRadio = val;

      // if (this.lossRadio) {
      //   this.$set(this.payload, "invoiceType", "pending");
      // }
    },

    checkBox(val) {
      this.checkInput = value;
    },
    invoiceFilter: function (n, o) {
      if (n != "") {
        this.$set(this.payload, "invoiceType", n);
      }
    },
    invoiceSalesType: function (n, o) {
      if (n != "") {
        this.$set(this.payload, "invoiceLevel", n);
      }
    },
    adjust(val) {
      this.selectedItems = [];
      this.selectedids = [];
      this.items.forEach((e) => {
        e.selected = false;

        e.amountEnter = e.outStandingAmount;
      });
      if (val) {
        this.payload = {
          workingCountry: this.workingCountry,
          section: "adjust",
          customerId: this.payload.customerId,
        };
      } else {
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
    },
    customerFilter: function (n, o) {
      if (n != "") {
        this.$set(this.payload, "customerId", n);

        this.$emit("Button-show-button");
      }
    },

    reset: function (n, o) {
      // this.loading = true;

      if (n == true) {
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
    },
    invoiceNumber: function (n, o) {
      if (n != "") {
        this.$set(this.payload, "invoiceNumber", n);
      }
    },
    dateRangenew: function (n, o) {
      if (!n) {
        this.$eventBus.$emit("on-load");
        this.payload = {
          workingCountry: this.workingCountry,
        };
      }
      if (n) {
        this.$eventBus.$emit("on-load");
        this.loading = false;
        if (n.length && n[0] && n[1]) {
          this.startDate = moment(n[0]).format("YYYY-MM-DD");
          this.endDate = moment(n[1]).format("YYYY-MM-DD");
        }

        this.$set(this.payload, "salesInvoiceGeneratedAtDateRange", {
          start: this.startDate,
          end: this.endDate,
        });
      } else {
        this.payload = {
          isApproved: this.tabFilter,
          workingCountry: this.workingCountry,
        };
      }
    },
  },
  data() {
    return {
      workingCountry: null,
      selectedValue: "",
      listApiUrl: CustomerListApi,
      title: null,
      dialog1: false,
      lossRadio: false,
      buttonShow: Boolean,
      outStandingAmount: "",
      isChecked: false,
      checkInput: false,
      selectedids: [],
      selectednames: [],
      adjustedAmount: [
        {
          i: 0,
          invoiceNumber: "",
          adjustedAmount: 0,
        },
      ],
      headers: accountCustomerPayment,
      pagination: {},
      dateRange: { startDate: "", endDate: "" },
      startDate: "",
      endDate: "",
      componentKey: 0,
      payload: {},
      dateRange: { startDate: "", endDate: "" },
      startDate: "",
      endDate: "",
      walletInfo: {},
      totalPages: 0,
      selectedItems: [],
      pages: 1,
      items: [],
      lossArrayItem: [],
      sendInfo: [],
      pages: 1,
      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "This field is required.",
        number: (v) => /^[0-9]*$/.test(v) || "Please enter valid amount",
      },
    };
  },
  methods: {
    page(event) {
      this.pages = event;
    },
    editPopupLtt(
      lossRadio,
      lossTReceiptNumber,
      lossToTAmount,
      lossTstatus,
      automaticAmount
    ) {
      if (lossRadio && lossTReceiptNumber && lossToTAmount) {
        if (lossTstatus == "Rejected" && automaticAmount > 0) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    refreshList() {
      this.componentKey = this.componentKey + 1;
    },
    radioSelected(id, invoiceNumber, outStandingAmount, currency, siLevel) {
      this.$emit("invoice-no", invoiceNumber);
      this.$emit("loss-currency", currency);
      this.$emit("si-level", siLevel);

      this.$emit("Loss-id", id);
      console.log(outStandingAmount);
      this.$emit("outstanding-amount", outStandingAmount);
    },

    radioButtonCheck(
      outStandingAmount,
      lossTReceiptNumber,
      receivedAmount,
      lossAmount
    ) {
      if (outStandingAmount <= 0 || receivedAmount <= 0 || lossAmount > 0) {
        return true;
      } else {
        return false;
      }
    },

    checkLoading(event) {
      if (event) {
        this.$emit("on-loading", true);
      } else {
        this.$emit("off-loading", false);
      }
    },
    getTime4(date) {
      return moment(date).format("ll  h:mm a");
    },
    amountFill(id, amount) {
      this.selectedItems = this.selectedItems.map((e) =>
        id == e.invoiceNumber
          ? {
              invoiceNumber: id,
              adjustedAmount: amount,
            }
          : e
      );

      this.$emit("adjust-amount", this.selectedItems);
    },

    checkSelected(select, i, id, amount) {
      this.items = this.items.map((user) =>
        select && id == user.invoiceNumber
          ? {
              ...user,
              selected: true, // just for example
            }
          : user
      );
      this.items = this.items.map((user) =>
        !select && id == user.invoiceNumber
          ? {
              ...user,
              amountEnter: user.outStandingAmount, // just for example
            }
          : user
      );

      this.checkDone = false;
      // this.selectedItems.push({ invoiceNumber: id, adjustedAmount: select });

      if (select && !this.selectedids.includes(id)) {
        this.selectedids.push(id);

        this.selectedItems.push({
          invoiceNumber: id,
          adjustedAmount: amount,
        });
        this.selectednames.push({});
        this.$emit("adjust-amount", this.selectedItems);
      }
      if (!select && this.selectedids.includes(id)) {
        this.selectedids.splice(this.selectedids.indexOf(id), 1);
        let invoiceNumber = id;
        this.selectedItems.splice(
          this.selectedItems.findIndex((e) => e.invoiceNumber === id),
          1
        );

        this.$emit("adjust-amount", this.selectedItems);
      }
    },

    callback(res) {
      this.$eventBus.$emit("off-loading");
      this.selectedValue = "";

      if (res.walletInfo) {
        this.walletInfo = res.walletInfo;
        this.$emit("wallet-amount", this.walletInfo);
        this.$emit("selectCustomerWorkingCountry", res.list[0].workingCountry);
      }

      this.items = [...res.list];

      this.items.forEach((e) => {
        e.selected = false;
        e.inputBox = false;

        // e.amountEnter = e.outStandingAmount;

        if (this.selectedids.includes(e.invoiceNumber)) {
          e.selected = true;
          this.selectedItems.filter((p) => {
            if (e.invoiceNumber === p.invoiceNumber) {
              e.amountEnter = p.adjustedAmount;
            }
          });
        } else {
          e.amountEnter = e.outStandingAmount;
        }
      });

      this.totalPages = !this.adjust
        ? Math.ceil(res.totalData / 15)
        : Math.ceil(res.totalData / 5);
      this.pagination.rowsPerPage = !this.adjust ? 15 : 5;

      window.scrollTo(0, 0);
    },
  },
};
</script>
<style>
.mt--10 {
  margin-top: -12px !important;
}
.tooltip-op-comment {
  max-width: 40%;
  opacity: 1 !important;
}

.orange-text {
  color: orange;
}
.red-text {
  color: red;
  animation: animate 1.5s linear infinite;
}
.green-text {
  color: green;
}

.font_Bold {
  font-size: 13px;
  font-weight: 600;

  font-family: "Open Sans bold", sans-serif;
}
.theme--light.v-icon {
  color: black;
}
</style>
