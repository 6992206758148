<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs3>
        <v-autocomplete
          v-model="searchTypes"
          :items="searchItems"
          label="Search Type"
          item-text="status"
          single-line
          item-value="abbr"
          @change="searchByType()"
          return-object
        ></v-autocomplete>
      </v-flex>
      <v-divider class="divider-style" vertical></v-divider>
      <v-flex xs3 pr-5>
        <v-text-field
          class="gap searchAccounts"
          :disabled="!searchTypes"
          :label="'Search'"
          single-line
          v-model="search"
          append-icon="search"
          @keyup.enter="searchAnything"
          @click:append="searchAnything"
        ></v-text-field>
      </v-flex>
      <v-flex xs5 pt-3 pr-5>
        <v-autocomplete
          class="pt-0 currencyTitle"
          :items="customers"
          v-model="customerId"
          label="Select Company Name"
          single-line
          autocomplete="offfadsf"
          @change="searchCustomer"
        />
      </v-flex>
      <v-flex
        xs3
        pl-3
        style="padding-top: 18px"
        class="wrapper"
        id="accountDate"
      >
        <ejs-daterangepicker
          :placeholder="waterMark"
          v-model="dateRange2"
        ></ejs-daterangepicker>
      </v-flex>
      <v-flex xs2 class="">
        <v-card-actions class="justify-center">
          <v-btn
            class="reset-btn-color"
            style="padding: 21px !important"
            flat
            prepend-icon
            color="orange darken-1"
            @click.native="reset()"
          >
            Reset
            <v-icon color="orange">donut_large</v-icon>
          </v-btn>
        </v-card-actions>
      </v-flex>
      <v-flex xs3>
        <InvoicePopup
          :jobIds="this.$props.jobIds"
          :selectedCustomerForInvoice="this.$props.selectedCustForInvoice"
          :countInvoice="count"
          @customerInvoiceFailed="customerInvoiceFailed()"
          @reset="reset()"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import InvoicePopup from "./InvoicePopup";
import { StorageKeys } from "../../constants/constants";
import Vue from "vue";
import { DateRangePickerPlugin } from "@syncfusion/ej2-vue-calendars";

Vue.use(DateRangePickerPlugin);
import moment from "moment";
import Loading from "vue-loading-overlay";
import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually (in case you want to override it)
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { EventBus } from "../../event-bus.js";
export default {
  data() {
    return {
      searchItems: [
        {
          status: "Job Id",
          abbr: "jobId",
        },
        { status: "Assignment Id", abbr: "assignmentId" },
        { status: "Sales Invoice Number", abbr: "salesInvoice" },
        { status: "Purchase Invoice Number", abbr: "purchaseInvoice" },
      ],
      searchTypes: "",
      customerId: null,
      waterMark: "Select a Range",
      customers: [],
      customerCurrency: null,
      workingCountry: [],
      startDate: "",
      search: "",
      workingCountry: [],
      dateRange: { startDate: "", endDate: "" },
      dateRange2: null,
    };
  },
  components: {
    Loading,
    DateRangePicker,
    InvoicePopup,
  },
  props: {
    jobIds: Array,
    count: Number,
    selectedCustForInvoice: Object,
  },
  watch: {
    dateRange2() {
      localStorage.setItem("accountStartdate", this.dateRange2.startDate);
      localStorage.setItem("accountlEndDate", this.dateRange2.endDate);
      this.$emit("daterangenew-cust", this.dateRange2);
    },
  },
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;
      this.fetchShippers(this.workingCountry);
    }

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = val;
      this.fetchShippers(this.workingCountry);
      this.reset();
    });
  },
  methods: {
    fetchShippers(val) {
      this.customers = [];
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.t = config.headers.authorization;
      let body = { workingCountry: val, blockCustomerAllow: true };
      this.axios
        .post(`${this.constants.apiUrl}/dmsAdmin/shippers`, body, config)
        .then((response) => {
          const { data = null } = response.data;
          for (var i = 0; i < data.length; i++) {
            data[i].fullName = data[i].companyName;
          }

          data.forEach((element) => {
            if (element.companyName) {
              this.customers.push({
                text: element.fullName,
                value: element.userId,
                companyName: element.companyName,
                companyVatNumber: element.companyVatNumber,
                address: element.address
                  ? element.address.replaceAll("\n", ", ")
                  : "",
                invoicingCity: element.invoicingCity,
                invoicingCountry: element.invoicingCountry,
                selectedIndustry: element.selectedIndustry,
              });
            } else {
              this.customers.push({
                text: element.name,
                value: element.userId,
                companyName: element.companyName,
                companyVatNumber: element.companyVatNumber,
                address: element.address
                  ? element.address.replaceAll("\n", ", ")
                  : "",
                invoicingCity: element.invoicingCity,
                invoicingCountry: element.invoicingCountry,
                selectedIndustry: element.selectedIndustry,
              });
            }
          });
        });
    },
    searchByType() {
      this.search = "";
      this.$emit("filters-type", this.searchTypes.abbr);
      this.$emit("customer-search", this.search);
    },
    searchAnything() {
      localStorage.setItem("searchAccount", this.search);
      localStorage.setItem(
        "searchAccountType",
        JSON.stringify(this.searchTypes)
      );

      this.$emit("customer-search", this.search);
      this.$emit("filter-type", this.searchTypes.abbr);
    },
    customerInvoiceFailed() {
      this.$emit("customerInvoiceFailed", { ...this.selectedCustForInvoice });
    },
    reset() {
      localStorage.removeItem("searchAccount");
      localStorage.removeItem("searchAccountType");
      localStorage.removeItem("accountSearchCust");
      localStorage.removeItem("accountStartdate");
      localStorage.removeItem("accountlEndDate");
      this.$emit("customer-reset", this.search);
      this.search = "";
      this.searchTypes = "";
      this.dateRange2 = null;
      this.customerId = null;
    },
    searchCustomer() {
      localStorage.setItem("accountSearchCust", this.customerId);
      this.$emit("customer-Id", this.customerId);
    },
  },
};
</script>
<style scss>
div#ej2-datetimepicker_0_popup {
  left: 905.2px !important;
}
#accountDate .e-input-group-icon.e-range-icon.e-icons::after {
  content: none !important;
}

#accountDate span.e-input-group-icon.e-range-icon.e-icons {
  position: absolute;
  left: 0;
  right: 100px !important;
  width: 100%;
  text-align: right;
}
#accountDate .e-input-group-icon.e-range-icon::before,
*.e-control-wrapper .e-input-group-icon.e-range-icon::before {
  content: "\e245";
  font-family: "e-icons";
  float: right;
  position: absolute;
  right: 0;
}
#accountDate .e-daterangepicker {
  left: inherit !important;
  right: 120px;
}
.e-daterangepicker.e-popup .e-calendar,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar {
  background-color: #fff;
  height: 100%;
}
#accountDate .e-clear-icon {
  display: none !important;
  opacity: 0;
}
.searchAccounts .v-label.v-label--is-disabled.theme--light {
  left: 10px !important;
}

.searchAccounts .theme--light.v-label {
  left: 10px !important;
}
.searchAccounts input {
  padding-left: 10px !important;
}
</style>

<style scoped>
.divider-style {
  margin-bottom: 24px;
  margin-top: 15px;
  background: lightgrey;
}
</style>
