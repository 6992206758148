import { render, staticRenderFns } from "./TripInformation.vue?vue&type=template&id=fd8a4b10&scoped=true&"
import script from "./TripInformation.vue?vue&type=script&lang=js&"
export * from "./TripInformation.vue?vue&type=script&lang=js&"
import style0 from "./TripInformation.vue?vue&type=style&index=0&id=fd8a4b10&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd8a4b10",
  null
  
)

export default component.exports