<template>
  <div class="log">
    <button type="button" class="doc-ass-btn" @click="dialog = true">
      View
    </button>
    <v-dialog v-model="dialog" max-width="85%" persistent>
      <v-form ref="form">
        <v-card style="padding: 0px !important; overflow-x: hidden">
          <v-toolbar
            dark
            style="background-color: #dcdcdc; color: black; padding-left: 10px"
            flat
          >
            <v-toolbar-title>Driver Document Document </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="mx-2" fab small @click="dialog = false">
              <v-icon dark> close </v-icon>
            </v-btn>
          </v-toolbar>

          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer class="customScrollAir">
            <v-card-text class="nospace">
              <v-layout style="border-top: 1px solid #efefef">
                <v-flex xs6 pt-3 pl-3
                  ><span class="board-style">Driver Details : </span>
                  {{
                    assignmentData.driverName
                      ? assignmentData.driverName + " | "
                      : "N.A"
                  }}
                  <span
                    v-if="assignmentData.driverName"
                    style="position: relative; top: 5px"
                  >
                    <img src="../../../assets/TruckIcon.svg" height="18px"
                  /></span>
                  <span
                    v-if="assignmentData.truckNumber"
                    style="position: relative; top: 1px"
                    >{{ assignmentData.truckPrefix }}-{{
                      assignmentData.truckNumber
                    }}</span
                  ></v-flex
                >
                <v-flex xs3 pt-3
                  ><span class="board-style">Driver Price :</span>
                  {{
                    assignmentData.totalAmount
                      ? assignmentData.totalAmount
                      : "N.A"
                  }}
                  {{
                    assignmentData.totalAmount
                      ? assignmentData.customerCurrency
                      : ""
                  }}
                </v-flex>
                <v-flex xs3 pt-3
                  ><span class="board-style">Balanced Amount : </span>
                  {{
                    assignmentData.amountToPaid
                      ? assignmentData.amountToPaid
                      : "N.A"
                  }}
                  {{
                    assignmentData.amountToPaid
                      ? assignmentData.customerCurrency
                      : ""
                  }}</v-flex
                >
                <v-flex xs2 style="text-align: end" class="pb-2">
                  <v-btn class="ma-2" outline color="red">
                    Received
                    <v-icon small style="padding-left: 5px"
                      >check_circle</v-icon
                    >
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-data-table
              id="ListTable"
              hide-actions
              :headers="headers"
              :items="items"
              style="word-break: break-all"
            >
              <template slot="items" slot-scope="props">
                <td style="width: 20%">{{ getTime3(props.item.createdAt) }}</td>
                <td style="width: 20%">{{ props.item.documentType }}</td>
                <td style="width: 20%">{{ props.item.uploadedFrom }}</td>
                <td style="width: 20%">
                  <v-layout>
                    <ul style="list-style: none; display: flex">
                      <li
                        :key="i"
                        v-for="(doc, i) in props.item.document"
                        class="list"
                        ref="documentList"
                      >
                        <a
                          :href="doc"
                          class="document-link"
                          style="text-decoration: none; outline: none"
                          v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                          target="_blank"
                        >
                          <img
                            src="../../../assets/pfd.png"
                            height="40"
                            width="32"
                          />
                        </a>
                        <a
                          :href="doc"
                          style="text-decoration: none; outline: none"
                          class="document-link"
                          target="_blank"
                          v-else
                        >
                          <img
                            src="../../../assets/docimg.webp"
                            height="40"
                            width="40"
                          />
                        </a>
                      </li>
                    </ul>
                  </v-layout>
                </td>
              </template>
            </v-data-table>

            <span class="page-count-span totalpages"
              >Total Pages - {{ totalPages }}</span
            >

            <PaginationButtons
              v-if="dialog"
              :url="`/dmsAdmin/getShipmentDeliveryDoc`"
              :payload="payload"
              :callback="callback"
              :componentKey="componentKey"
              v-on:pagination-payload="paginationPayload"
            />
            <success-dialog
              :content="x.message"
              :show="x.success"
              :onclose="closeMessage"
            />

            <v-snackbar
              :timeout="3000"
              color="red darken-2"
              v-model="error"
              class="white--text"
              v-if="error"
              >{{ error }}</v-snackbar
            >
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import { EventBus } from "../../../event-bus.js";
import Loading from "vue-loading-overlay";
import SuccessDialog from "@/components/Common/SuccessDialog";

import ErrorBox from "@/components/Common/ErrorBox";
import moment from "moment";
import {
  StorageKeys,
  JobTypes,
  JobListTypes,
} from "../../../constants/constants";
import { async } from "q";
import PaginationButtons from "@/components/Pagination/Pagination-New";
export default {
  created() {
    window.addEventListener("online", () => {
      this.componentKey += 1;
    });
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.workingCountry = key.value;
          this.currency = key.currency;
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "documents") {
        this.$router.push(`/jobs`);
      }
      this.workingCountry = val.value;
      this.loading = true;
    });
    this.$eventBus.$on("success-driver-doc", () => {
      this.componentKey += 1;
    });
    if (!navigator.onLine) {
      this.loading = false;
      this.error = "Please check your internet connection";
      setTimeout(() => {
        this.error = "";
      }, 3000);
      return;
    }
  },
  beforeDestroy() {
    this.$eventBus.$off("success");
    this.$eventBus.$off("edit-success");
  },
  components: {
    PaginationButtons,
    SuccessDialog,
    ErrorBox,
    Loading,
  },
  props: {
    loadingstop: Function,
    loader: Boolean,

    viewOnly: Boolean,
    jobCurrency: String,
    tabOrder: Number,
  },

  data() {
    return {
      workingCountry: null,
      totalAddtionalCharges: "",
      additionalchargesId: "",
      title: null,
      assignId: "",
      message: null,
      assignmentData: {},
      statusComments: "",
      confirmationDialog: false,
      dialog: false,
      dialogDelete: false,
      options: {
        color: "#ffc04c",
        width: 500,
        zIndex: 200,
      },
      operationName: "",
      chargesType: "",
      customerStatus: "",
      customerId: "",
      JobTypes: [],
      jobId: "",
      status: "",
      assignID: "",
      currency: "",
      jobType: "",
      error: "",
      documents: [],
      document: [],
      loading: true,
      processing: false,
      x: {
        message: "",
        loading: true,
        success: false,
        menu: false,

        error: null,
        missingField: null,
      },
      search: "",
      componentKey: 0,
      assignment: [],
      assignmentId: "",
      totalPages: "",

      payload: {},

      headers: [
        {
          text: "Date & Time",
          sortable: false,
          value: "dateAndTime",
          class: "table-header-item",
        },
        {
          text: "Document Name",
          sortable: false,
          value: "document",
          class: "table-header-item",
        },
        {
          text: "Uploaded By",
          sortable: false,
          value: "uploadedBy",
          class: "table-header-item",
        },
        {
          text: "Document ",
          sortable: false,
          value: "document",
          class: "table-header-item",
        },
      ],
      items: [],
    };
  },
  watch: {},
  methods: {
    assigments() {
      this.loading = true;
      const data = {
        jobId: this.$route.params.id,
        tabOrder: this.$props.tabOrder ? this.$props.tabOrder.toString() : "1",
      };

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      this.axios
        .post(`${apiUrl}/dmsAdmin/assignmentForEditDriverPrice`, data, config)
        .then(
          (response) => {
            this.assignmentId = response.data.data[0]._id;
            response.data.data.forEach((e) => {
              this.assignment.push({ value: e._id, text: e.assignmentId });
            });
            this.dialog = true;
            this.getList();
            this.loading = false;
          },
          (error) => {
            this.error = error.response.data.message;
            // this.dailog = false;
            this.loading = false;
          }
        );
    },

    markDocStatus() {
      let Url = "/dmsAdmin/markShipmentDocStatus";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignId: this.assignmentId,
      };
      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          this.x.success = true;
          this.confirmationDialog = false;
        },
        (error) => {
          this.x.success = false;
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    getList() {
      this.dialog = true;
      this.processing;
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry = key.value;
            this.currency = key.currency;
          }
        });
      }

      EventBus.$on("countryChanged", (val) => {
        if (this.$route.name == "documents") {
          this.$router.push(`/jobs`);
        }
        this.workingCountry = val.value;
        this.loading = true;
      });
      this.payload = {
        skip: "0",
        limit: "15",
        assignmentId: this.assignmentId,
        workingCountry: this.workingCountry,
      };
    },
    async deleteDevice() {
      this.operationName = "edit-additional-charges";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.loading = true;
        //this.processing = true;
        let Url = "/dmsAdmin/deleteAdditionalCharges";
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };

        let body = {
          jobId: this.jobId,
          assignmentId: this.assignId,
          additionalchargesId: this.additionalchargesId,
        };
        this.axios.post(this.constants.apiUrl + Url, body, config).then(
          (response) => {
            this.loading = false;
            this.dialogDelete = false;
            this.componentKey += 1;
          },
          (error) => {
            this.x.success = false;
            this.dialogDelete = false;
            this.loading = false;
            this.error = error.response.data.message;
          }
        );
      }
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.$emit("on-block");
    },
    getTime3(date) {
      return moment(date).format("MMMM Do YYY , hh:mm a ");
    },
    callback(res) {
      this.loading = false;
      this.items = res.list;
      this.assignmentData = { ...res.assignmentDetail[0] };
      this.totalPages = Math.ceil(res.totalData / 15);
      // window.scrollTo(0, 0);
    },

    paginationPayload(event) {
      this.payload = event;
      this.loading = true;
    },
  },
};
</script>
<style scoped>
.customScrollAir {
  overflow-y: scroll !important;
  max-height: calc(100vh - 200px) !important;
}
</style>
<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.doc-ass-btn {
  text-decoration: none;
  color: orange;
  border: 2px solid orange;
  padding: 4px 15px;
  border-radius: 5px;
}
.board-style {
  color: darkorange;
  font-size: 16px;
  font-weight: 700;
}
.orange-btn {
  text-decoration: none;
  color: #ffffff;
  border-radius: 4px;
  background-color: darkorange;
  border: 2px solid darkorange;
  padding: 5px 15px;
}
.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.editCustomer {
  margin: auto 0;
}

.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.color-black {
  color: #000000 !important;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.communicate-btn {
  border: 2px solid darkorange !important;
  color: darkorange !important;
  background-color: transparent !important;
}
</style>
