<template>
  <div>
    <button
      class="si-ass-btn"
      flat
      prepend-icon
      title="Share Invoice"
      type="button"
      color="white darken-1"
      @click="dialog = true"
    >
      <img src="../../../assets/share-icon.png" />
    </button>
    <v-dialog v-model="dialog" persistent max-width="30%">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-form ref="form" onSubmit="return false;">
          <v-card class>
            <v-toolbar>
              <v-toolbar-title>Share Invoice</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn flat @click="close()" icon>
                <v-icon class="heading grey--text text--darken-4">close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout>
                  <v-flex xs12>
                    <v-text-field
                      label="To"
                      v-model="communicateDialog.to"
                      value="email"
                      disabled
                    />
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-combobox
                    multiple
                    v-model="communicateDialog.select"
                    label="cc"
                    :items="items"
                    append-icon
                    chips
                    deletable-chips
                    hide-selected
                    hide-details
                    :persistent-hint="false"
                    class="tag-input"
                    v-on:keyup.17="checkValue"
                    :search-input.sync="search"
                    @keyup.tab="updateTags"
                    @keypress="smartRecipent()"
                    @paste="updateTags"
                    @change="checkValue()"
                    :rounded="true"
                  >
                  </v-combobox>
                </v-layout>
                <v-layout>
                  <v-flex xs12 sm6 md12>
                    <v-text-field
                      label="Type your subject"
                      v-model="communicateDialog.subject"
                      counter
                      maxlength="50"
                    />
                  </v-flex>
                </v-layout>
                <v-layout style="text-align: center"> </v-layout>
                <v-layout style="text-align: end">
                  <v-flex xs12 sm6 md12>
                    <v-btn
                      slot="activator"
                      color="primary"
                      v-if="!communicateDialog.success"
                      class="communicate-btn"
                      @click="sendCommunication"
                      dark
                      >Send
                    </v-btn>
                    <v-btn
                      v-if="communicateDialog.success"
                      slot="activator"
                      color="success"
                      dark
                      >Sent
                      <v-icon style="padding-right: 3px !important" small>
                        check
                      </v-icon>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-form>
        <success-dialog
          :content="x.message"
          :show="x.success"
          :onclose="close"
        />
        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="x.error"
          class="white--text"
          v-if="x.error"
          >{{ x.error }}</v-snackbar
        >
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import ErrorBox from "@/components/Common/ErrorBox";
import { StorageKeys } from "../../../constants/constants";
import SuccessDialog from "@/components/Common/SuccessDialog";
export default {
  data() {
    return {
      document: [],
      x: {
        message: "",
        loading: false,
        success: false,
        menu: false,
        error: null,
      },
      res: "",
      search: "",
      dialog: false,
      communicateDialog: {
        error: "",
        success: false,
        show: false,
        to: this.$props.email,
        type: "milestone",
        subject: null,
        cc: null,
        select: [],
        items: [],
        search: "", //sync search
      },
      selected: ["email"],
      valid: true,
      items: [],
      lazy: false,
      message: null,
      loading: false,
      x: {
        error: "",
      },
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  components: {
    SuccessDialog,
    ErrorBox,
  },
  props: {
    email: String,
    jobId: Number,
    workingCountry: String,
    invoiceUrl: String,
  },
  methods: {
    close() {
      this.dialog = false;
      this.communicateDialog.subject = "";
      this.communicateDialog.select = [];
      this.items = [];
      this.search = "";
      this.x.success = false;
    },

    smartRecipent() {
      this.items = [];

      if (this.search && this.search.trim().length > 0) {
        let url = "/dmsAdmin/smartRecipent ";
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          jobId: this.$props.jobId.toString(),
          searchText: this.search.trim(),
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.items = response.data.data;
          },
          (error) => {
            this.error = error.response.data.message;
          }
        );
      } else {
        this.search = "";
      }
    },
    checkValue() {
      if (this.communicateDialog.select.length) {
        let count_valid = 0;
        // let regex = '/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/';
        var re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        for (let k = 0; k < this.communicateDialog.select.length; k++) {
          this.communicateDialog.select[k] =
            this.communicateDialog.select[k].trim();

          if (re.test(this.communicateDialog.select[k])) {
            count_valid = count_valid + 1;
          }
        }
        if (count_valid !== this.communicateDialog.select.length) {
          this.x.error = "Please make sure all the emails are properly formed";

          this.loading = false;
          return false;
        }
      }

      if (this.communicateDialog.select) {
        this.communicateDialog.select = this.communicateDialog.select.filter(
          function (el) {
            return el.trim().length > 0;
          }
        );
        this.items = [];
      }
      this.search = "";
    },
    updateTags() {
      this.$nextTick(() => {
        this.search = this.search.trim();
        this.communicateDialog.select.push(...this.search.split(","));
        this.$nextTick(() => {
          this.search = "";
        });
      });
    },

    sendCommunication() {
      setTimeout(() => {
        this.communicateDialog.success = false;
        this.x.error = "";
        this.x.success = false;
        let url = "/dmsAdmin/shareInvoice";
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        if (!this.communicateDialog.subject) {
          this.x.error = "Please provide a suitable subject";
          this.loading = false;
          return false;
        }
        let body = {
          invoiceUrl: this.$props.invoiceUrl,
          workingCountry: this.$props.workingCountry,
          toEmail: this.$props.email,
          subject: this.communicateDialog.subject,
        };
        let count_valid = 0;
        // if (!this.communicateDialog.select.length) {
        //   this.x.error = "Please make sure all the emails valid";
        //   return;
        // }

        if (this.communicateDialog.select) {
          // let regex = '/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/';
          var re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          for (let k = 0; k < this.communicateDialog.select.length; k++) {
            this.communicateDialog.select[k] =
              this.communicateDialog.select[k].trim();
            // //console.log(this.communicateDialog.select[k].match(regex));

            this.communicateDialog.select[k] =
              this.communicateDialog.select[k].trim();
            if (re.test(this.communicateDialog.select[k])) {
              count_valid = count_valid + 1;
            }
          }
          if (count_valid !== this.communicateDialog.select.length) {
            this.x.error =
              "Please make sure all the emails are properly formed";
            this.loading = false;
            return;
          }
        }

        if (this.communicateDialog.select.length) {
          body.cc = this.communicateDialog.select;
        }

        let { apiUrl } = this.constants;
        this.loading = true;
        this.axios.post(`${apiUrl}/dmsAdmin/shareInvoice`, body, config).then(
          (response) => {
            this.communicateDialog.success = true;
            this.dialog = false;
            this.communicateDialog.success = false;
            this.communicateDialog.subject = "";
            this.communicateDialog.select = [];
            this.items = [];
            this.search = "";
            this.loading = false;

            this.x.success = true;
          },
          (error) => {
            this.x.error = error.response.data.message;
            this.loading = false;
          }
        );
      }, 1000);
    },
  },
};
</script>
<style lang="scss">
.v-chip .v-chip__content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 28px;
  cursor: default;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: fit-content !important;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 12px;
  vertical-align: middle;
  white-space: inherit !important;
  word-break: break-all !important   ;
  z-index: 1;
}
</style>
<style scoped>
.listClass {
  text-align: center;
  padding-left: 0px !important;
  display: inline !important;
}
.message {
  display: inline-block;
  margin: 0 50px;
}
.communication {
  width: 40%;
}
.success {
  font-size: 18px;
  font-weight: 600;
}
.messagebtn {
  margin: 0 auto;
}
.list {
  display: block;
}
.document-link {
  text-decoration: none;
}
.si-ass-btn {
  text-decoration: none;
  color: #ffffff;

  border-radius: 5px;
  border: 2px solid lightskyblue;
  padding: 2px 4px;
  margin-right: 5px;
}
.communicate-btn {
  border: 2px solid darkorange !important;
  color: white !important;
  background-color: darkorange !important;
}
.theme--light.v-chip {
  background: #feebd9 !important;
  color: rgb(248, 159, 69) !important;
}
.tag-input span.chip {
  background-color: #1976d2;
  color: #fff;
  font-size: 1em;
  white-space: inherit !important;
  word-break: break-all !important   ;
  height: fit-content;
}

.tag-input span.v-chip {
  background-color: #1976d2;
  color: #fff;
  font-size: 1em;
  padding-left: 7px;
  white-space: inherit !important;
  word-break: break-all !important   ;
  height: fit-content;
}

.tag-input span.v-chip::before {
  content: "label";
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: inherit !important;
  word-break: break-all !important   ;
  height: fit-content;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}
.info {
  padding: 10px;
  font-size: 14px;
  color: #ffffff;
  border-radius: 10px;
}
.error.info {
  background-color: #ef5350 !important;
}
.success.info {
  background-color: #66bb6a !important;
}
</style>
