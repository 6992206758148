<template>
  <div class="log">
    <v-btn
      small
      @click="getList()"
      v-if="section === 'Pay' && isVisible !== 0"
      class="payy-ass-btn green-button ready_pay"
    >
      Pay Advance
    </v-btn>
    <v-btn
      style="width: 30px; height: 30px; cursor: pointer"
      outline
      small
      fab
      v-if="this.$route.name == 'Accounts' && section !== 'Pay'"
      @click="getList()"
      color="orange"
    >
      <v-icon>edit</v-icon>
    </v-btn>

    <button
      v-if="this.$route.name != 'Accounts' && section !== 'Pay'"
      color="orange"
      outline
      class="pay-ass-btn"
      type="button"
      @click.stop="dialog = false"
      @click="getList()"
    >
      Details
    </button>
    <v-dialog v-model="dialog" max-width="90%" persistent>
      <v-form ref="form" onsubmit="return false;" class="layout__list">
        <v-card style="padding: 0px !important">
          <v-toolbar
            class="pd__left__15"
            dark
            style="background-color: #dcdcdc; color: black"
            flat
          >
            <v-toolbar-title>Request Advance Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn class="mx-2" fab small @click="closePopup()">
              <v-icon dark> close </v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="loading"
          ></v-progress-linear>
          <v-spacer>
            <v-card-text class="nospace">
              <v-card-text class="nospace">
                <v-layout
                  style="
                    border-top: 1px solid #efefef;
                    padding-left: 15px;
                    padding-bottom: 6px;
                  "
                >
                  <v-flex xs3 pt-2 v-if="advanceAmount && advanceStatus"
                    ><span class="dashboard-style"
                      >Total Advance Requested:
                    </span>
                    {{ advanceAmount }} {{ currency }}</v-flex
                  >
                  <v-flex xs3 pt-2 v-if="totaladvancePaid"
                    ><span class="dashboard-style">Total Advance Paid: </span>
                    {{ totaladvancePaid }} {{ currency }}</v-flex
                  >
                  <v-flex xs3 pt-2 v-if="totaldata"
                    ><span class="dashboard-style">Driver Price: </span
                    >{{ paymentAmount }} {{ currency }}</v-flex
                  >
                </v-layout>
              </v-card-text>
              <v-data-table
                id="TransporterListTable"
                hide-actions
                :headers="headers"
                style="word-break: break-word"
                expanded.sync="expanded"
                item-key="_id"
                :disable-initial-sort="true"
                single-expand
                show-expand
                :items="items"
              >
                <template slot="items" slot-scope="props">
                  <tr
                    style="cursor: pointer"
                    @click="props.expanded = !props.expanded"
                  >
                    <td>
                      <v-layout row justify-center>
                        <activityLogs
                          :subType="17"
                          :id="props.item._id"
                          :type="'advance'"
                        />
                        <span class="pl-1 pt-1">
                          {{ props.item.advanceAmount }} {{ currency }}</span
                        >
                      </v-layout>
                    </td>
                    <td v-if="props.item.actualAmountPaid">
                      {{ props.item.actualAmountPaid }} {{ currency }}
                    </td>
                    <td v-else>N.A</td>
                    <!-- <td v-if="props.item.updatedAt">
                      {{ props.item.employeeName }}({{
                        props.item.employeeId
                      }})<br />
                      {{ getTime3(props.item.updatedAt) }}
                    </td>
                    <td v-else>
                      {{ props.item.employeeName }}({{ props.item.employeeId }})
                    </td> -->
                    <td v-if="props.item.creditorModeOfPayment == 'Cash'">
                      {{ props.item.creditorModeOfPayment }}
                    </td>
                    <td
                      v-else-if="
                        props.item.creditorModeOfPayment == 'Bank Transfer'
                      "
                    >
                      {{ props.item.creditorModeOfPayment }}<br />
                      Swift No. -
                      {{ props.item.swiftNumber || "N.A" }} <br />
                      IBAN No. -
                      {{ props.item.IBANNumber || "N.A" }}<br />
                      Account No. -
                      {{ props.item.accountNumber || "N.A" }} <br />Account
                      Holder Name-
                      {{ props.item.accountHolderName || "N.A" }}
                    </td>
                    <td v-else>-</td>
                    <!-- <td>
                      {{ props.item.modeOfPayment || "N.A" }}<br />
                      {{ props.item.bankName }}
                    </td> -->
                    <td>
                      {{
                        props.item.actualPaidDate && props.item.actualPaidTime
                          ? getDate(props.item.actualPaidDate) +
                            " " +
                            props.item.actualPaidTime
                          : "-"
                      }}
                    </td>
                    <td>
                      {{
                        assignmentDetails.driverFirstName
                          ? assignmentDetails.driverFirstName +
                            " " +
                            assignmentDetails.driverLastName
                          : ""
                      }}<br />(via {{ ownerType }})
                      <br />
                      {{ assignDetails.driverData ? assignDetails.driverId : ""
                      }}<br />
                      <span v-if="assignDetails.truckNumber">
                        <template v-if="assignDetails.truckNumber">
                          {{ assignDetails.truckPrefix }}-{{
                            assignDetails.truckNumber
                          }}</template
                        >
                        <template v-else>
                          {{ assignDetails.driverData.truckPrefix }}-{{
                            assignDetails.driverData.truckNumber
                          }}</template
                        >
                      </span>
                    </td>
                    <td>
                      {{ getStatus(props.item.advanceStatus) }}
                      <div
                        v-if="
                          props.item.isRbEntryFailed &&
                          props.item.advanceStatus == !'Paid'
                        "
                        style="color: red"
                      >
                        (RB Entry Failed)
                      </div>
                    </td>
                    <td>
                      <span v-if="props.item.adjustedAmount">
                        {{ props.item.adjustedAmount }} {{ currency }} <br />{{
                          props.item.remarksForAdjustment
                            ? "(" + props.item.remarksForAdjustment + ")"
                            : ""
                        }}</span
                      >
                      <span v-else>N.A</span>
                    </td>

                    <td style="word-break: break-word">
                      {{
                        props.item.cancelAdvanceReason ||
                        props.item.rejectionReason ||
                        "-"
                      }}
                    </td>
                    <td v-if="!isPurchaseInvoiceGenerated">
                      <AdvancePaid
                        v-if="
                          (props.item.advanceStatus === 'Ready' &&
                            assignDetails.assignmentStatus != 4) ||
                          (props.item.advanceStatus === 'PartiallyPaid' &&
                            assignDetails.assignmentStatus != 4)
                        "
                        :type="'add'"
                        v-permissions="'pay-advance-for-accounts'"
                        :assignmentData="props.item"
                        :actualamoutPaid="props.item.actualAmountPaid"
                        :driverCreditDuration="
                          assignDetails.driverData
                            ? assignDetails.driverData.driverPaymentDuration
                            : null
                        "
                        :invoiceCity="invoiceCity"
                        :assignedToTransporter="assignedToTransporter"
                        :ownerType="ownerType"
                        :assignId="
                          assignDetails ? assignDetails.assignmentId : null
                        "
                        :currency="currency"
                        :invoiceCompany="invoiceCompany"
                        :additionalCharges="additionalCharges"
                        :totaladvancePaid="totaladvancePaid"
                        :totalAdvancePaidArray="
                          assignDetails.totalAdvancePaidAmount || 0
                        "
                        :driverPrice="paymentAmount"
                        @close-partial-table="closeSubPartial()"
                        @assign-list-refresh="refreshList()"
                      />
                      <v-btn
                        v-if="
                          props.item.advanceStatus === 'Ready' &&
                          assignDetails.assignmentStatus != 4
                        "
                        class="reject-ass-btn"
                        small
                        outline
                        color="red"
                        @click="
                          openReject(props.item._id, props.item.advanceAmount)
                        "
                      >
                        Reject
                      </v-btn>
                      <proof-of-delivery
                        v-if="
                          props.item.advanceStatus == 'Paid' &&
                          props.item.document &&
                          props.item.document.length
                        "
                        :deliveryProof="props.item.document"
                      />
                    </td>
                    <td v-else></td>
                  </tr>
                </template>
                <template slot="expand" slot-scope="props">
                  <PartialAdvanceReadyToPay
                    :advanceId="props.item._id"
                    :assignDetails="assignDetails"
                    :listData="props.item"
                    :invoiceCompany="invoiceCompany"
                    :invoicingCity="invoiceCity"
                    @assign-list-refresh2="refreshList()"
                  />
                </template>
              </v-data-table>

              <span class="page-count-span totalpages"
                >Total Pages - {{ totalPages }}</span
              >

              <PaginationButtons
                v-if="dialog"
                :url="`/dmsAdmin/paidAdvanceList`"
                :payload="payload"
                :callback="callback"
                :componentKey="componentKey"
                v-on:pagination-payload="paginationPayload"
              />
              <success-dialog
                :content="x.message"
                :show="x.success"
                :onclose="closeMessage"
              />

              <v-dialog v-model="rejectDialog" max-width="40%" persistent>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card style="overflow-x: hidden">
                    <v-card-title
                      style="
                        background-color: #f5f5f5;
                        padding-bottom: 5px;
                        padding-top: 5px;
                      "
                    >
                      <span class="headline">Reject Advance</span>
                      <v-spacer></v-spacer>
                      <v-btn icon @click.prevent="closeReject()">
                        <v-icon class="heading grey--text text--darken-4"
                          >close</v-icon
                        >
                      </v-btn>
                    </v-card-title>
                    <v-card-text class="title-layout-size">
                      <v-layout>
                        <v-flex xs12 md12 class="text-stylee" pt-2 pb-2>
                          Are you sure want to reject?
                        </v-flex>
                      </v-layout>

                      <v-layout>
                        <v-flex md12>
                          <span>Provide Rejection Reason</span>

                          <v-layout>
                            <v-flex>
                              <v-text-field
                                class="pt-0 currencyTitle"
                                type="text"
                                single-line
                                label="Enter Your Reason"
                                required
                                v-model="rejectReason"
                                :value="rejectReason"
                              />
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card-text>

                    <v-spacer></v-spacer>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="black darken-1"
                        :disabled="loading"
                        flat
                        @click="closeReject()"
                        >Cancel</v-btn
                      >
                      <v-btn
                        depressed
                        color="error"
                        :disabled="loading"
                        @click="rejectAdvance()"
                        >Reject</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-form>
                <v-snackbar
                  :timeout="3000"
                  bottom
                  color="red darken-2"
                  v-model="x.error"
                  class="white--text"
                  v-if="x.error"
                  >{{ x.error }}</v-snackbar
                >
              </v-dialog>

              <v-snackbar
                :timeout="3000"
                color="red darken-2"
                v-model="error"
                class="white--text"
                v-if="error"
                >{{ error }}</v-snackbar
              >
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import _ from "lodash";
import { EventBus } from "../../../event-bus.js";
import Loading from "vue-loading-overlay";
import { generateAdvanceAccountsList } from "@/constants/datatable-headers.js";

import SuccessDialog from "@/components/Common/SuccessDialog";
import AdvancePaid from "@/components/Accounts/Popups/AdvancePaid";
import GenerateAdvance from "@/components/Pop-ups/GenerateAdvance";
import ProofOfDelivery from "@/components/Pop-ups/ProofOfDelivery";
import PartialAdvanceReadyToPay from "../Popups/PartialAdvanceReadyToPay.vue";
import ReadyToPayPopup from "@/components/Pop-ups/ReadyToPayPopup.vue";
import editAdvance from "@/components/Pop-ups/editAdvance";
import ErrorBox from "@/components/Common/ErrorBox";
import activityLogs from "../../Common/activityLogs.vue";
import "moment/locale/es"; // without this line it didn't work
moment.locale("en-in");

import moment from "moment";
import {
  StorageKeys,
  JobTypes,
  JobListTypes,
  CancellationType,
} from "../../../constants/constants";
import { async } from "q";
import PaginationButtons from "@/components/Pagination/Pagination-New";
export default {
  created() {
    this.customerStatus = this.$props.viewOnly;
    this.cancellationType = CancellationType;
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          this.workingCountry = key.value;
        }
      });
    }

    EventBus.$on("countryChanged", (val) => {
      if (this.$route.name == "documents") {
        this.$router.push(`/jobs`);
      }
      this.workingCountry = val.value;
      this.loading = true;
    });
    this.payload = {
      skip: "0",
      limit: "15",
      assignmentId: this.$props.assignId,
      workingCountry: this.workingCountry,
    };
    this.JobTypes = JobTypes;
    if (!navigator.onLine) {
      this.loading = false;
      this.x.error = "Please check your internet connection";
      setTimeout(() => {
        this.x.error = "";
      }, 3000);
      return;
    }
  },
  components: {
    PaginationButtons,
    SuccessDialog,
    ErrorBox,
    activityLogs,
    Loading,
    editAdvance,
    GenerateAdvance,
    ReadyToPayPopup,
    ProofOfDelivery,
    AdvancePaid,
    PartialAdvanceReadyToPay,
  },
  props: {
    loadingstop: Function,
    loader: Boolean,
    assignId: String,
    totalAdvanceAmountPaid: Number,
    additionalCharges: Number,

    viewOnly: Boolean,
    section: String,
    isVisible: Number,
  },

  data() {
    return {
      opeartionName: "",
      workingCountry: null,
      rejectDialog: false,
      singleExpand: true,
      showExpand: true,
      rejectReason: null,
      assignDetails: {},
      expanded: [],
      totalAdvancePaidArray: [],
      customerStatus: "",
      bankName: "",
      ownerType: "",
      advanceId: null,
      JobTypes: [],
      invoiceCompany: "",
      invoiceCity: "",
      totaldata: "",
      advanceAmount: null,
      totaladvancePaid: 0,
      advanceStatus: "",
      currency: "",
      cancellationType: [],
      cancellationReason: "",
      cancelType: "",
      amount: 0,
      status: "",
      jobType: "",
      jobId: "",
      assignID: "",
      isRejectTrue: false,
      paymentAmount: null,
      assignmentDetails: {},
      isMarkedAllRead: false,
      error: "",
      valid: true,
      lazy: false,
      cancelDialog: false,
      isCancelAll: null,
      cancelreason: "",
      loading: false,
      dialog: false,
      dialog2: false,
      assignedToTransporter: Boolean,
      processing: false,
      x: {
        message: "",
        loading: true,
        success: false,
        menu: false,

        error: null,
        missingField: null,
      },
      search: "",
      componentKey: 0,

      totalPages: "",
      isPurchaseInvoiceGenerated: Boolean,

      payload: {},

      headers: generateAdvanceAccountsList,
      items: [],
    };
  },
  watch: {},
  methods: {
    closeSubPartial() {
      console.log("-----------");
      this.showExpand = false;
    },
    getStatus(status) {
      switch (status) {
        case "Paid":
          return "Paid";
        case "Issued":
          return "Requested";
        case "Rejected":
          return "Rejected";
        case "Cancel":
          return "Cancelled";
        case "Ready":
          return "Ready To Pay";
        case "PartiallyPaid":
          return "Partial Paid";

        default:
          return "Unknown";
      }
    },
    refreshList() {
      this.$emit("assignment-list-refresh");
      this.componentKey = this.componentKey + 1;
      this.showExpand = false;
    },
    getBankName() {
      if (this.invoiceCity == "1" && this.invoiceCompany == "1") {
        this.bankName = "Alinma Bank - Dammam";
      } else if (this.invoiceCity == "2" && this.invoiceCompany == "1") {
        this.bankName = "Alinma Bank - Jeddah";
      } else if (this.invoiceCity == "3" && this.invoiceCompany == "1") {
        this.bankName = "Alinma Bank - Riyadh";
      } else if (this.invoiceCity == "1" && this.invoiceCompany == "2") {
        this.bankName = "Mashreq Bank PSJC";
      } else if (this.invoiceCity == "2" && this.invoiceCompany == "2") {
        this.bankName = "EMIRATES NBD Bank";
      }
      return this.bankName;
    },
    getList() {
      if (localStorage.getItem("workingCountries")) {
        this.workingCountries = JSON.parse(
          localStorage.getItem("workingCountries")
        );
        this.workingCountries.forEach((key, index) => {
          if (key.isSelected) {
            this.workingCountry = key.value;
          }
        });
      }

      this.dialog = true;
      this.payload = {
        skip: "0",
        limit: "15",
        assignmentId: this.$props.assignId,
        workingCountry: this.workingCountry,
      };
    },
    openReject(id, amount) {
      this.rejectDialog = true;
      this.advanceId = id;
      this.amount = amount;
    },
    async check(type, id) {
      this.rejectDialog = true;
      this.advanceId = id;
    },
    closeReject() {
      this.rejectDialog = false;

      this.rejectReason = "";
    },

    rejectAdvance() {
      this.loading = true;

      if (!this.rejectReason) {
        this.error = "Please enter rejection reason before moving ahead";
        setTimeout(() => {
          this.x.error = "";
        }, 3000);
        this.loading = false;
        return false;
      }

      const data = {
        assignmentId: this.$props.assignId,
        advanceId: this.advanceId,
        comment: this.rejectReason.trim(),
      };
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      this.axios.post(`${apiUrl}/dmsAdmin/rejectAdvance`, data, config).then(
        (response) => {
          const { data } = response.data;
          this.x.success = true;
          this.rejectDialog = false;
          this.x.message =
            "The advance for " +
            this.amount +
            " " +
            this.currency +
            " " +
            " has been rejected for " +
            this.assignDetails.assignmentId +
            " assignment id";

          this.rejectReason = "";
          this.loading = false;
        },
        (error) => {
          this.x.error = error.response.data.message;
          this.loading = false;
        }
      );
    },
    closeMessage() {
      this.x.success = false;
      this.componentKey += 1;
      this.showExpand = false;
      this.$emit("on-block");
      this.$emit("assignment-list-refresh");
    },
    getTime3(date) {
      return moment(date).format("ll LT");
    },
    getDate(date) {
      return moment(date).format("ll");
    },
    getJobType(type) {
      switch (type) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    closePopup() {
      console.log("showExpand");
      this.dialog = false;
      console.log(this.$props);

      this.expanded = [];
    },
    callback(res) {
      this.loading = false;
      this.isMarkedAllRead = false;
      this.$eventBus.$emit("data", res.assignmentDetails[0]);
      this.assignDetails = res.assignmentDetails[0];
      this.advanceAmount = res.assignmentDetails[0].advanceAmount;
      this.totaladvancePaid = res.assignmentDetails[0].totalAdvancePaidAmount;
      this.totaldata = res.assignmentDetails[0].totalAmount;
      this.ownerType = res.assignmentDetails[0].ownerType;
      this.invoiceCity = res.invoicingCity;
      this.assignmentDetails = res.assignmentDetails[0];
      this.assignedToTransporter =
        res.assignmentDetails[0].assignedToTransporter;

      this.invoiceCompany = res.invoicingCompany;
      this.$eventBus.$emit("off-load");
      this.paymentAmount = res.assignmentDetails[0].paymentAmount;
      this.currency = res.assignmentDetails[0].customerCurrency;
      this.advanceStatus = res.assignmentDetails[0].isGenerated;
      this.isPurchaseInvoiceGenerated =
        res.assignmentDetails[0].isPurchaseInvoiceGenerated;
      this.assignID = res.assignmentDetails[0].assignmentId;
      this.status = res.assignmentDetails[0].assignmentStatus;
      this.items = res.list;
      res.list.filter((x) => {
        this.totalAdvancePaidArray.push(x.advanceAmount);
      });
      this.getBankName();
      this.totalPages = Math.ceil(res.totalData / 15);
    },

    paginationPayload(event) {
      this.payload = event;
      this.loading = true;
    },
  },
};
</script>
<style lang="scss">
.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}

.table-header-item1 {
  font-size: 16px !important;
  color: orange;
  font-weight: bold;
}

td {
  padding: 0px 15px !important;
  font-size: 14px !important;
}

thead {
  background: #fbfbfb;
}
</style>

<style scoped>
.dashboard-style {
  color: grey;
  font-size: 16px;
  font-weight: 700;
}
.p-16 {
  padding: 8px !important;
}
.customer-actions {
  padding: 0;
  margin: auto 20px auto 0px;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.document {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  color: #666666;
}
.editCustomer {
  margin: auto 0;
}

.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.color-black {
  color: #000000 !important;
}
.cancel-ass-btn {
  text-decoration: none;
  color: #ffffff;

  border-radius: 5px;
  border: 2px solid red;
  padding: 2px 0px;
}
.reject-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: red;
  border-radius: 5px;
  border: 2px solid red;
  padding: 2px 0px;
}

.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.p-b-0 {
  padding-bottom: 0px !important ;
}
.p-t-0 {
  padding-top: 0px !important ;
}
.text-stylee {
  font-size: 20px;
}
</style>
