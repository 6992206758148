<template>
  <div class="log">
    <button
      @click.stop="dialog = true"
      title="View Customer Details"
      class="view-ass-btn"
    >
      View
    </button>

    <v-dialog v-model="dialog" max-width="50%">
      <v-form ref="form">
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title>View {{ title }} Details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="dialog = false">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-spacer class="address-pop">
            <v-card-text class="nospace">
              <table
                id="jobsTable"
                class="v-datatable v-table theme--light"
                hide-actions
              >
                <tr>
                  <td>
                    {{ headers.id }}
                  </td>
                  <td v-if="title === 'Driver'">
                    {{ detail.driverId || detail.transporterId }}
                  </td>
                  <td v-if="title === 'Transporter'">
                    {{ detail.driverId || detail.transporterId }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ headers.name }}
                  </td>
                  <td v-if="title === 'Driver'">
                    {{ detail.name }}
                  </td>
                  <td v-if="title === 'Transporter'">
                    {{ detail.transporterName }}
                  </td>
                </tr>
                <tr v-if="headers.email">
                  <td>
                    {{ headers.email }}
                  </td>
                  <td v-if="title === 'Driver'">
                    {{ detail.email || "N.A" }}
                  </td>
                  <td v-if="title === 'Transporter'">
                    {{ detail.email || "N.A" }}
                  </td>
                </tr>
                <tr v-if="headers.phone">
                  <td>
                    {{ headers.phone }}
                  </td>
                  <td v-if="title === 'Driver'">
                    {{ detail.countryCode + "-" + detail.phoneNo }}
                  </td>

                  <td v-if="title === 'Transporter'">
                    {{ detail.countryCode + "-" + detail.phoneNo }}
                  </td>
                </tr>
              </table>
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      x: {
        error: null,
      },
      items: [],
      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  props: {
    detail: Object,
    headers: Object,
    title: String,
  },
  created() {
    // this.getActivityLog();
  },

  methods: {},
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
</style>
