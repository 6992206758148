<template>
  <v-container fluid>
    <v-card raised>
      <loading :active.sync="loading" />
      <v-flex>
        <AccountsTab />
        <Dashboard v-if="id || date2" :dashboard="dashboard" />
        <CustomFilter
          v-on:customer-search="searchText"
          v-on:daterange-cust="dateRange"
          v-on:daterangenew-cust="dateRangenew"
          v-on:filters-type="TypeFilter"
          v-on:filter-type="Type"
          v-on:customer-Id="searchCustomer"
          :jobIds="jobIdsList"
          :selectedCustForInvoice="selectedCustomerDataInvoice"
          :count="countInvoice"
          v-on:customer-reset="resetCustomer"
          @customerInvoiceFailed="customerInvoiceFailed()"
        />
      </v-flex>
      <v-flex>
        <AccountList
          :searchText="search"
          :dashboardData="dashboardData"
          :dateRange="date"
          :dateRangenew="date2"
          :searchCustomer="id"
          :resetCustomer="reset"
          :loader="loading"
          :TypeFilter="filtertype"
          :Type="filter"
          :selectedCustInvoiceFailed="selectedCustInvoiceFailed"
          v-on:count="count1"
          v-on:jobList-Id="jobIds"
          v-on:selectedCustomerData="selectedCustomerData"
          v-on:on-load="startLoad"
          v-on:off-load="stopLoad"
        />
      </v-flex>
    </v-card>
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import Dashboard from "./Dashboard";
import AccountList from "./AccountList";
import CustomFilter from "./CustomFilter";
import { EventBus } from "../../event-bus.js";
import AccountsTab from "./AccountsTab";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import moment from "moment";

export default {
  mixins: [checkPermissionsMixin],
  created() {
    this.$eventBus.$on("close-job-profile", () => {
      this.dialog = false;
    });

    this.$eventBus.$on("on-load", () => {
      this.loading = true;
    });

    this.$eventBus.$on("off-load", () => {
      this.loading = false;
    });
  },
  mounted() {},
  components: {
    AccountsTab,
    AccountList,
    CustomFilter,
    Dashboard,
    Loading,
  },

  data: () => ({
    search: "",
    date: "",
    date2: null,
    countInvoice: 0,
    noTrue: true,
    operationName: "",
    dashboard: {
      onGoingData: 0,
      totalData: 0,
    },
    id: null,
    filtertype: "",
    filter: "",
    jobIdsList: [],
    selectedCustomerDataInvoice: null,
    selectedCustInvoiceFailed: null,
    showScreen: true,
    dialog: false,
    reset: false,
    loading: true,
  }),
  methods: {
    dashboardData(event) {
      this.dashboard.totalData = event.totalData;
      this.dashboard.onGoingData = event.onGoingData;

      this.loading = false;
    },
    startLoad() {
      this.loading = true;
    },
    stopLoad() {
      this.loading = false;
    },
    jobIds(event) {
      this.jobIdsList = event;
    },
    selectedCustomerData(event) {
      this.selectedCustomerDataInvoice = event;
    },
    count1(event) {
      this.countInvoice = event;
    },
    customerInvoiceFailed() {
      this.selectedCustInvoiceFailed = { ...this.selectedCustomerDataInvoice };
    },
    resetCustomer() {
      this.reset = true;
      this.$eventBus.$emit("refresh-account-list");
      this.search = "";
      this.countInvoice = 0;
      this.jobIdsList = [];
      this.id = null;
      this.date = "";
      this.date2 = null;
    },
    searchText(event) {
      this.search = event;
      this.reset = false;
    },
    searchCustomer(event) {
      this.id = event;
      this.reset = false;
    },
    dateRange(event) {
      this.date = event;
      this.reset = false;
    },
    dateRangenew(event) {
      this.date2 = event;
      this.reset = false;
    },
    TypeFilter(event) {
      this.filtertype = event;
      this.reset = false;
    },
    Type(event) {
      this.filter = event;
      this.reset = false;
    },
  },
};
</script>

<style scoped>
.errorbox {
  padding: 20px;
  text-align: center;
  color: red !important;
  font-size: 20px;
  padding: 10px 10px 10px 10px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
</style>
