<template>
  <div>
    <button outline class="tra-ass-btn" type="button" @click.stop="check2()">
      <v-icon color="grey darken-1" flat prepend-icon class="customer-actions"
        >create
      </v-icon>
    </button>

    <v-dialog
      v-model="dialog"
      max-width="50%"
      persistent
      class="layout__spacing"
    >
      <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
        height="100%"
        onSubmit="return false;"
        class="layout__spacing"
      >
        <v-card>
          <v-card-title
            style="
              background-color: #f5f5f5;
              padding-bottom: 5px;
              padding-top: 5px;
            "
          >
            <span class="headline pl-4">Edit Invoice</span>
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="close">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-card-title>

          <v-spacer class="">
            <v-layout class="pb-2 pt-3 pl-5">
              <v-flex xs3>
                <div class="sub-heading">Total Receipt Amount</div>

                <span style="color: black" class="heading"
                  >{{ totalReceiptAmount.toFixed(2) }} {{ currency }}</span
                >
              </v-flex>
              <v-flex xs3>
                <div class="sub-heading">Total Invoice Amount</div>

                <span style="color: black" class="heading"
                  >{{ totalInvoiceAmount.toFixed(2) }} {{ currency }}</span
                >
              </v-flex>
              <v-flex xs3>
                <div class="sub-heading">Total Pending Amount</div>

                <span style="color: black" class="heading"
                  >{{ pendingAmount.toFixed(2) }} {{ currency }}</span
                >
              </v-flex>
              <v-flex xs3>
                <div class="sub-heading">Sales Invoice Type</div>

                <span style="color: black" class="heading">
                  {{
                    $props.invoiceDetails.isInvoiceLevelAssignment
                      ? "Assignment Level"
                      : "Job Level"
                  }}
                </span>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex xs5 class="pt-2" pl-5 style="word-break: break-all">
                <div class="sub-heading">Invoice Number</div>
                <span style="color: black" class="heading" pt-1
                  >{{ invoiceNumber }}
                </span>
              </v-flex>
              <v-flex xs6 class="pt-2" pl-3 style="word-break: break-all">
                <div class="sub-heading">Enter Amount To Be Adjusted</div>
                <v-text-field
                  v-model="adjustedAmount"
                  class="mtn"
                  :value="adjustedAmount"
                  placeholder="Enter Amount To Be Adjusted"
                  type="number"
                >
                </v-text-field>
              </v-flex>
            </v-layout>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                :disabled="processing"
                flat
                @click="close()"
                >Close</v-btn
              >
              <v-btn
                color="white darken-1"
                style="background: orange !important"
                :disabled="processing"
                flat
                @click="adjustAmount()"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-spacer>
        </v-card>
      </v-form>

      <v-dialog v-model="confirmationDialog" max-width="23%" persistent>
        <v-card>
          <v-spacer class="spacerclass">
            <v-card-text style="padding-top: 0px !important">
              <v-layout class="pt-4" style="text-align: center">
                <v-flex pr-2 pb-3>
                  <v-icon x-large color="orange">info</v-icon></v-flex
                >
              </v-layout>
              <v-layout>
                <v-flex pb-3 style="text-align: center">
                  {{ confirmationData }}
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex style="text-align: center">
                  <v-btn
                    color="white darken-1"
                    style="
                      background: orange !important;
                      min-width: 66px !important;
                    "
                    flat
                    @click.prevent="confirm()"
                    >OK</v-btn
                  ></v-flex
                >
              </v-layout>
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-dialog>

      <v-snackbar
        :timeout="3000"
        bottom
        color="red darken-2"
        v-model="x.error"
        class="white--text"
        v-if="x.error"
        >{{ x.error }}</v-snackbar
      >
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { StorageKeys, Banks } from "../../../../constants/constants";
import { editMarkedOff } from "../../../../constants/api-urls";
import { recieveAdjust } from "@/constants/api-urls.js";

export default {
  components: {},

  data() {
    return {
      operationName: "",
      customers: [],

      invoiceNumber: "",
      confirmationDialog: false,
      options: {
        color: "#ffc04c",
        width: 500,
        zIndex: 200,
      },
      adjustedAmount: 0,
      pendingAmount: 0,
      totalInvoiceAmount: 0,
      totalReceiptAmount: 0,
      totalRemainAmount: 0,
      currency: "",

      valid: true,
      lazy: false,

      processing: false,
      confirmationData: "",
      dialog: false,

      loading: false,

      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "Enter valid value.",
        amount: (v) =>
          /^\d*(\.\d+)?$/.test(v) || "Enter a valid amount. E.g 10 or 10.20",
        number: (v) => /^[0-9]*$/.test(v) || "Please enter valid amount",
      },
      x: {
        error: null,
      },
    };
  },

  props: {
    invoiceDetails: Object,
    custId: String,
    entryId: String,
  },
  created() {
    this.customerStatus = localStorage.getItem("cust-status");
    // this.totalPayment = this.$props.totalAmount;

    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;
    }

    // this.getActivityLog();
  },
  methods: {
    check2() {
      this.dialog = true;
      // this.fetchInvoice(this.workingCountry);

      this.invoiceNumber = this.$props.invoiceDetails.invoiceNumber;
      this.pendingAmount = this.$props.invoiceDetails.balanceAmount;
      this.totalInvoiceAmount = this.$props.invoiceDetails.amount;
      this.totalReceiptAmount = this.$props.invoiceDetails.receiveAmount;
      this.currency = this.$props.invoiceDetails.currency;
      this.invoiceNumber = this.$props.invoiceDetails.invoiceNumber;
      this.adjustedAmount = parseFloat(
        this.$props.invoiceDetails.adjustedAmount
      ).toFixed(2);
      this.totalRemainAmount =
        this.$props.invoiceDetails.adjustedAmount +
        this.$props.invoiceDetails.balanceAmount;
    },

    adjustAmount() {
    
     
      
      

      this.processing = true;
      let url = editMarkedOff;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        entry_id: this.$props.entryId,
        invoiceNumber: this.invoiceNumber,
        _id: this.$props.invoiceDetails._id,
        adjustedAmount: this.adjustedAmount,
        customer_id: this.$props.custId,
        invoiceLevel: this.$props.invoiceDetails.isInvoiceLevelAssignment
          ? "ASSIGNMENT"
          : "JOB",
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {

          if (
          this.totalRemainAmount -
            this.adjustedAmount +
            this.$props.invoiceDetails.lossToTrukkinAmount <
            1 &&
          this.totalRemainAmount -
            this.adjustedAmount +
            this.$props.invoiceDetails.lossToTrukkinAmount >
            0 &&
          this.totalRemainAmount - this.adjustedAmount > 0 &&
          this.totalRemainAmount - this.adjustedAmount < 1
        ) {
          console.log("--updated-loss");
          this.confirmationDialog = true;
          this.confirmationData = ` Remaining amount of ${(
            this.totalRemainAmount -
            this.adjustedAmount +
            this.$props.invoiceDetails.lossToTrukkinAmount
          ).toFixed(2)} for  ${
            this.$props.invoiceDetails.invoiceNumber
          } will be marked automatically as Loss To Trukkin.`;
        }
          this.dialog = false;
          this.processing = false;


        },
        (error) => {
          this.processing = false;
          this.x.error = error.response.data.message;
        }
      );
    },
    confirm() {
      this.confirmationDialog = false;
    },

    fetchInvoice(val) {
      this.customers = [];
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.t = config.headers.authorization;
      let body = {
        workingCountry: val,
        edit: true,
        invoiceLevel: this.$props.invoiceDetails.isInvoiceLevelAssignment
          ? "ASSIGNMENT"
          : "JOB",

        customerId: this.$props.custId,
      };
      this.axios
        .post(
          `${this.constants.apiUrl}/customerPayment/listInvoice`,
          body,
          config
        )
        .then((response) => {
          const { data = null } = response.data;

          data.forEach((element) => {
            this.customers.push({
              text: element.invoiceNumber,
              value: element.outStandingAmount,
              pendingAmount: element.outStandingAmount,
              totalSalesAmount: element.totalSalesAmount,
            });
          });
        });
    },

    setCustomerValues(val) {
      var data = this.customers.filter((v) => {
        if (v.text === val) return v;
      });

      this.adjustedAmount = data[0].value;
      this.pendingAmount = data[0].pendingAmount;
      this.totalInvoiceAmount = data[0].totalSalesAmount;
    },

    check() {
      this.fetchInvoice(this.workingCountry);
      this.dialog = true;
    },

    //close popup
    close() {
      this.dialog = false;
      this.shipmentDocument = [
        {
          i: 0,
          invoiceNumber: null,
          adjustedAmount: 0,
        },
      ];
      // this.$refs.form.resetValidation();
    },
  },
};
</script>
<style lang="scss" scoped>
.layout__spacing .v-input__slot {
  min-height: auto !important;
}
.layout__spacing .v-text-field__slot input {
  margin-top: 0px !important;
}
</style>
<style scoped>
.spacerclassAssign {
  overflow-y: scroll !important;
  max-height: calc(104vh - 176px) !important;
}
.tra-ass-btn {
  text-decoration: none;
  margin: 7px !important;
  color: white;

  padding: 6px 6px;
}
.heading__span {
  font-size: 20px !important;
  line-height: 32px !important;

  font-weight: bold;
}
.span__padding {
  padding: 10px 10px 10px 16px;
}
.dashboard-style {
  color: grey;
  font-size: 16px;
  font-weight: 700;
}
.fonttime {
  font-size: 12px !important;
}

.messagebtn {
  margin: 0 auto;
}
.label__position {
  position: relative;
  top: 5px;
}
.mtn {
  margin-top: -10px !important;
}
.title-layout-size {
  padding: 0px 16px !important;
}
.title {
  font-size: 18px;
  font-weight: 500;
}
.list {
  display: inline-block;
  position: relative;
}
</style>
