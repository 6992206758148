<template>
  <div class="log">
    <v-btn
      style="width: 30px; height: 30px"
      outline
      small
      fab
      @click="check($event)"
      v-if="
        !priceData.isCustomerReceiptInitiated &&
        !priceData.isAssignmentCustomerReceiptInitiated
      "
      :disabled="processing"
      color="orange"
    >
      <v-icon>edit </v-icon>
    </v-btn>

    <v-dialog v-model="dialog" max-width="40%">
      <v-form ref="form">
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title>Customer Price </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="close()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer class="spacerClass">
            <v-card-text class="nospace">
              <v-stepper v-model="e1" class="no-transition">
                <v-stepper-header class="SubTypeTab">
                  <template v-for="n in steps" class="bg-clr-tab">
                    <v-stepper-step
                      :complete="e1 == n"
                      :key="`${n}-step`"
                      :step="n"
                      :editable="true"
                      @click="test()"
                      color="orange darken-1"
                      complete-icon
                      class="bg-clr-tab"
                    >
                      <span>
                        {{
                          assignments.length
                            ? getJobType(jobTypeArray[n - 1])
                            : ""
                        }}
                        {{ n }}
                      </span>
                    </v-stepper-step>
                  </template>
                </v-stepper-header>
                <v-stepper-items>
                  <v-stepper-content
                    v-for="n in steps"
                    :key="`${n}-content`"
                    :step="n"
                  >
                    <v-card-text class="nospace">
                      <v-layout row>
                        <v-flex xs5>
                          <v-text-field
                            label="Price "
                            v-model="x.subJobPrice[n - 1]"
                            type="number"
                            :rules="[rules.required]"
                            min="0"
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs1></v-flex>
                        <v-flex xs5 pt-3>
                          <v-text-field
                            label="Comments"
                            class="pt-0 currencyTitle"
                            v-model="x.comments[n - 1]"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        flat
                        :disabled="processing"
                        @click="dialog = false"
                        >Close</v-btn
                      >
                      <v-btn
                        color="orange darken-1"
                        flat
                        :disabled="processing"
                        @click="addCharges"
                        >Submit</v-btn
                      >
                    </v-card-actions>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-card-text>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      bottom
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
  </div>
</template>
<script>
import moment from "moment";
import ErrorBox from "@/components/Common/ErrorBox";
import { StorageKeys, TruckTypes } from "../../../constants/constants";
export default {
  data() {
    return {
      subJobArray: [],
      operationName: "",
      x: {
        error: null,
        customerCurrency: [],
        subJobPrice: [],
        comments: [],
        perAssignmentPrice: [],
      },
      assignments: [],
      isAssignDriver: [],
      jobPrice: "",
      comments: "",
      haserror: false,
      tabArray: [],
      e1: 1,
      steps: 1,
      jobType: null,
      error: null,
      items: [],
      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "Enter valid value.",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        phoneNumber: (v) =>
          /^[0-9]{5,15}$/.test(v) || "Please Enter Valid Phone No.",
      },
    };
  },
  props: {
    priceData: Object,
  },
  created() {
    this.jobTypeArray = [];
  },
  components: {
    ErrorBox,
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
  methods: {
    test() {},
    async check(e) {
      e.stopPropagation();
      this.processing = true;
      this.operationName = "edit-customer-price-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.processing = false;
        this.getAssignmentList(this.priceData.jobId);
      } else {
        this.processing = false;
        return;
      }
    },
    close() {
      this.processing = false;
      this.dialog = false;
      this.error = null;
    },
    getJobType(type) {
      switch (type) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    getAssignmentList(jobId) {
      this.dialog = true;
      this.processing = true;
      let url = "/dmsAdmin/jobDetailsForAccount";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        jobId: jobId.toString(),
      };
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          const data = response.data.data;
          const status = response.status;
          this.processing = false;
          const { jobInfo = null, assignmentList = [] } = data;
          var size = assignmentList.length;
          this.steps = size;
          this.assignments = [];
          this.jobTypeArray = [];
          this.subJobArray = [];
          for (var i = 0; i < size; i++) {
            this.assignments[i] = assignmentList[i].assignmentListing;
            this.jobTypeArray[i] = assignmentList[i].jobType || "1";
            this.subJobArray[i] = assignmentList[i];
            this.x.subJobPrice[i] = this.subJobArray[i].subJobPrice;

            var prices = {
              driverCost: null,
              customerCost: null,
              trukkinCost: null,
              AdditionalCost: null,
            };
          }
        },
        (error) => {
          this.error = "Failed to Fetch Data";
          this.processing = false;
        }
      );
    },

    async addCharges() {
      this.operationName = "edit-customer-price-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.processing = true;
        var size = this.assignments.length;
        let url = "/dmsAdmin/editJobPrice";
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {};
        let subJobAmount = [];
        this.x.subJobPrice.forEach((e) => {
          subJobAmount.push(parseFloat(e));
        });

        for (var i = 0; i < size; i++) {
          if (this.x.subJobPrice[this.e1 - 1] == null) {
            this.processing = false;
            this.error = "Please enter price.";
            return;
          }
          if (this.x.subJobPrice[this.e1 - 1] == 0) {
            this.processing = false;
            this.error = "Please enter valid price.";
            return;
          }
          if (this.x.subJobPrice[this.e1 - 1] >= 1000000000000) {
            this.processing = false;
            this.error = "Please enter amount less than 1000000000000";
            return;
          }

          if (!this.x.subJobPrice[this.e1 - 1]) {
            this.processing = false;
            this.error = "Please enter valid price.";
            return;
          }
          // if (this.x.subJobPrice[this.e1 - 1]) {
          //   var value = /^[0-9]*$/.test(this.x.subJobPrice[this.e1 - 1]);
          //   if (!value) {
          //     this.processing = false;
          //     this.error = "Please enter valid price.";
          //     return;
          //   }
          // }

          if (
            !this.x.customerCurrency ||
            this.x.customerCurrency == undefined
          ) {
            this.processing = false;
            this.error = "Please select the currency.";
            return;
          }
          if (this.subJobArray[this.e1 - 1].isDriverJob) {
            if (
              this.subJobArray[this.e1 - 1] <
              this.subJobArray[this.e1 - 1].amountForTransporter
            ) {
              this.processing = false;
              this.x.error =
                "Job price cannot be less than bid price(" +
                this.subJobArray[this.e1 - 1].amountForTransporter +
                ").";

              return;
            }
          }

          if (this.subJobArray[this.e1 - 1].isTransporterJob) {
            if (
              this.subJobArray[this.e1 - 1] <
              this.subJobArray[this.e1 - 1].amountForTransporter
            ) {
              this.processing = false;
              this.x.error =
                "Job price cannot be less than bid price(" +
                this.subJobArray[this.e1 - 1].amountForTransporter +
                ").";
              this.haserror = true;
              return;
            }
          }

          this.processing = true;
          //  console.log(Number(this.subJobArray[this.e1 - 1].subJobPrice).toFixed(2))
          // console.log(Number(this.subJobArray[this.e1 - 1].subJobPrice).toFixed(2))

          var jobPrice =
            parseFloat(this.priceData.jobPrice) -
            parseFloat(this.subJobArray[this.e1 - 1].subJobPrice) +
            parseFloat(this.x.subJobPrice[this.e1 - 1]);
          // console.log(this.subJobArray[this.e1 - 1].subJobPrice)
          // console.log(this.x.subJobPrice[this.e1 - 1])
          console.log(jobPrice);

          var perAssignmentPrice =
            this.x.subJobPrice[this.e1 - 1] /
            this.subJobArray[this.e1 - 1].assignNotCancelledCount;
          body = {
            jobPrice: Number(jobPrice).toFixed(2).toString(),
            jobId: this.$props.priceData.jobId.toString(),
            jobCurrency: this.$props.priceData.customerCurrency,
            subJobPrice: Number(this.x.subJobPrice[this.e1 - 1])
              .toFixed(2)
              .toString(),
            tabOrder: this.subJobArray[this.e1 - 1].tabOrder
              ? this.subJobArray[this.e1 - 1].tabOrder.toString()
              : "1",
            jobType: this.subJobArray[this.e1 - 1].tabOrder
              ? this.subJobArray[this.e1 - 1].jobType.toString()
              : "1",
            perAssignmentPrice: perAssignmentPrice.toFixed(2).toString(),
          };
          if (this.x.comments[this.e1 - 1])
            body.comments = this.x.comments[this.e1 - 1];
        }

        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            var amount = this.amount;
            this.amount = null;
            this.comments = null;
            this.processing = false;
            this.close();
            this.$emit("account-list-refresh");
            this.error = null;
          },
          (error) => {
            this.processing = false;
            this.dialog = true;
            this.error = error.response.data.message;
          }
        );
      } else {
        this.processing = false;
        return;
      }
    },
  },
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.spacerClass {
  max-height: 400px;
}
.tabsPadding {
  padding: 9px !important;
}
.no-transition .stepper__content {
  transition: none;
}
</style>
