<template>
  <div>
    <button
      type="button"
      color="orange"
      outline
      class="pay-ass-btn green-button"
      v-if="
        details.assignmentStatus !== 4 &&
        details.assignmentStatus !== 7 &&
        details.isReadyForFinalPayment &&
        (details.customerCurrency === 'PKR'
          ? true
          : details.isPurchaseInvoiceGenerated) &&
        PendingAmount < 0
      "
      @click="check()"
    >
      Adjust
    </button>

    <v-dialog v-model="dialog" persistent max-width="900">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-toolbar dark style="background-color: #dcdcdc; color: black" flat>
            <v-toolbar-title>
              <span class="headline"
                >Adjust Outstanding Payment(Assignment ID
                {{ details.assignmentId }})
              </span></v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn class="mx-2" fab small :disabled="loading" @click="close()">
              <v-icon dark> close </v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="loading"
          ></v-progress-linear>

          <v-card-text>
            <v-layout>
              <v-flex xs12 md12>
                <v-radio-group
                  class="mt-0 pt-0"
                  v-model="paymentMode"
                  row
                  :mandatory="true"
                  :rules="[rules.required]"
                  @change="emptyPopup()"
                >
                  <div>
                    <div class>
                      <v-layout>
                        <v-radio
                          label="Collected cash from driver"
                          value="Cash"
                          color="orange"
                          :disabled="section === 'Assignment'"
                          class="col-md-6"
                        ></v-radio>

                        <v-radio
                          label="Adjust manually with assignment"
                          value="Manually"
                          :disabled="section === 'Accounts'"
                          color="orange"
                          class="col-md-6"
                        ></v-radio>
                        <v-radio
                          label="Loss to trukkin"
                          value="Lost"
                          :disabled="section === 'Assignment'"
                          color="orange"
                          class="col-md-6"
                        ></v-radio>
                      </v-layout>
                    </div>
                  </div>
                </v-radio-group>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs5>
                <span class="subheading caption" v-if="paymentMode === 'Lost'"
                  >Total amount to be lost</span
                ><span class="subheading caption" v-else
                  >Total amount to be adjusted</span
                >
                <br />{{
                  (
                    details.paymentAmount +
                    details.totalAdditionalAmount -
                    (details.totalAdvancePaidAmount -
                      this.adjustAmountAssignment) -
                    details.amountPaidToDriver
                  ).toFixed(2)
                }}
                {{ details.customerCurrency }}</v-flex
              >
              <v-flex xs6 pl-5 v-if="this.paymentMode === 'Cash'">
                <span class="subheading caption"
                  >Enter amount to be adjusted</span
                >
                <v-text-field
                  v-model="amountCollected"
                  label="Enter amount to be adjusted"
                  type="number"
                  class="pt-0 currencyTitle"
                  :value="amountCollected"
                  :suffix="details.customerCurrency"
                  single-line
                  :rules="[rules.required, rules.number]"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-layout
                v-if="paymentMode === 'Manually' && this.AssignmentId.length"
              >
                <v-flex xs3>
                  <span class="subheading caption">Select assignment</span>

                  <v-select
                    ref="ids"
                    :items="AssignmentId"
                    label="Select Assignment"
                    v-model="assignmentId"
                    class="pt-0 currencyTitle"
                    return-object
                    single-line
                    :rules="[rules.required]"
                  ></v-select>
                </v-flex>
                <v-flex xs4 pl-2>
                  <span class="subheading caption"
                    >Enter Amount To Be Adjusted</span
                  >
                  <v-text-field
                    v-model="amount"
                    label="Enter Amount To Be Adjusted"
                    class="pt-0 currencyTitle"
                    type="number"
                    :value="amount"
                    :suffix="details.customerCurrency"
                    single-line
                    :rules="[rules.required, rules.number]"
                  ></v-text-field>
                </v-flex>
                <v-flex xs3 pl-2>
                  <span class="subheading caption">Description</span>
                  <v-text-field
                    class="pt-0 currencyTitle label__texxt"
                    label="Description"
                    :rules="[rules.required, rules.noWhiteSpace]"
                    single-line
                    v-model="descrpition"
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 class="pt-2" pl-2>
                  <div class="upload-btn-wrapper">
                    <button
                      style="border: 2px solid green; color: green"
                      @click="add"
                      type="button"
                      class="btn"
                    >
                      Add
                    </button>
                  </div>
                </v-flex>
              </v-layout>
            </v-layout>
            <div v-if="this.payment.length">
              <v-layout
                v-for="(item, index) in payment"
                :key="item.id"
                class="py-3 border___Bottom"
              >
                <v-flex xs3>
                  <span class="subheading caption">Assignment ID</span><br />
                  <v-list-tile-sub-title class="text--primary">
                    {{ item.id }}</v-list-tile-sub-title
                  ></v-flex
                >
                <v-flex xs4 pl-2>
                  <span class="subheading caption"
                    >Actual amount collected from the driver</span
                  ><br />
                  <v-list-tile-sub-title class="text--primary"
                    >{{ item.amount }}
                    {{ details.customerCurrency }}</v-list-tile-sub-title
                  ></v-flex
                >
                <v-flex xs3>
                  <span class="subheading caption">Description</span><br />
                  <v-list-tile-sub-title class="text--primary">{{
                    item.descrpition
                  }}</v-list-tile-sub-title>
                </v-flex>
                <v-flex xs2>
                  <div class="upload-btn-wrapper">
                    <button
                      style="border: 2px solid red; color: red"
                      @click="remove(index)"
                      type="button"
                      class="btn"
                    >
                      Remove
                    </button>
                  </div>
                </v-flex>
              </v-layout>
            </div>

            <v-layout v-if="this.paymentMode === 'Cash'">
              <v-flex xs5>
                <span class="subheading caption"
                  >Select actual date of collection</span
                >
                <v-menu
                  ref="FilterDateBool"
                  lazy
                  v-model="FilterDateBool"
                  class="date__icon"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  full-width
                  :nudge-right="40"
                  min-width="290px"
                  :return-value.sync="startDate"
                >
                  <v-text-field
                    slot="activator"
                    :label="'Select actual date of collection '"
                    v-model="startDate"
                    prepend-icon="event"
                    readonly
                    single-line
                    class="pt-0 currencyTitle"
                    :rules="[rules.required]"
                  ></v-text-field>
                  <v-date-picker
                    v-model="startDate"
                    :max="dateToday"
                    class="minHeight"
                    @change="$refs.FilterDateBool.save(startDate)"
                    no-title
                    scrollable
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs5 pl-5 style="position: relative">
                <span class="subheading caption"
                  >Select actual time of collection</span
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="clock_account_pay"
                  height="20"
                  viewBox="0 0 24 24"
                  width="20"
                >
                  <path
                    d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
                  />
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
                </svg>
                <vue-timepicker
                  class="fontSize"
                  :minute-interval="30"
                  v-model="pickupTime"
                  input-width="28em"
                  placeholder="Select actual time of collection"
                  :label="'Select actual time of collection'"
                  close-on-complete
                  :rules="[rules.required]"
                ></vue-timepicker>
              </v-flex>
            </v-layout>

            <v-layout v-if="this.paymentMode != 'Manually'">
              <v-flex xs11>
                <span class="subheading caption">Description</span>

                <v-flex xs12>
                  <v-text-field
                    class="pt-0 currencyTitle label__texxt"
                    label="Description"
                    :rules="[rules.required, rules.noWhiteSpace]"
                    single-line
                    v-model="comments"
                  ></v-text-field>
                </v-flex>
              </v-flex>
            </v-layout>
            <v-layout v-if="!this.AssignmentId.length && isError">
              <v-flex class="adjust_error"
                >Outstanding payment can't be adjusted as driver doesn't have
                any ongoing assignment to adjust</v-flex
              >
            </v-layout>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-layout style="justify-content: end">
              <v-btn
                color="red darken-1"
                :disabled="loading"
                flat
                @click="close()"
                >Close</v-btn
              >

              <v-btn
                color="orange darken-1"
                flat
                :disabled="
                  loading ||
                  (!this.assignmentIdList.length && section === 'Assignment')
                "
                @click="paymentMode === 'Manually' ? adjustManually() : pay()"
              >
                Adjust</v-btn
              >
            </v-layout>
          </v-card-actions>
        </v-card>
        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="error"
          class="white--text"
          v-if="error"
          >{{ error }}</v-snackbar
        >
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { StorageKeys, CancellationType } from "../../../constants/constants";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
import moment from "moment";
export default {
  props: {
    message: String,
    confirmationTitle: String,
    cancelBtn: String,
    confirmBtn: String,
    assignStatus: Number,
    section: String,
    details: Object,
    PendingAmount: Number,

    driverCreditDuration: Number,
  },
  components: {
    VueTimepicker,
  },
  data: () => ({
    loading: false,
    dateToday: moment(new Date()).format("YYYY-MM-DD"),
    paymentMode: "",
    isError: false,
    adjustAmountAssignment: 0,
    FilterDateBool: false,
    AssignmentId: [],
    assignmentId: null,
    assignmentIdList: [],
    descrpition: "",
    amount: null,
    payment: [],
    startDate: "",
    adjustAmount: null,
    pickupTime: {
      HH: "",
      MM: "",
    },
    startDate: "",
    ids: "",
    valid: true,
    lazy: false,
    amountCollected: null,
    cancellationType: CancellationType,
    rules: {
      required: (value) => !!value || "This field is required.",
      requiredObject: (v) =>
        v.hasOwnProperty("value") || "This field is required.",

      number: (value) => value > 0 || "Enter amount greater than zero.",
      requiredLength: (value) => !!value.length || "This field is required.",
      noWhiteSpace: (v) =>
        (v != null && v.trim().length > 0) || "This field is required.",
    },
    cancellationReason: "",
    cancelType: { text: "Other’s", value: "Other’s" },
    operationName: "",
    dialog: false,
    comments: "",
    error: null,
  }),
  created() {},
  methods: {
    assignmentList() {
      this.loading = true;
      this.isError = false;
      const data = {
        driverId: this.details.driverData.driverId.toString(),
        assignmentId: this.details._id,
      };

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      const { apiUrl } = this.constants;
      this.axios
        .post(`${apiUrl}/dmsAdmin/assignmentsToBeAdjusted`, data, config)
        .then(
          (response) => {
            response.data.data.forEach((e) => {
              this.AssignmentId.push({
                value: e._id,
                text: e.assignmentId,
              });
              this.assignmentIdList.push({
                value: e._id,
                text: e.assignmentId,
              });
            });
            setTimeout(() => {
              if (!this.AssignmentId.length) {
                this.isError = true;
              } else {
                this.isError = false;
              }
            }, 1000);

            this.loading = false;
          },

          (error) => {
            this.error = error.response.data.message;
            this.loading = false;
          }
        );
    },
    check() {
      if (
        this.$props.details.assetId &&
        this.$props.details.imeiInstallStatus &&
        this.$props.details.imeiInstallStatus != "collected"
      ) {
        this.dialog = false;
        this.error = "Kindly collect the gps device";
        this.$emit("close", true);
      } else if (
        this.$props.details.assetId &&
        !this.$props.details.imeiInstallStatus
      ) {
        this.dialog = false;
        this.error = "Kindly collect the gps device";
        this.$emit("close", true);
      } else {
        this.dialog = true;
        this.adjustAmountAssignment =
          this.details.amountAdjustedFromThisAssignment || 0;
        this.adjustAmount = Number(this.PendingAmount);

        if (this.$props.section === "Assignment") {
          this.assignmentList();
          this.paymentMode = "Manually";
        }
        if (this.section === "Accounts") {
          this.paymentMode = "Cash";
        } else {
          this.paymentMode = "Manually";
        }
        this.dialog = true;
      }
      // this.dialog = true;
    },
    emptyPopup() {
      this.comments = "";
      this.$refs.form.resetValidation();
      this.amountCollected = null;
      if (this.paymentMode === "Cash") {
        this.pickupTime = { HH: "", MM: "" };
        this.startDate = "";
      }
    },
    close() {
      this.dialog = false;
      this.comments = "";
      this.loading = false;
      this.adjustAmountAssignment = 0;

      this.$refs.form.resetValidation();
      if (this.paymentMode === "Manually") {
        this.amountCollected = null;
        this.amount = null;
        this.assignmentId = null;
        this.AssignmentId = [];
        this.descrpition = "";
        this.payment = [];
      }
      if (this.paymentMode === "Cash") {
        this.amountCollected = null;
        this.pickupTime = { HH: "", MM: "" };
        this.startDate = "";
      }
    },
    refreshList(i, selectedId) {
      for (let j = 0; j < this.payment.length; j++) {
        if (j != i) {
          var index = this.payment[j].assignmentList.findIndex(function (o) {
            return o.value === selectedId;
          });
          this.payment[j].assignmentList.splice(index, 1);
        }
      }
    },
    add() {
      if (this.$refs.form.validate()) {
        var collectedAmount = 0;
        if (this.payment.length >= 1) {
          for (let i = 0; i < this.payment.length; i++) {
            collectedAmount += Number(this.payment[i].amount);
          }

          if (
            Number(collectedAmount) + Number(this.amount) >
            Math.abs(this.adjustAmount)
          ) {
            this.error = "Amount can't be greater than to be collected amount";
            return;
          }
        } else {
          if (this.amount > Math.abs(this.adjustAmount)) {
            this.error = "Amount can't be greater than to be collected amount";
            return;
          }
        }

        this.payment.push({
          assignmentId: this.assignmentId.value,
          id: this.assignmentId.text,
          amount: this.amount,
          descrpition: this.descrpition,
        });

        let id = "";
        id = this.assignmentId.value;
        this.assignmentId = "";
        var index = this.AssignmentId.findIndex(function (o) {
          return o.value === id;
        });
        this.AssignmentId.splice(index, 1);
        this.descrpition = null;
        this.amount = null;
        this.$refs.form.resetValidation();
      }
    },
    remove(i) {
      this.AssignmentId.push({
        text: this.payment[i].id,
        value: this.payment[i].assignmentId,
      });
      let el = this.payment;
      el.splice(i, 1);
      this.payment = el;
    },

    checkTime() {
      let re = /^\d{1,2}:\d{2}([ap]m)?$/;
      var time = this.pickupTime.HH + ":" + this.pickupTime.MM;
      if (time != "" && !time.match(re)) {
        this.processing = false;
        this.error = "Please select the valid time before moving ahead!";
        time.focus();
        return false;
      }
    },
    adjustManually() {
      this.loading = true;
      if (!this.payment.length) {
        if (!this.$refs.form.validate()) {
          this.loading = false;
          return;
        }
      }

      if (this.paymentMode === "Manually") {
        if (
          !this.payment.length &&
          Number(this.amount) > Math.abs(this.adjustAmount)
        ) {
          this.loading = false;

          this.error = "Amount can't be greater than adjust amount";
          return;
        }
      }

      delete this.axios.defaults.headers.common["token"];
      let url = "/dmsAdmin/adjustAssignmentPayment";
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignmentId: this.details._id,
      };
      if (this.paymentMode === "Manually") {
        body.adjustedVia = "Assignment";

        let adjust = [];

        if (this.payment.length) {
          adjust = this.payment.map(({ assignmentId, amount, descrpition }) => {
            return { assignmentId, amount, descrpition };
          });

          body.assignmentIds = adjust;
        } else {
          let arr = [];
          arr.push({
            assignmentId: this.assignmentId.value,
            amount: this.amount,
            descrpition: this.descrpition,
          });
          body.assignmentIds = arr;
        }
      }
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.close();
          this.$emit("refresh-list");
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
    pay() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.paymentMode === "Cash") {
          if (Math.abs(this.adjustAmount) < Number(this.amountCollected)) {
            this.loading = false;
            this.error = "Amount can't be greater than adjust amount";
            return;
          }

          if (!this.pickupTime) {
            this.loading = false;
            this.error = "Please select time before moving ahead!";
            return;
          }
          if (this.pickupTime) {
            this.checkTime();
          }
        }
        delete this.axios.defaults.headers.common["token"];
        let url = "/dmsAdmin/adjustAssignmentPayment";
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = { assignmentId: this.details._id };
        if (this.paymentMode === "Cash") {
          body.adjustedVia = "Return";
          body.amount = this.amountCollected;
          body.adjustedTime = this.pickupTime.HH + ":" + this.pickupTime.MM;
          body.adjustedDate = this.startDate;
          body.comment = this.comments.trim();
        } else if (this.paymentMode === "Lost") {
          body.adjustedVia = "Loss";
          body.amount = this.adjustAmount.toString();
          body.comment = this.comments.trim();
        }

        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.close();
            this.$emit("refresh-list");
          },
          (error) => {
            this.loading = false;
            this.error = error.response.data.message;
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.dashboard-style {
  color: grey;
  font-size: 18px;
  font-weight: 700;
}
::v-deep .fontSize input.display-time::placeholder {
  font-family: "Open Sans", sans-serif !important;
  font-size: 16px !important;
  font-weight: 300 !important ;
  color: rgba(0, 0, 0, 0.54) !important;
  line-height: 20px !important;
  height: 20px !important;
}
.vue__time-picker input.display-time {
  border-radius: 0px !important;
  border-bottom: 1px solid #d2d2d2 !important;
  border: none;
  padding: 0px !important;
}
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.payy-ass-btn {
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 2px 2px;
}
.text-style {
  font-size: 16px;
}
.adjust_error {
  color: red;
  font-size: 16px;
  text-align: center;
}
.border___Bottom {
  border-bottom: 1px solid #8080803d !important;
}
</style>
