<template>
  <v-card>
    <v-layout>
      <v-flex xs8 style="text-align: start" class="heading orange--text pb-2"
        >Trip Information
      </v-flex>
      <v-flex xs4 style="text-align: end">
        <v-btn
          v-if="
            readOnly &&
            !isAssignCustomerRecipt &&
            !isCustomerReceiptInitiate &&
            !isJobActivatedByScript &&
            !totalAdditionalAmountTab > 0
          "
          color="grey"
          outline
          v-permissions="'change-location-for-accounts'"
          :disabled="processing"
          @click.native="check()"
          >Change Location</v-btn
        >
        <v-btn
          v-permissions="'change-location-for-accounts'"
          v-if="!readOnly && !isAssignCustomerRecipt"
          class="white--text"
          color="grey"
          :disabled="processing"
          @click.native="reset"
          >Cancel</v-btn
        >
        <v-btn
          v-permissions="'change-location-for-accounts'"
          color="orange darken-2"
          v-if="!readOnly && !isAssignCustomerRecipt"
          :disabled="processing"
          class="white--text"
          @click.native="updateLocation"
          >Update Location</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout align-end wrap>
      <v-flex xs4 class="px-2">
        <v-text-field
          :disabled="readOnly"
          :id="`autocomplete1-${tabOrder}`"
          label="Source Location"
          placeholder="Provide source location"
          v-model="updateInfo.sourceLocation"
          :value="updateInfo.sourceLocation"
        ></v-text-field>
      </v-flex>
      <v-flex xs2 class="px-2">
        <v-text-field
          :disabled="readOnly"
          :id="`autocomplete1City-${tabOrder}`"
          label="Source City"
          placeholder="Source City"
          v-model="updateInfo.sourceCity"
          :value="updateInfo.sourceCity"
          @input="updateSourceCity()"
        ></v-text-field>
      </v-flex>

      <v-flex xs2 class="px-2">
        <!-- <v-text-field
          :disabled="readOnly"
          label="Source Country"
          v-model="updateInfo.sourceCountry"
          :value="updateInfo.sourceCountry"
        ></v-text-field> -->
        <!-- <span class=>Source Country</span> -->
        <v-autocomplete
          label="Source Country"
          placeholder="Source Country"
          :items="countries"
          item-text="country_name"
          :disabled="readOnly"
          class="pt-0"
          v-model="updateInfo.sourceCountry"
          autocomplete="offfadsf"
          :value="updateInfo.sourceCountry"
          @change="updateSourceCountry()"
          required
        />
      </v-flex>
      <v-flex
        xs2
        class="px-2"
        v-if="assignments[0].bookingTimeZone == 'Saudi Arabia Standard Time'"
      >
        <v-text-field
          :disabled="readOnly"
          label="Source City (in Arabic)"
          v-model="updateInfo.sourceCityArabic"
          :value="updateInfo.sourceCityArabic"
        ></v-text-field>
      </v-flex>
      <v-flex
        xs2
        class="px-2"
        v-if="assignments[0].bookingTimeZone == 'Saudi Arabia Standard Time'"
      >
        <v-text-field
          :disabled="readOnly"
          label="Source Country(in Arabic)"
          v-model="updateInfo.sourceCountryArabic"
          :value="updateInfo.sourceCountryArabic"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs4 class="px-2">
        <v-text-field
          :disabled="readOnly"
          :id="`autocomplete2-${tabOrder}`"
          placeholder="Provide destination location"
          label="Destination Location"
          v-model="updateInfo.destinationLocation"
          :value="updateInfo.destinationLocation"
        ></v-text-field>
      </v-flex>
      <v-flex xs2 class="px-2">
        <v-text-field
          :disabled="readOnly"
          :id="`autocomplete2City-${tabOrder}`"
          label="Destination City"
          placeholder="Destination City"
          v-model="updateInfo.destinationCity"
          :value="updateInfo.destinationCity"
          @input="updateDestinationCity()"
        ></v-text-field>
      </v-flex>

      <v-flex xs2 class="px-2">
        <!-- <v-text-field
          :disabled="readOnly"
          label="Destination Country"
          v-model="updateInfo.destinationCountry"
          :value="updateInfo.destinationCountry"
        ></v-text-field> -->
        <!-- <span >Destination Country </span> -->
        <v-autocomplete
          label="Destination Country"
          placeholder="Destination Country"
          :items="countries"
          item-text="country_name"
          :disabled="readOnly"
          v-model="updateInfo.destinationCountry"
          autocomplete="offfadsf"
          :value="updateInfo.destinationCountry"
          @change="updateDestinationCountry()"
          required
        />
      </v-flex>
      <v-flex
        xs2
        class="px-2"
        v-if="assignments[0].bookingTimeZone == 'Saudi Arabia Standard Time'"
      >
        <v-text-field
          :disabled="readOnly"
          label="Destination City (in Arabic)"
          v-model="updateInfo.destinationCityArabic"
          :value="updateInfo.destinationCityArabic"
        ></v-text-field>
      </v-flex>
      <v-flex
        xs2
        class="px-2"
        v-if="assignments[0].bookingTimeZone == 'Saudi Arabia Standard Time'"
      >
        <v-text-field
          :disabled="readOnly"
          label="Destination Country (in Arabic)"
          v-model="updateInfo.destinationCountryArabic"
          :value="updateInfo.destinationCountryArabic"
        ></v-text-field>
      </v-flex>

      <v-spacer />
    </v-layout>
    <v-snackbar
      :timeout="3000"
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
  </v-card>
</template>

<script>
import { StorageKeys, countries } from "../../constants/constants";
export default {
  watch: {
    assignments(val) {
      this.updateInfo = Object.assign({
        sourceLocation: val[0].sourceLocation,
        sourceCity: val[0].sourceCity,
        sourceCityArabic: val[0].sourceCityArabic,
        sourceCountry: val[0].sourceCountry,
        sourceCountryArabic: val[0].sourceCountryArabic,
        destinationLocation: val[0].destinationLocation,
        destinationCity: val[0].destinationCity,
        destinationCityArabic: val[0].destinationCityArabic,
        destinationCountry: val[0].destinationCountry,
        destinationCountryArabic: val[0].destinationCountryArabic,
        destinationlong: val[0].destinationLatLong[0],
        destinationlat: val[0].destinationLatLong[1],
        sourceCityError: false,
        destinationCityError: false,
        sourcelong: val[0].sourceLatLong[0],
        sourcelat: val[0].sourceLatLong[1],
      });
    },

    tabOrder(val) {
      this.tabOrder = val;
    },
  },
  props: {
    assignments: Array,
    tabOrder: Number,
    jobType: String,
    jobId: Number,
    jobGeography: Array,
    isCustomerReceiptInitiate: Boolean,
    isAssignmentCustomerReceiptInitiated: Boolean,
    isAssignCustomerRecipt: Boolean,
    additionalCharges: Number,
    isJobActivatedByScript: Boolean,
    totalAdditionalAmountTab: Number,
  },
  data: () => ({
    readOnly: true,
    operationName: "",
    processing: false,
    countries: countries,
    error: null,
    updateInfo: {
      sourceLocation: null,
      sourcePlace: [],
      sourceCity: null,
      sourceCityArabic: null,
      sourceCountry: null,
      sourceCountryArabic: null,
      destinationLocation: null,
      destinationCity: null,
      destinationCityArabic: null,
      destinationCountry: null,
      destinationCountryArabic: null,
      destinationlong: null,
      destinationlat: null,
      sourceCityError: null,
      destinationCityError: null,
      sourcelong: null,
      sourcelat: null,
    },
  }),
  created() {
    setTimeout(() => {
      return new Promise((resolve, reject) => {
        let script = document.createElement("script");
        script.onload = () => {
          this.initLoadingAutocomplete("1");
          this.initLoadingAutocompleteCity("1");
          this.initUnloadingAutocomplete("1");
          this.initUnloadingAutocompleteCity("1");
        };
        script.src =
          "https://maps.google.com/maps/api/js?libraries=places&key=AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8";
        document.head.appendChild(script);
      });
    }, 2500);

    delete this.axios.defaults.headers.common["token"];
    let token = localStorage.getItem(StorageKeys.SessionKey);
    let config = {
      headers: {
        authorization: `bearer ${token}`,
      },
    };
    this.t = config.headers.authorization;
    if (this.assignments && this.assignments.length > 0) {
      this.updateInfo = {
        sourceLocation: this.assignments[0].sourceLocation,
        sourceCity: this.assignments[0].sourceCity,
        sourceCityArabic: this.assignments[0].sourceCityArabic,
        sourceCountry: this.assignments[0].sourceCountry,
        sourceCountryArabic: this.assignments[0].sourceCountryArabic,
        destinationLocation: this.assignments[0].destinationLocation,
        destinationCity: this.assignments[0].destinationCity,
        sourceCityError: false,
        destinationCityError: false,
        destinationCityArabic: this.assignments[0].destinationCityArabic,
        destinationCountry: this.assignments[0].destinationCountry,
        destinationCountryArabic: this.assignments[0].destinationCountryArabic,
        destinationlong: this.assignments[0].destinationLatLong[0],
        destinationlat: this.assignments[0].destinationLatLong[1],
        sourcelong: this.assignments[0].sourceLatLong[0],
        sourcelat: this.assignments[0].sourceLatLong[1],
      };
    }
  },
  methods: {
    async check() {
      this.processing = true;
      this.operationName = "change-location-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.processing = false;
        this.readOnly = false;
        setTimeout(() => {
          return new Promise((resolve, reject) => {
            let script = document.createElement("script");
            script.onload = () => {
              this.initLoadingAutocomplete(this.tabOrder);
              this.initLoadingAutocompleteCity(this.tabOrder);
              this.initUnloadingAutocomplete(this.tabOrder);
              this.initUnloadingAutocompleteCity(this.tabOrder);
            };
            script.src =
              "https://maps.google.com/maps/api/js?libraries=places&key=AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8";
            document.head.appendChild(script);
          });
        }, 2500);
      } else {
        this.processing = false;
        return;
      }
    },
    initLoadingAutocomplete(n) {
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete1-" + n)
      );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      // autocomplete.setFields(['address_component', 'geometry']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.

      autocomplete.addListener("place_changed", () => {
        // Get the place details from the autocomplete object.
        var place = autocomplete.getPlace();
        this.sourcePlace = autocomplete.getPlace();

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.

        this.updateInfo.sourceLocation = place.formatted_address;
        this.updateInfo.sourcelat = place.geometry.location.lat();
        this.updateInfo.sourcelong = place.geometry.location.lng();

        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];
        if (place.address_components) {
          this.updateInfo.sourceCityError = false;
        } else {
          this.updateInfo.sourceCityError = true;
        }
        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }
        if (x.length === 2) {
          this.updateInfo.sourceCountry = x[1];
          this.updateInfo.sourceCity = x[0];
        } else {
          this.updateInfo.sourceCity = y[0];
          if (y.length == 0) {
            this.updateInfo.sourceCityError = true;
          }
        }
        if (!this.updateInfo.sourceCity.match(/^[ A-Za-z0-9_@./#&+-]*$/)) {
          this.updateInfo.sourceCity = "";
          this.updateInfo.sourceCityError = true;
        }
      });
    },
    updateSourceCity() {
      if (this.updateInfo.sourceCityError == false) {
        this.updateInfo.sourceCityError = true;
      }

      // this.axios
      //   .get("https://maps.googleapis.com/maps/api/place/autocomplete/json", {
      //     params: {
      //       input: this.x.sub[n - 1].sourceCity,
      //       key: "AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8", // Replace with your actual API key
      //     },
      //   })
      //   .then((response) => {
      //     console.log(response);
      //   })
      //   .catch((error) => {
      //     console.error("Error fetching predictions:", error);
      //   });
    },
    updateDestinationCity() {
      if (this.updateInfo.destinationCityError == false) {
        this.updateInfo.destinationCityError = true;
      }

      // this.axios
      //   .get("https://maps.googleapis.com/maps/api/place/autocomplete/json", {
      //     params: {
      //       input: this.x.sub[n - 1].sourceCity,
      //       key: "AIzaSyAbH5dlLsPapMreDphjjYtTbdj0_xHdLt8", // Replace with your actual API key
      //     },
      //   })
      //   .then((response) => {
      //     console.log(response);
      //   })
      //   .catch((error) => {
      //     console.error("Error fetching predictions:", error);
      //   });
    },
    updateDestinationCountry() {
      this.updateInfo.destinationCityError = true;
      this.updateInfo.destinationCity = "";
    },
    updateSourceCountry() {
      this.updateInfo.sourceCity = "";
      this.updateInfo.sourceCityError = true;
    },
    initLoadingAutocompleteCity(n) {
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete1City-" + n)
      );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      // autocomplete.setFields(['address_component', 'geometry']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.

      autocomplete.addListener("place_changed", () => {
        // Get the place details from the autocomplete object.
        var place = autocomplete.getPlace();
        this.sourcePlace = autocomplete.getPlace();

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.

        // this.updateInfo.sourceLocation = place.formatted_address;
        this.updateInfo.sourcelat = place.geometry.location.lat();
        this.updateInfo.sourcelong = place.geometry.location.lng();

        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];
        if (place.address_components) {
          this.updateInfo.sourceCityError = false;
        } else {
          this.updateInfo.sourceCityError = true;
        }
        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }
        if (x.length === 2) {
          this.updateInfo.sourceCountry = x[1];
          this.updateInfo.sourceCity = x[0];
        } else {
          if (y.length == 0) {
            this.updateInfo.sourceCityError = true;
          }
          this.updateInfo.sourceCity = y[0];
        }
        if (!this.updateInfo.sourceCity.match(/^[ A-Za-z0-9_@./#&+-]*$/)) {
          this.updateInfo.sourceCity = "";
          this.updateInfo.sourceCityError = true;
        }
      });
    },
    initUnloadingAutocomplete(n) {
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete2 = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete2-" + n)
      );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      // autocomplete2.setFields(['address_component', 'geometry']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      autocomplete2.addListener("place_changed", () => {
        // Get the place details from the autocomplete object.
        var place = autocomplete2.getPlace();

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.

        this.updateInfo.destinationLocation = place.formatted_address;
        this.updateInfo.destinationlong = place.geometry.location.lng();
        this.updateInfo.destinationlat = place.geometry.location.lat();

        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];
        if (place.address_components) {
          this.updateInfo.destinationCityError = false;
        } else {
          this.updateInfo.destinationCityError = true;
        }

        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }
        if (x.length === 2) {
          this.updateInfo.destinationCountry = x[1];
          this.updateInfo.destinationCity = x[0];
        } else {
          // this.updateInfo.destinationCountry = y[1];
          if (y.length == 0) {
            this.updateInfo.destinationCityError = true;
          }
          this.updateInfo.destinationCity = y[0];
        }
        if (!this.updateInfo.destinationCity.match(/^[ A-Za-z0-9_@./#&+-]*$/)) {
          this.updateInfo.destinationCity = "";
          this.updateInfo.destinationCityError = true;
        }
      });
    },
    initUnloadingAutocompleteCity(n) {
      // Create the autocomplete object, restricting the search predictions to
      // geographical location types.
      var autocomplete2 = new google.maps.places.Autocomplete(
        document.getElementById("autocomplete2City-" + n)
      );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      // autocomplete2.setFields(['address_component', 'geometry']);

      // When the user selects an address from the drop-down, populate the
      // address fields in the form.
      autocomplete2.addListener("place_changed", () => {
        // Get the place details from the autocomplete object.
        var place = autocomplete2.getPlace();

        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.

        // this.updateInfo.destinationLocation = place.formatted_address;
        this.updateInfo.destinationlong = place.geometry.location.lng();
        this.updateInfo.destinationlat = place.geometry.location.lat();

        var componentMap = {
          country: "country",
          locality: "locality",
        };
        var componentMap2 = {
          administrative_area_level_1: "administrative_area_level_1",
        };
        var x = [];
        var y = [];
        if (place.address_components) {
          this.updateInfo.destinationCityError = false;
        } else {
          this.updateInfo.destinationCityError = true;
        }

        for (var i = 0; i < place.address_components.length; i++) {
          var types = place.address_components[i].types; // get types array of each component
          for (var j = 0; j < types.length; j++) {
            // loop through the types array of each component as types is an array and same thing can be indicated by different name.As you can see in the json object above
            var component_type = types[j];
            // check if this type is in your component map.If so that means you want this component
            if (componentMap.hasOwnProperty(component_type)) {
              x.push(place.address_components[i]["long_name"]);
            }
            if (componentMap2.hasOwnProperty(component_type)) {
              y.push(place.address_components[i]["long_name"]);
            }
          }
        }
        if (x.length === 2) {
          this.updateInfo.destinationCountry = x[1];
          this.updateInfo.destinationCity = x[0];
        } else {
          if (y.length == 0) {
            this.updateInfo.destinationCityError = true;
          }
          this.updateInfo.destinationCity = y[0];
        }
        if (!this.updateInfo.destinationCity.match(/^[ A-Za-z0-9_@./#&+-]*$/)) {
          this.updateInfo.destinationCity = "";
          this.updateInfo.destinationCityError = true;
        }
      });
    },
    async updateLocation() {
      this.processing = true;
      this.operationName = "change-location-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.processing = false;
        console.log(this.tabOrder);
        console.log(this.$props.jobGeography[this.tabOrder - 1]);

        console.log(this.$props.jobGeography);
        if (
          this.updateInfo.sourceCityError == null ||
          this.updateInfo.sourceCityError == true
        ) {
          this.updateInfo.sourceCityError = true;
          this.error = "Please choose valid city ";
          this.profileUpdateLoading = false;
          return;
        }
        if (
          this.updateInfo.destinationCityError == null ||
          this.updateInfo.destinationCityError == true
        ) {
          this.updateInfo.destinationCityError = true;
          this.error = "Please choose valid city ";
          this.profileUpdateLoading = false;
          return;
        }

        if (
          this.$props.jobGeography[this.tabOrder - 1] == 1 &&
          this.updateInfo.sourceCountry == this.updateInfo.destinationCountry
        ) {
          this.error =
            "Please select different country address because your job geo type is international";
          this.profileUpdateLoading = false;
          return false;
        }
        if (
          this.$props.jobGeography[this.tabOrder - 1] == 2 &&
          this.updateInfo.sourceCountry !== this.updateInfo.destinationCountry
        ) {
          this.error =
            "Please select same country address because your job geo type is domestic";
          this.profileUpdateLoading = false;
          return false;
        }
        if (!this.updateInfo.sourcelong && !this.updateInfo.sourcelat) {
          this.error =
            "Please select source address from the suggestions dropdown!";

          return;
        }

        if (
          !this.updateInfo.destinationlong &&
          !this.updateInfo.destinationlat
        ) {
          this.error =
            "Please select destination address from the suggestions dropdown!";

          return;
        }
        if (!this.updateInfo.sourceLocation) {
          this.error =
            "Please select source location from the suggestions dropdown!";
          return;
        }
        if (!this.updateInfo.destinationLocation) {
          this.error =
            "Please select destination location from the suggestions dropdown!";
          return;
        }
        if (!this.updateInfo.sourceCity) {
          this.error = "Please select source city before moving ahead!";
          return;
        }
        // if (!this.updateInfo.sourceCity.match(/^[ A-Za-z]*$/)) {
        //   this.error = "Please enter valid source city before moving ahead!";
        //   return;
        // }

        if (!this.updateInfo.sourceCountry) {
          this.error = "Please select source country before moving ahead!";
          return;
        }

        if (!this.updateInfo.destinationCity) {
          this.error = "Please select destination city before moving ahead!";

          return;
        }
        // if (!this.updateInfo.destinationCity.match(/^[ A-Za-z]*$/)) {
        //   this.error =
        //     "Please enter valid destination city before moving ahead!";

        //   return;
        // }

        if (!this.updateInfo.destinationCountry) {
          this.error = "Please select destination country before moving ahead!";
          return;
        }
        let jobId = this.$props.jobId.toString();
        var jobTabOrder = this.tabOrder;

        var tabOrder = jobTabOrder.toString();
        var jobType = this.jobType ? this.jobType.toString() : "1";

        let {
          sourceLocation,
          sourceCity,
          sourceCityArabic,
          sourceCountry,
          sourceCountryArabic,
          destinationLocation,
          destinationCity,
          destinationCityArabic,
          destinationCountry,
          destinationCountryArabic,
          destinationlong,
          destinationlat,
          sourcelong,
          sourcelat,
        } = this.updateInfo;

        destinationlong = destinationlong.toString();
        destinationlat = destinationlat.toString();
        sourcelong = sourcelong.toString();
        sourcelat = sourcelat.toString();

        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        this.axios
          .post(
            `${this.constants.apiUrl}/dmsAdmin/assignmentLocation`,
            {
              jobId,
              sourceLocation,
              sourceCity,
              sourceCityArabic,
              sourceCountry,
              sourceCountryArabic,
              destinationLocation,
              destinationCity,
              destinationCityArabic,
              destinationCountry,
              destinationCountryArabic,
              destinationlong,
              destinationlat,
              sourcelong,
              sourcelat,
              tabOrder,
              jobType,
            },
            config
          )
          .then(
            (response) => {
              const { status, data } = response.data;
              if (status === 200) {
                this.updateInfo = Object.assign(
                  {},
                  {
                    sourceLocation,
                    sourceCity,
                    sourceCityArabic,
                    sourceCountry,
                    sourceCountryArabic,
                    destinationLocation,
                    destinationCity,
                    destinationCityArabic,
                    destinationCountry,
                    destinationCountryArabic,
                    sourcelat,
                    sourcelong,
                    destinationlong,
                    destinationlat,
                  }
                );
              }
              this.readOnly = true;
              this.$emit("account-list-refresh");
            },
            (error) => {
              this.error = error.response.data.message;
            }
          );
      } else {
        this.processing = false;

        return;
      }
    },
    async reset() {
      this.operationName = "change-location-for-accounts";
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.readOnly = true;
        if (this.assignments && this.assignments.length > 0) {
          this.updateInfo = {
            sourceLocation: this.assignments[0].sourceLocation,
            sourceCity: this.assignments[0].sourceCity,
            sourceCityArabic: this.assignments[0].sourceCityArabic,
            sourceCountry: this.assignments[0].sourceCountry,
            sourceCountryArabic: this.assignments[0].sourceCountryArabic,
            destinationLocation: this.assignments[0].destinationLocation,
            destinationCity: this.assignments[0].destinationCity,
            destinationCityArabic: this.assignments[0].destinationCityArabic,
            destinationCountry: this.assignments[0].destinationCountry,
            destinationCountryArabic:
              this.assignments[0].destinationCountryArabic,
            destinationlong: this.assignments[0].destinationLatLong[0],
            destinationlat: this.assignments[0].destinationLatLong[1],
            sourceCityError: false,
            destinationCityError: false,
            sourcelong: this.assignments[0].sourceLatLong[0],
            sourcelat: this.assignments[0].sourceLatLong[1],
          };
        }
        this.updateInfo = Object.assign({
          sourceLocation: this.updateInfo.sourceLocation,
          sourceCity: this.updateInfo.sourceCity,
          sourceCityArabic: this.updateInfo.sourceCityArabic,
          sourceCountry: this.updateInfo.sourceCountry,
          sourceCountryArabic: this.updateInfo.sourceCountryArabic,
          destinationLocation: this.updateInfo.destinationLocation,
          destinationCity: this.updateInfo.destinationCity,
          destinationCityArabic: this.updateInfo.destinationCityArabic,
          destinationCountry: this.updateInfo.destinationCountry,
          destinationCountryArabic: this.updateInfo.destinationCountryArabic,
          destinationlong: this.updateInfo.destinationLatLong[0],
          destinationlat: this.updateInfo.destinationLatLong[1],
          sourcelong: this.updateInfo.sourceLatLong[0],
          sourceCityError: false,
          destinationCityError: false,
          sourcelat: this.updateInfo.sourceLatLong[1],
        });
      } else {
        return;
      }
    },
  },
};
</script>

<style scoped>
.tmutedlabel {
  display: block;
  text-align: start;
  line-height: 17px;
  font-size: 10px;
}
</style>
