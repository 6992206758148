<template>
  <div class="log pb-2">
    <v-btn
      class="track-ass-btn blue-button"
      click.stop="dialog = false"
      @click="
        dialog = true;
        assigments();
      "
      target="_blank"
    >
      <v-icon>folder</v-icon> Proof
    </v-btn>

    <v-dialog v-model="dialog" max-width="40%" persistent>
      <v-form
        ref="form"
        onsubmit="return false;"
        v-model="valid"
        lazy-validation
      >
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title>Add Proof</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="close">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>

          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-spacer>
            <v-card-text class="nospace">
              <v-container class="p-16">
                <v-layout pt-4>
                  <v-flex
                    xs3
                    class="pr-1 pt-1 pb-3 pt-100"
                    v-if="document.length < 30"
                  >
                    <div class="upload-btn-wrapper">
                      <button v-if="document.length < 30" class="btn">
                        + Upload
                      </button>
                      <input
                        ref="fileUpload"
                        id="fileUpload"
                        type="file"
                        multiple
                        accept=".pdf, .jpeg, .jpg, .png"
                        @change="uploadDocument"
                      />
                    </div>
                  </v-flex>
                  <v-flex class="pt-1" xs7 v-if="document.length">
                    <v-text-field
                      v-model="comments"
                      class="pt-0 currencyTitle"
                      placeholder="Description"
                      :rules="[rules.noWhiteSpace, rules.required]"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-flex xs12>
                  <ul style="padding: 0px !important">
                    <li
                      style="position: relative"
                      :key="i"
                      v-for="(doc, i) in document"
                      class="list"
                      ref="documentList"
                    >
                      <button
                        type="button"
                        v-show="document"
                        @click="removeImage(i)"
                      >
                        <span class="close imageClose">&times;</span>
                      </button>
                      <a
                        :href="doc"
                        class="document-link"
                        v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                        target="_blank"
                      >
                        <img src="../../assets/pfd.png" />
                      </a>
                      <a
                        :href="doc"
                        class="document-link"
                        target="_blank"
                        v-else
                      >
                        <img
                          src="../../assets/docimg.webp"
                          height="72"
                          width="75"
                        />
                      </a>
                    </li>
                  </ul>
                </v-flex>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-snackbar
                :timeout="3000"
                bottom
                color="red darken-2"
                v-model="x.error"
                class="white--text"
                v-if="x.error"
                >{{ x.error }}</v-snackbar
              >
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                :disabled="processing"
                flat
                @click="close"
                >Close</v-btn
              >
              <v-btn
                color="orange darken-1"
                :disabled="processing"
                flat
                @click="addProofOfDelivery()"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
    <success-dialog
      content="The proof of additional charge has been uploaded successfully"
      :show="x.success"
      :onclose="() => (x.success = false)"
    />
  </div>
</template>
<script>
import moment from "moment";
import { addProofOfDelivery, uploadImage } from "@/constants/api-urls.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import { StorageKeys } from "../../constants/constants";
export default {
  data() {
    return {
      AssignmentId: [],
      customerStatus: "",
      ids: "",
      currency: "",
      valid: true,
      lazy: false,
      type: "",
      comments: "",
      documents: [],
      document: [],
      x: {
        error: null,
        success: false,
      },
      amount: null,
      comments: null,
      items: [],
      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        noWhiteSpace: (v) =>
          (v != null && v.trim().length > 0) || "This field is required.",
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  props: {
    assId: String,
  },
  created() {
    // this.getActivityLog();
    this.customerStatus = localStorage.getItem("cust-status");
  },
  components: {
    SuccessDialog,
  },
  methods: {
    removeImage(key) {
      this.document.splice(key, 1);
    },
    close() {
      this.dialog = false;
      this.document = [];
      (this.type = ""), (this.comments = "");
      this.$refs.form.resetValidation();
    },
    uploadDocument(e) {
      this.error = null;
      var files = e.target.files || e.dataTransfer.files;
      let x = [...files];
      setTimeout(() => {
        this.$refs.fileUpload.value = "";
      }, 6000);
      if (!files.length) return;

      Array.from(x).forEach((y, i) => {
        this.createImage(files[i]);
      });
    },
    createImage(file) {
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.x.error = "Please upload proof with size less than 5MB!";

        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        this.x.error =
          "Please upload proof of file type png , jpg ,jpeg or pdf!";
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.upload(file);
      }
    },
    upload(file) {
      this.processing = true;
      const formData = new FormData();
      formData.append("image", file);
      this.axios({
        url: `${this.constants.apiUrl}${uploadImage}`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          this.processing = false;
          const { statusCode } = response.data;
          if (statusCode === 200) {
            if (this.document.length < 30) {
              this.document.push(response.data.data.original);
            } else {
              this.x.error = "Maximum 30 proofs can be uploaded!";
              return false;
            }
          } else {
            this.x.error = "Failed to upload image";
          }
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to upload image";
          this.processing = false;
        }
      );
    },
    addProofOfDelivery() {
      if (this.processing) {
        return;
      }

      if (!this.document.length) {
        this.x.error = "Please upload proof of delivery document";
        this.processing = false;
        this.loading = false;
        return false;
      }
      if (this.$refs.form.validate()) {
        this.processing = true;
        let url = addProofOfDelivery;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          assignmentId: this.$props.assId,
          document: this.document,
          comment: this.comments,
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.document = [];
            this.$refs.fileUpload.value = "";
            this.processing = false;
            this.close();

            this.$eventBus.$emit("delivery-proof-dialog");
          },
          (error) => {
            this.processing = false;
            this.x.error = error.response.data.message;
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.assign-driver-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #bebebe;
  border: 2px solid grey;
  padding: 2px 5px;
  border-radius: 5px;
}
.document-link {
  text-decoration: none;
}
.ttt button select {
  border: none !important;
}
.imageClose {
  position: absolute;
  width: 15px;
  bottom: 80px;
  height: 15px;
  background: #000;
  border-radius: 50%;
  color: #fff !important;
  line-height: 15px;
  top: -6px;
  left: 2px;
}

.message {
  display: inline-block;
  margin: 0 50px;
}
.communication {
  width: 40%;
}
.success {
  font-size: 18px;
  font-weight: 600;
}
.messagebtn {
  margin: 0 auto;
}
.list {
  display: inline-block;
  position: relative;
}
.thumb {
  width: 80px;
  height: 80px;
}

.custom-file-upload {
  border: none;
  display: inline-block;
  background: orange;
  font-size: 16px;
  color: #fff;
  padding: 10px 12px;
  cursor: pointer;
}
.blue-button {
  border-radius: 5px;
  background-color: blue !important;
  color: #ffffff !important;
  border: 2px solid blue !important;
}
</style>
