<template>
  <v-radio-group v-model="selectedValue">
    <v-card class="nospace">
      <v-data-table
        id="adjustTable"
        hide-actions
        :headers="headers"
        width="100% !important"
        :disable-initial-sort="true"
        :pagination.sync="pagination"
        :items="items"
      >
        <template slot="items" slot-scope="props">
          <td style="min-width: 200px !important">
            <v-radio
              :label="`${props.item.customerData['name']}`"
              :value="props.item._id"
              @change="
                radioSelected(
                  props.index,
                  props.item._id,
                  props.item.customer_id,
                  props.item.amount
                )
              "
            ></v-radio>
          </td>
          <td style="min-width: 180px !important">
            {{ getTime4(props.item.actualReceivedDate) }}
            {{ props.item.actualReceivedTime }}
          </td>
          <td style="min-width: 180px !important">
            {{ props.item.receiptNumber }}
          </td>

          <td style="min-width: 180px !important">
            {{ props.item.paymentMode }}
          </td>
          <td style="min-width: 180px !important">
            {{ props.item.amount }} {{ props.item.customerCurrency }}
          </td>
          <td style="min-width: 180px !important">
            {{ props.item.adjustedAmount }} {{ props.item.customerCurrency }}
          </td>

          <td style="min-width: 180px !important">
            {{ props.item.balanceAmount }} {{ props.item.customerCurrency }}
          </td>
        </template>
      </v-data-table>

      <pagination
        :totalPages="totalPages"
        :pages="this.pages"
        @pageNo="page"
        :url="listApiUrl"
        :callback="callback"
        :componentKey="componentKey"
        :action="`customerList`"
        :payload="payload"
        :type="1"
        v-on:pagination-load="checkLoading"
      />
    </v-card>
  </v-radio-group>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import pagination from "@/components/Pagination/pagination";
import { adjustCustomerPayment } from "@/constants/datatable-headers.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import { receieveCustomerPayment } from "@/constants/api-urls.js";
export default {
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;
      this.track("Sales Section", {
        email: localStorage.getItem("email"),
        name: localStorage.getItem("user"),
        "Working Country": workingCountry.toString(),
      });
      this.payload = {
        customer_id: this.$props.customerId,
        section: "adjust",
      };
    }
  },
  components: {
    pagination,
    SuccessDialog,
  },
  props: {
    customerId: String,
  },
  data() {
    return {
      workingCountry: null,
      selectedids: [],
      selectednames: [],

      checkBox: true,
      selectedValue: "",
      title: null,
      dialog1: false,
      componentKey: 0,
      count: 0,
      totalPages: 0,
      checkDone: true,
      listApiUrl: receieveCustomerPayment,
      headers: adjustCustomerPayment,
      pagination: {},
      payload: {},
      items: [],
      pages: 1,
      entryId: "",
      custId: "",
      amount: "",
    };
  },
  methods: {
    checkLoading(event) {
      if (event) {
        this.$emit("on-load", true);
        this.selectedValue = "";
      } else {
        this.$emit("off-load", false);
        this.selectedValue = "";
      }
    },

    radioSelected(i, id, cust_id, amount) {
      this.entryId = id;
      this.custId = cust_id;
      this.amount = amount;

      this.$emit("entry-id", this.entryId);
      this.$emit("cust-id", this.custId);
      this.$emit("recieve-amount", this.amount);
    },

    checkSelected(select, i, id, name) {
      this.checkDone = false;

      if (select && !this.selectedids.includes(id)) {
        this.selectedids.push(id);
        this.selectednames.push(name + " " + "," + id);
      }
      if (!select && this.selectedids.includes(id)) {
        this.selectedids.splice(this.selectedids.indexOf(id), 1);
        this.selectednames.splice(
          this.selectednames.indexOf(name + " " + "," + id),
          1
        );
      }
    },

    page(event) {
      this.pages = event;
    },
    getTime4(date) {
      return moment(date).format("ll");
    },
    callback(res) {
      this.items = [...res.list];
      this.selectedValue = "";
      this.entryId = "";
      this.custId = "";
      this.amount = "";
      this.$emit("entry-id", this.entryId);
      this.$emit("cust-id", this.custId);
      this.$emit("recieve-amount", this.amount);
      this.items.forEach((e) => {
        e.selected = false;
        if (this.selectedids.includes(e._id)) {
          e.selected = true;
        }
      });

      // this.items.filter((e))
      this.totalPages = Math.ceil(res.totalData / 5);
      this.pagination.rowsPerPage = 5;
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style>
.td {
  min-width: 200px;
}
.v-input--selection-controls .v-input__control {
  width: 100%;
}
</style>
