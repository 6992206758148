<template>
  <v-card class="nospace">
    <v-data-table
      id="dataTable"
      hide-actions
      :headers="headers"
      width="100% !important"
      hide-default-footer
      :pagination.sync="pagination"
      :items="items"
    >
      <template slot="items" slot-scope="props">
        <td style=" min-width: 200px !important">
          {{ getTime4(props.item.adjustedAt) }}
        </td>
        <td style="min-width: 180px !important">
          {{ props.item.invoiceNumber }}
        </td>
          <td >
          {{props.item.isInvoiceLevelAssignment?"Assignment Level":"Job Level"}}
           
          </td>
     
        <td style="min-width: 180px !important">
          {{ props.item.amount.toFixed(2)}}  {{ props.item.currency }}
        </td>

        <td style=" min-width: 180px !important">
          {{ props.item.balanceAmount.toFixed(2) }} {{ props.item.currency }}
        </td>
        <td style=" min-width: 180px !important">
          {{ props.item.adjustedAmount.toFixed(2) }} {{ props.item.currency }}
        </td>
        <td style="min-width: 180px !important">
        {{ props.item.createdBy }}</br>
            {{ props.item.employeeID }}
        </td>
        <td style="min-width: 180px !important">
         <editAdjustAmountPopup  :invoiceDetails="props.item" :entryId="refernceId" :custId="custId"/>
        </td>
      </template>
    </v-data-table>

    <pagination
      :totalPages="totalPages"
      :pages="this.pages"
      @pageNo="page"
      :url="listApiUrl"
      :callback="callback"
      :componentKey="componentKey"
      :action="`customerList`"
      :payload="payload"
      :type="1"
      v-on:pagination-load="checkLoading"
    />
  </v-card>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import pagination from "@/components/Pagination/pagination";
import editAdjustAmountPopup from "@/components/Accounts/CustomerPayment/CustomerPopups/editAdjustAmountPopup.vue";
import { markedOffCustomerPayment } from "@/constants/datatable-headers.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import { adjustStatus } from "@/constants/api-urls.js";
import { EventBus } from "../../../../event-bus.js";
export default {
  created() {
      this.$eventBus.$on("customerRecipt", (value) => {
      this.refreshList();
    });

    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = workingCountry;
      this.track("Sales Section", {
        email: localStorage.getItem("email"),
        name: localStorage.getItem("user"),
        "Working Country": workingCountry.toString(),
      });
      this.payload = {
        entry_id: this.$props.refernceId,
      };
    }
  },
  components: {
    pagination,
    SuccessDialog,
    editAdjustAmountPopup
  },
  props: {
    refernceId: String,
    custId:String,

  },
  watch: {
    dateRange2(val) {
      if (val) {
        if (val[0] && val[1]) {
          this.startDate = moment(val[0]).format("YYYY-MM-DD");
          this.endDate = moment(val[1]).format("YYYY-MM-DD");
          this.$emit("daterangenew-cust-pay", this.dateRange2);
          this.$set(this.payload, "adjustDateRange", {
            start: this.startDate,
            end: this.endDate,
          });
        }
      } else {
        this.startDate = "";
        this.endDate = "";
      }
    },
  },
  data() {
    return {
      workingCountry: null,
      selectedids: [],
      selectednames: [],
      waterMark: "Select a Range",

      dateRange2: null,
      checkBox: true,
      selectedValue: "",
      search: "",
      title: null,
      dialog1: false,
      componentKey: 0,
      count: 0,
      totalPages: 0,
      checkDone: true,
      listApiUrl: adjustStatus,
      headers: markedOffCustomerPayment,
      pagination: {},
      payload: {},
      items: [],
      pages: 1,
    };
  },
  methods: {
    checkLoading(event) {
      if (event) {
        this.$emit("on-load", true);
        this.selectedValue = "";
      } else {
        this.$emit("off-load", false);
        this.selectedValue = "";
      }
    },
    back() {
      this.$emit("back-button");
    },
     refreshList() {
      this.componentKey = this.componentKey + 1;
    },
    page(event) {
      this.pages = event;
    },
    getTime4(date) {
      return moment(date).format("ll");
    },
    searchAnything() {
      this.$set(this.payload, "adjustNumber", this.search);
    },
    reset() {
      this.search = "";
      this.dateRange2 = null;

      this.payload = {
        pageNo: 1,
        entry_id: this.$props.refernceId,
      };
    },
    callback(res) {
      this.items = res.data
      
      
      // this.items.filter((e))
      this.totalPages = Math.ceil(res.totalData / 5);
      this.pagination.rowsPerPage = 5;
     
    },
  },
};
</script>
<style>
.td {
  min-width: 200px;
}
.v-input--selection-controls .v-input__control {
  width: 100%;
}
.openMarked-btn-color {
  background-color: #042f71 !important;
}
</style>
