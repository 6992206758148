<template>
  <div class="log">
    <v-btn
      class="Loss-btn-color"
      v-if="$props.type == 1"
      :disabled="!$props.lossAmount > 0"
      @click.stop="dialog = false"
      @click="check()"
      style="padding: 21px !important"
      flat
      prepend-icon
      color="white "
    >
      Loss To Trukkin
    </v-btn>
    <button
      outline
      class="tra-ass-btn outlined"
      type="button pl-1 "
      title="Edit"
      @click.stop="Edit()"
      v-if="$props.type == 2"
    >
      <v-icon
        color="orange darken-1 outlined"
        flat
        prepend-icon
        class="customer-actions"
        >edit
      </v-icon>
    </button>
    <v-dialog v-model="dialog" max-width="55%">
      <v-form ref="form" onsubmit="return false;">
        <v-card class="comments-view">
          <!-- <loading :active.sync="loading" /> -->
          <v-toolbar fixed flat>
            <v-toolbar-title
              >Loss to Trukkin({{ $props.invoiceNumber }})</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="cancel()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-spacer>
            <v-progress-linear
              indeterminate
              color="trukkin-theme darken-2"
              v-show="processing"
            ></v-progress-linear>
            <v-card-text class="nospace">
              <v-container class="p-16">
                <div class="body-2 red--text pt-2" v-if="automaticapp">
                  {{ messageapproved }}
                </div>

                <v-layout row class="py-4">
                  <v-flex xs5 pt-2>
                    <v-autocomplete
                      label="Choose Receipt Number*"
                      :items="customers"
                      item-text="text"
                      item-value="text"
                      :value="receiptNumber"
                      :disabled="automaticapp"
                      v-model="receiptNumber"
                      @change="setCustomerValues($event)"
                    ></v-autocomplete>
                  </v-flex>
                  <v-flex xs1></v-flex>

                  <v-flex xs6 md6 pt-3>
                    <div class="sub-heading muted">Total Receive Amount</div>

                    <span
                      style="color: black"
                      class="heading"
                      v-if="totalAmount"
                      >{{ totalAmount.toFixed(2) }} {{ customerCurrency }}
                    </span>
                    <span style="color: black" class="heading" v-else
                      >N.A
                    </span>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs5>
                    <v-text-field
                      class="custom-label-color"
                      label="Enter the Loss Amount*"
                      :disabled="automaticapp"
                      v-model="amount"
                      type="number"
                      max="this.$props.lossAmount"
                      :rules="[rules.required, rules.minamount]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs1></v-flex>

                  <v-flex xs6 md6 pt-3>
                    <v-text-field
                      label="Comments*"
                      class="pt-0 currencyTitle custom-label-color"
                      v-model="comment"
                      :disabled="automaticapp"
                      maxlength="150"
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout>
                  <v-flex md12 class="payadvance">
                    <v-flex xs7 class="pt-3 pt-100" v-if="document.length < 5">
                      <div class="upload-btn-wrapper">
                        <button v-if="document.length < 5" class="btn">
                          <v-icon small color="orange">upload</v-icon> Upload
                          Proofs
                        </button>
                        <input
                          ref="fileUpload"
                          id="fileUpload"
                          type="file"
                          multiple
                          accept=".pdf, .jpeg, .jpg, .png"
                          @change="uploadDocument"
                        />
                      </div> </v-flex
                  ></v-flex>
                  <v-flex md12 class="payadvance">
                    <ul style="padding-top: 10px !important">
                      <li
                        style="position: relative"
                        :key="i"
                        v-for="(doc, i) in document"
                        class="list"
                        ref="documentList"
                      >
                        <button
                          type="button"
                          v-show="document"
                          @click="removeImage(i)"
                        >
                          <span class="close imageClose">&times;</span>
                        </button>
                        <a
                          :href="doc"
                          class="document-link"
                          v-if="doc.substr(doc.lastIndexOf('.') + 1) === 'pdf'"
                          target="_blank"
                        >
                          <img src="../../../../assets/pfd.png" />
                        </a>
                        <a
                          :href="doc"
                          class="document-link"
                          target="_blank"
                          v-else
                        >
                          <img
                            src="../../../../assets/docimg.webp"
                            height="72"
                            width="75"
                          />
                        </a>
                      </li>
                    </ul>
                  </v-flex>
                </v-layout>
                <v-layout> </v-layout>
              </v-container>
              <div
                class="my-3"
                v-if="haserror"
                style="
                  color: red;
                  width: 80%;
                  border: 0px;
                  margin: auto;
                  text-align: center;
                "
              >
                {{ x.error }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                flat
                :disabled="processing"
                @click="cancel()"
                >Close</v-btn
              >
              <v-btn
                v-if="!automaticapp"
                color="orange darken-1"
                flat
                :disabled="processing"
                @click="lossCharges()"
                >Submit</v-btn
              >
            </v-card-actions>
          </v-spacer>
          <v-dialog v-model="confirmationDialog" max-width="23%" persistent>
            <v-card>
              <v-spacer class="spacerclass">
                <v-card-text style="padding-top: 0px !important">
                  <v-layout class="pt-4" style="text-align: center">
                    <v-flex pr-2 pb-3>
                      <v-icon x-large color="orange">info</v-icon></v-flex
                    >
                  </v-layout>
                  <v-layout>
                    <v-flex pb-3 style="text-align: center">
                      The Loss To Trukkin amount has been sent for
                      approval.</v-flex
                    >
                  </v-layout>
                  <v-layout>
                    <v-flex style="text-align: center">
                      <v-btn
                        color="white darken-1"
                        style="
                          background: orange !important;
                          min-width: 66px !important;
                        "
                        flat
                        @click.prevent="confirm()"
                        :loading="processing"
                        >OK</v-btn
                      ></v-flex
                    >
                  </v-layout>
                </v-card-text>
              </v-spacer>
            </v-card>

            <v-snackbar
              :timeout="3000"
              bottom
              color="red darken-2"
              v-model="x.error"
              class="white--text"
              v-if="error"
              >{{ error }}</v-snackbar
            >
          </v-dialog>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { lossToTrukkin } from "@/constants/api-urls.js";
import SuccessDialog from "@/components/Common/SuccessDialog";
import { StorageKeys, Currencies } from "../../../../constants/constants";
import CustomerCurrency from "@/components/Common/CustomerCurrency";
import { uploadImage } from "../../../../constants/api-urls";
import { EventBus } from "../../../../event-bus.js";
export default {
  data() {
    return {
      creditLimitError: "",
      comment: null,
      UpdateListButton: false,

      confirmationDialog: false,
      options: {
        color: "#ffc04c",
        width: 400,
        zIndex: 200,
      },
      dialog1: false,
      amount: "",
      operationName: "",
      document: [],
      x: {
        error: null,
        jobPrice: null,
        customerCurrency: null,
        subJobPrice: null,
      },

      items: [],
      currencies: [],
      automaticapp: false,
      receiptId: "",
      customers: [],
      processing: false,
      reason: "",
      error: "",
      messageapproved: "",
      receiptNumber: "",

      dialog: false,
      disStatus: false,
      haserror: false,
      loading: false,
      totalAmount: null,
      levelAssignment: null,
      rules: {
        required: (value) => !!value || "This field is required.",
        emailRules: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(v) ||
          "E-mail must be valid",
        minamount: (v) => (v && v >= 1) || "Enter valid value",
        countryCode: (v) =>
          /^(\+?\d{1,3}|\d{1,4})$/.test(v) ||
          "Please Enter Valid Country Code.",
        phoneNumber: (v) =>
          /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(v) ||
          "Please Enter Valid Mobile No.",
        number: (v) => /^[0-9]*$/.test(v) || "Please Enter Valid Price",
      },
    };
  },
  props: {
    custid: String,

    invoiceNumber: String,
    lossAmount: Number,
    customerCurrency: String,

    type: Number,
    editData: Object,
    invoiceLevel: Boolean,
  },
  watch: {
    type(event) {
      if (event == 1) {
        this.UpdateListButton = false;
      } else {
        this.UpdateListButton = true;
      }
    },
  },

  created() {
    // this.$eventBus.$on("customerRecipt", (value) => {
    //   this.$props.lossAmount = 0;
    // });
    // this.x.customerCurrency = this.detail.customerCurrency;
  },
  components: {},
  methods: {
    removeImage(key) {
      this.document.splice(key, 1);
    },
    cancel() {
      this.dialog = false;
      this.dialog1 = false;
      this.amount = "";
      this.comment = null;
      this.automaticapp = false;
      this.document = [];
    },
    async check() {
      this.fetchInvoice();
      this.open();
    },
    async Edit() {
      this.fetchInvoice();
      this.open();
      console.log(this.$props.invoiceLevel);
      console.log(this.$props.editData.isInvoiceLevelAssignment);

      // this.$props.invoiceLevel == this.$props.editData.isInvoiceLevelAssignment;
      // this.levelAssignment=this.$props.editData.isInvoiceLevelAssignment
      if (this.$props.editData.lossTTEntryProof) {
        this.document = this.$props.editData.lossTTEntryProof;
      }

      if (!this.$props.editData.isInvoiceLevelAssignment) {
        console.log("updated");
        this.levelAssignment = this.$props.invoiceLevel;
      } else {
        this.levelAssignment = this.$props.editData.isInvoiceLevelAssignment;
      }
      console.log(this.$props.editData.lossTstatus);
      console.log(this.$props.editData);
      if (this.$props.editData.lossTstatus == "Approved") {
        console.log(this.$props.editData.automaticAmount);
        console.log(
          this.$props.editData.automaticAmount +
            this.$props.editData.lossToTrukkin
        );
        console.log(
          this.$props.editData.automaticAmount > 0 &&
            this.$props.editData.automaticAmount +
              this.$props.editData.lossToTAmount <
              1
        );

        if (
          this.$props.editData.automaticAmount > 0 &&
          this.$props.editData.automaticAmount +
            this.$props.editData.lossToTAmount <
            1
        ) {
          this.messageapproved = `* ${this.$props.editData.automaticAmount} ${this.$props.editData.customerCurrency} amount is automatically added to Loss To Trukkin, you can not edit this entry.`;
          this.automaticapp = true;
          console.log(this.messageapproved);
        }
      }
      this.amount = this.$props.editData.lossToTAmount;

      this.receiptNumber = this.$props.editData.lossTReceiptNumber;

      this.customerCurrency = this.$props.editData.customerCurrency;
      this.totalAmount = parseFloat(
        this.$props.editData.lossTReceiptReciveAmount
      );
      this.comment = this.$props.editData.lossTComment;
      this.receiptId = this.$props.editData.lossTReceiptId;
    },
    closeMessage() {
      this.processing = false;
      this.x.success = false;
      this.isEmail = true;
      this.comment = null;
    },

    setCustomerValues(val) {
      var data = this.customers.filter((v) => {
        if (v.text === val) return v;
      });
      this.amount = data[0].balanceAmount;
      this.totalAmount = data[0].totalAmount;
      this.receiptId = data[0].id;
    },

    fetchInvoice(val) {
      this.customers = [];

      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      this.t = config.headers.authorization;
      let body = {
        customer_id: this.$props.custid,
        invoiceNumber: this.$props.invoiceNumber,
      };
      this.axios
        .post(
          `${this.constants.apiUrl}/customerPayment/payment/listReciptNumber`,
          body,
          config
        )
        .then((response) => {
          const { data = null } = response.data.data;

          if (this.$props.type == 1) {
            this.receiptNumber = data[0].receiptNumber;

            this.totalAmount = data[0].receivedAmount;
            this.receiptId = data[0]._id;
          }

          data.forEach((element) => {
            this.customers.push({
              text: element.receiptNumber,
              value: element.balanceAmount,
              balanceAmount: element.balanceAmount,
              totalAmount: element.receivedAmount,
              id: element._id,
            });
          });
        });
    },

    open() {
      this.dialog = true;
      this.processing = false;
      this.x.error = null;
      this.haserror = false;
      this.amount = this.$props.lossAmount;
      this.levelAssignment = this.$props.invoiceLevel;

      this.customerCurrency = this.$props.customerCurrency;
      this.comment = null;
    },
    confirm() {
      this.confirmationDialog = false;
    },

    lossCharges() {
      // console.log(this.amount>=1,'---condo')
      if (!this.amount) {
        this.haserror = true;
        this.processing = false;
        this.x.error = "Please Fill Amount";
        return;
      }
      if (!this.document.length) {
        this.haserror = true;
        this.x.error = "Please upload proof of physical document";
        this.processing = false;

        return false;
      }
      console.log(this.amount >= 1);
      if (this.amount < 1) {
        console.log("---ehd");
        this.haserror = true;
        this.processing = false;
        this.x.error = "Loss amount should be greater than or equal to 1";
        return;
      }
      // if(this.automaticapp){

      //   if(this.amount > this.$props.editData.lossToTAmount){
      //     this.haserror = true;
      //     this.x.error = `Please  not fill amount greater than ${this.$props.editData.lossToTAmount} ${this.$props.editData.customerCurrency }`;
      //     return;

      //   }

      // }
      if (!this.receiptId) {
        this.haserror = true;
        this.processing = false;
        this.x.error = "Please  Choose Receipt Number";
        return;
      }
      if (!this.comment) {
        this.haserror = true;
        this.processing = false;
        this.x.error = "Please add comment before moving ahead ";
        return;
      }
      let url = lossToTrukkin;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      var body = {
        lossAmount: this.amount,
        customer_id: this.$props.custid,
        invoiceNumber: this.$props.invoiceNumber,
        comment: this.comment,
        lossTTEntryProof: this.document,
        entry_id: this.receiptId,
        invoiceLevel: this.levelAssignment ? "ASSIGNMENT" : "JOB",
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.amount = null;
          this.comment = null;
          this.processing = false;
          this.x.success = true;
          this.$emit("upLossButton", "");
          this.receiptId = "";
          this.customers = [];
          this.$emit("loss-submit", true);
          this.confirmationDialog = true;
          this.document = [];
          this.cancel();
          this.x.error = null;
          this.haserror = false;
        },
        (error) => {
          this.processing = false;
          this.dialog = true;
          this.haserror = true;
          this.x.error = error.response.data.message;
        }
      );
    },

    uploadDocument(e) {
      this.x.error = null;
      var files = e.target.files || e.dataTransfer.files;
      let x = [...files];
      setTimeout(() => {
        this.$refs.fileUpload.value = "";
      }, 6000);
      if (!files.length) return;

      Array.from(x).forEach((y, i) => {
        this.createImage(files[i]);
      });
    },
    //createImage file check size of image/Document
    createImage(file) {
      let size = file.size / 1024 / 1024;
      // -------- FILE SIZE CHECK
      if (size > 5) {
        this.x.error = "Please upload proof with size less than 5MB!";

        return false;
      }
      if (
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/png" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !== "image/jpeg" &&
        file.type
          .toString()
          .substr(file.type.toString().lastIndexOf(".") + 1) !==
          "application/pdf"
      ) {
        this.x.error =
          "Please upload proof of file type png , jpg ,jpeg or pdf!";
        return false;
      } else {
        var image = new Image();
        var reader = new FileReader();
        var vm = this;

        reader.onload = (e) => {
          vm.image = e.target.result;
        };
        reader.readAsDataURL(file);
        this.upload(file);
      }
    },

    //upload function for upload document
    upload(file) {
      this.processing = true;
      const formData = new FormData();
      formData.append("image", file);
      this.axios({
        url: `${this.constants.apiUrl}${uploadImage}`,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      }).then(
        (response) => {
          this.processing = false;
          const { statusCode } = response.data;
          if (statusCode === 200) {
            if (this.document.length < 5) {
              this.document.push(response.data.data.original);
            } else {
              this.x.error = "Maximum 5 proofs can be uploaded!";
              return false;
            }
          } else {
            this.x.error = "Failed to upload image";
          }
          this.loading = false;
        },
        (error) => {
          this.x.error = "Failed to upload image";
          this.processing = false;
        }
      );
    },
  },
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.activityLog {
  margin: 0 auto;
  width: 80%;
}
.list {
  display: inline-block;
  position: relative;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}
.upload-btn-wrapper a {
  margin-left: 10px;
}

.upload-btn-wrapper .btn,
.upload-btn-wrapper .view-btn {
  border: 2px solid darkorange;
  color: darkorange;
  background-color: white;
  padding: 8px 8px;
  cursor: pointer !important;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
}
.activity {
  margin: auto;
}
.tooltip {
  font-size: 18px;
  font-weight: 500;
}
.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.tooltip-op {
  left: calc(100% - 9.2%) !important;
  opacity: 1 !important;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.assign-driver-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #bebebe;
  border: 2px solid grey;
  padding: 2px 5px;
  border-radius: 5px;
}

.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #e3471b;
  border: 2px solid #e3471b;
  padding: 2px 5px;
  border-radius: 5px;
}
.Loss-btn-color {
  background-color: #e3471b !important;
}
.outlined {
  border: 1px solid orange;
}
</style>
