<template>
  <div>
    <v-icon color="red" class="outlined" flat @click="check()" title="Reject"
      >close</v-icon
    >

    <v-dialog v-model="dialog" persistent max-width="600">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="headline">Reject Loss To Trukkin Amount </span>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="close()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-layout>
              <v-flex xs20 md10 class="text-style">
                {{ message }}
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex>
                <v-text-field
                  class="pt-0 currencyTitle"
                  v-if="cancelType.value == 'Other’s'"
                  type="text"
                  single-line
                  label="Enter your reason"
                  :rules="[rules.required, rules.noWhiteSpace]"
                  required
                  v-model="cancellationReason"
                  maxlength="150"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer> </v-spacer>

            <v-btn color="black darken-1" flat @click="close()">Cancel</v-btn>
            <v-btn color="error" @click="rejectCustomer" :disabled="loading">
              Reject
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="error"
          class="white--text"
          v-if="error"
          >{{ error }}</v-snackbar
        >
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { StorageKeys, CancellationType } from "../../../../constants/constants";
import { acceptLossPayment } from "../../../../constants/api-urls";
export default {
  props: {
    message: String,
    confirmationTitle: String,
    cancelBtn: String,
    confirmBtn: String,
    customerData: Object,
    invoiceLevel: Boolean,
    id: String,
    amount: String,
    currency: String,
  },
  data: () => ({
    loading: false,
    valid: true,
    message: String,
    lazy: false,
    cancellationType: CancellationType,
    rules: {
      required: (value) => !!value || "This field is required.",
      noWhiteSpace: (v) =>
        (v != null && v.trim().length > 0) || "This field is required.",
    },
    cancellationReason: "",
    cancelType: { text: "Other’s", value: "Other’s" },
    operationName: "",
    dialog: false,
    error: null,
  }),
  created() {},
  methods: {
    async check() {
      this.message = ` Are you sure you want to reject ${this.$props.amount} ${this.$props.currency}  as loss to Trukkin?`;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.cancellationReason = "";
      this.$refs.form.resetValidation();
    },
    async rejectCustomer() {
      this.loading = true;
      let url = acceptLossPayment;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        status: "Rejected",
        _id: this.$props.id,
        invoiceLevel: this.$props.invoiceLevel ? "ASSIGNMENT" : "JOB",
      };
      if (this.cancellationReason) {
        body.comment = this.cancellationReason;
      }

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          // this.x.success = true;
          this.close();
          this.loading = false;
          this.$emit("refresh-list-receieve");
        },
        (error) => {
          this.loading = false;
          this.error = error.response.data.message;
        }
      );
    },
  },
};
</script>

<style scoped>
.m-t-10 {
  margin-top: 10px;
}
.m-l-10 {
  margin-left: 10px;
}
.cancel-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #ffc278;
  border-radius: 5px;
  border: 2px solid darkorange;
  padding: 2px 12px;
}
.green-button {
  border-radius: 5px;
  background-color: #63ae63 !important;
  color: #ffffff !important;
  padding: 2px 12px;
  border: 2px solid green !important;
}
.red-button {
  border-radius: 5px;
  background-color: #ff7b7b !important;
  color: #ffffff !important;
  padding: 2px 12px;
  border: 2px solid red !important;
}
.text-style {
  font-size: 16px;
}
.v-icon.outlined {
  border: 1px solid red;
}
</style>
