<template>
  <v-layout align-center justify-center>
    <button @click="check()" class="payy-ass-btn green-button">
      Document Received
    </button>
    <v-dialog v-model="dialog" persistent max-width="700">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
            style="
              background-color: #f5f5f5;
              padding-bottom: 5px;
              padding-top: 5px;
            "
          >
            <span class="headline">Documents Received By Finance</span>
            <v-spacer></v-spacer>
            <v-btn icon :disabled="processing" @click.prevent="dialog = false">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-card-title>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="processing"
          ></v-progress-linear>
          <v-card-text>
            <v-layout>
              <v-flex xs12 md12 class="text-style heading">
                Have you received the physical documents from the procurement or
                operations team?
              </v-flex>
            </v-layout>
            <v-layout> </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="success" :disabled="processing" @click="approve()">
              Yes
            </v-btn>

            <v-btn
              color="error"
              :disabled="processing"
              @click="rejectDialog = true"
              >Reject
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="error"
          class="white--text"
          v-if="error"
          >{{ error }}</v-snackbar
        >
      </v-form>
    </v-dialog>
    <v-dialog v-model="rejectDialog" persistent max-width="400">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title
            style="
              background-color: #f5f5f5;
              padding-bottom: 5px;
              padding-top: 5px;
            "
          >
            <span class="headline">Reject Documents</span>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="closeRejectDialog()">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-layout>
              <v-flex xs12 md12 class="text-style heading">
                Are you sure want to reject?
              </v-flex>
            </v-layout>
            <v-layout> </v-layout>
            <v-layout>
              <v-flex>
                <v-text-field
                  class="pt-0 currencyTitle"
                  type="text"
                  single-line
                  label="Provide rejection reason"
                  :rules="[rules.required, rules.noWhiteSpace]"
                  required
                  v-model="cancellationReason"
                  :value="cancellationReason"
                />
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" @click="reject()"> YES </v-btn>
            <v-btn color="black darken-1" flat @click="closeRejectDialog()"
              >NO</v-btn
            >
          </v-card-actions>
        </v-card>
        <v-snackbar
          :timeout="3000"
          bottom
          color="red darken-2"
          v-model="error"
          class="white--text"
          v-if="error"
          >{{ error }}</v-snackbar
        >
      </v-form>
    </v-dialog>
  </v-layout>
</template>

<script>
import CancellationPopup from "@/components/Pop-ups/CancellationPopup.vue";
import { StorageKeys, CancellationType } from "../../../constants/constants";
export default {
  props: {
    details: Object,
  },
  data: () => ({
    loading: false,
    valid: true,
    lazy: false,
    cancellationType: CancellationType,
    processing: false,
    rules: {
      required: (value) => !!value || "This field is required.",
      noWhiteSpace: (v) =>
        (v != null && v.trim().length > 0) || "This field is required.",
    },
    cancellationReason: "",
    rejectDialog: false,
    cancelType: { text: "Other’s", value: "Other’s" },
    operationName: "",
    dialog: false,
    error: null,
  }),
  created() {},
  methods: {
    async check() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.cancellationReason = "";
      this.processing = false;
    },
    closeRejectDialog() {
      this.rejectDialog = false;
      this.cancellationReason = "";
      this.$refs.form.resetValidation();
      this.processing = false;
    },
    approve() {
      this.processing = true;
      let url = "/dmsAdmin/documentReceivedForAssignment";
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {
        assignmentId: this.details._id,
        isRejected: false,
      };

      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.close();
          this.$emit("refresh-list");
        },
        (error) => {
          this.processing = false;
          this.error = error.response.data.message;
        }
      );
    },
    reject() {
      if (this.$refs.form.validate()) {
        let url = "/dmsAdmin/documentReceivedForAssignment";
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          assignmentId: this.details._id,
          isRejected: true,
          rejectedReason: this.cancellationReason,
        };
        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            // this.x.success = true;
            this.close();
            this.closeRejectDialog();
            this.$emit("refresh-list");
          },
          (error) => {
            this.processing = false;
            this.error = error.response.data.message;
          }
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text-style {
  font-size: 16px;
}
</style>
